import Froalaeditor from "froala-editor"
import onCreateImage from "../features/insertImage/onCreateImage"
import { createInsertImageDialog } from "../features/insertImage/InsertImageDialog"

// Define icon for our custom image insert button
Froalaeditor.DefineIcon("showImageDialog", {
  SVG_KEY: "insertImage",
})

Froalaeditor.DefineIcon("replaceImageDialog", {
  SVG_KEY: "replaceImage",
})

/**
 * Register custom command to show Image Insert dialog instead of the built-in Froala one.
 * @param displayReactElement - Function to render edit Dialog box
 */
const registerImageCommands = (displayReactElement: any) => {
  Froalaeditor.RegisterCommand("customImageReplace", {
    title: "Replace Image",
    icon: "replaceImageDialog",
    focus: true,
    undo: true,
    callback: function () {
      const existingImage = this.image.get() as any
      displayReactElement((onClose: () => void) =>
        createInsertImageDialog((url: string | null) => {
          onClose()
          if (url) {
            this.image.insert(url, true, { link: url }, existingImage)
          }
        })
      )
    },
  })

  Froalaeditor.RegisterCommand("showImageDialog", {
    title: "Insert Image",
    icon: "showImageDialog",
    focus: true,
    undo: true,
    shortcut: ["CMD+P", "CTRL+P"],
    callback: function () {
      const selection = this.selection.get() as any

      const anchorElement = selection?.anchorNode
      if (!anchorElement) {
        return
      }

      anchorElement.innerHTML = "<br/>"

      displayReactElement((onClose: () => void) =>
        onCreateImage(anchorElement, onClose, this)
      )
    },
  })
}

export default registerImageCommands
