import { Box, Typography } from "@mui/material"
import ImageUploadBox from "./ImageUploadBox"
import ColourPicker from "./ColourPicker"
import SizePicker from "./SizePicker"
import FontUploadBox from "./FontUploadBox"
import { useFlag } from "../../utilities/feature-management"
import ColourThemeSelectionControl from "../uxRefresh/pages/brandingPage/components/ColourThemeSelectionControl"
import BrandingStylesPicker from "./BrandingStylesPicker"

/**
 * Component for choosing branding options
 *
 * @param props - Component props
 */
const BrandingForm = (props: any) => {
  const { branding, onPropertyChange } = props
  const useNewColorControls = useFlag("rollout-branding-new-color-controls")
  /**
   * Logo change
   * @param imageUrl - image url
   * @param file - image upload file
   */
  const onLogoChange = (imageUrl: string | null, file: any) =>
    onPropertyChange("logo", imageUrl, file)

  /**
   * brandmark change
   * @param imageUrl - image url
   * @param file - image upload file
   */
  const onBrandMarkChange = (imageUrl: string | null, file: any) =>
    onPropertyChange("brandMark", imageUrl, file)

  return (
    <Box>
      <ImageUploadBox
        data-cy="branding-logo"
        title="Logo"
        imageUrl={branding.logo}
        onChange={onLogoChange}
        helpText="Image should be at most 140 pixels wide and 80 pixels high."
        imageDisplayWidth={140}
      />
      <Box mb={4}></Box>
      <ImageUploadBox
        data-cy="branding-mark"
        title="Brand Mark"
        imageUrl={branding.brandMark}
        onChange={onBrandMarkChange}
        maxWidth={25}
        imageDisplayWidth={40}
        helpText="Image should be square and at least 40 pixels wide."
      />
      <Box mb={4}></Box>

      {useNewColorControls ? (
        <ColourThemeSelectionControl {...props} />
      ) : (
        <ColourPicker {...props} data-cy="color-picker" />
      )}
      <Typography variant="body1" fontWeight="bold" sx={{ mt: 3 }}>
        Fonts
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        For best results with Word and PowerPoint exports, please use embeddable
        fonts. Most TrueType (.ttf) and OpenType (.otf) fonts allow embedding,
        also avoid PostScript-based fonts as they may cause compatibility
        issues.
      </Typography>
      <FontUploadBox
        title="Heading"
        variant="heading"
        {...props}
        data-cy="fonts-heading"
      />
      <FontUploadBox
        title="Body"
        variant="body"
        {...props}
        data-cy="fonts-body"
      />
      <Box mb={3}></Box>
      <SizePicker {...props} data-cy="size-picker" />

      <BrandingStylesPicker {...props} data-cy="styles-picker" />
    </Box>
  )
}

export default BrandingForm
