export type QuestionCounts = {
  "multiple-choice": number
  "fill-in-the-blank": number
  "true-false": number
  matching: number
  count: number
}

/**
 * Return a count of each question type
 * @param htmlString - Assembled HTML of course
 */
const countQuestionTypes = (htmlString: string): QuestionCounts => {
  const html = document.createElement("html")
  html.innerHTML = htmlString

  const counts: QuestionCounts = {
    "multiple-choice": 0,
    "fill-in-the-blank": 0,
    "true-false": 0,
    matching: 0,
    count: 0,
  }

  const questionElements = html.querySelectorAll(
    '[data-component$="-question"]'
  )

  questionElements.forEach((element) => {
    const componentType = element.getAttribute("data-component")
    if (componentType) {
      const questionType = componentType.replace("-question", "")
      if (questionType in counts) {
        counts[questionType as keyof QuestionCounts] += 1
        counts.count += 1
      }
    }
  })

  return counts
}

export default countQuestionTypes
