/* eslint-disable react/jsx-filename-extension */
import { useEffect } from "react"
import GlobalConfig from "react-global-configuration"
import config from "../config"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import muiTheme from "../themes/theme"
import useNotifier from "../hooks/useNotifier"
import useWatchJobs from "../hooks/useWatchJobs"
import { CssBaseline } from "@mui/material"
import { useFlag, useIdentify } from "../utilities/feature-management"
import updatedTheme from "../themes/updatedTheme"
import { useAuth } from "../contexts/authorization"
import { addUserMetadata } from "../rum-config"
import useNetworkError from "../hooks/useNetworkError"
import mixpanel from "mixpanel-browser"
import {
  useInitializeMixpanelUser,
  useMixpanelNavigationTracker,
} from "../utilities/mixpanel"
import useRefreshBrowser from "../hooks/useRefreshBrowser"
import * as Sentry from "@sentry/react"
import AppRoutes from "./AppRoutes"
import { AppErrorBoundary } from "../components/widgets/AppErrorBoundary"

GlobalConfig.set(config)

mixpanel.init(config.mixpanelApiCredentials.apiKey, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
})

/**
 * Renders the LearnExperts web app.
 */
const App = () => {
  useNetworkError()
  useIdentify()
  useNotifier()
  useWatchJobs()
  // Initialize Mixpanel user tracking
  useInitializeMixpanelUser()
  useMixpanelNavigationTracker()
  useRefreshBrowser()

  useEffect(() => {
    document.title = GlobalConfig.get("title")
  }, [])

  const { user } = useAuth()

  // When the user changes, this hook updates the AWS RUM metadata to reflect changes
  useEffect(() => {
    const userId = user ? user.id : "User ID not available"

    /**
     * Output user data when mounting
     */
    addUserMetadata(userId)

    // Only set the user if Sentry is enabled
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.setUser(
        user
          ? { id: user.id, username: user.username, email: user.email }
          : null
      )
    }
  }, [user])

  const useNewUX = useFlag("rollout-new-home-page-ux")

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={useNewUX ? updatedTheme : muiTheme}>
        <CssBaseline />
        <AppErrorBoundary>
          <AppRoutes />
        </AppErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
