import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import ImageUploadBox from "../branding/ImageUploadBox"
import React from "react"
import useCourseTileImageUpload from "./hooks/useCourseTileImageUpload.ts"

/**
 * Dialog component for uploading custom thumbnail for course
 * @param props - Component props
 */
const AddCourseImageDialog = (props) => {
  const { open, onClose, course, saveCustomCourseImage } = props
  const { title, custom_image_url } = course

  const { image, setImage } = useCourseTileImageUpload(custom_image_url)

  /**
   * Called when image is selected by teh ImageUploadBox
   * @param imageUrl - url
   * @param file - upload
   */
  const onImageChange = (imageUrl, file) =>
    setImage({
      thumbnail: imageUrl,
      file: file,
    })

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Thumbnail For "{title}"</DialogTitle>
      <DialogContent>
        <ImageUploadBox
          title="Upload image"
          onChange={onImageChange}
          imageUrl={image.thumbnail}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!image.file}
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.preventDefault()
            saveCustomCourseImage(course, image)
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddCourseImageDialog
