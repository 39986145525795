import { Switch, Route } from "react-router-dom"
import { useFlag } from "../utilities/feature-management"
import { useFeatureDecisions } from "../contexts/features"
import PrivateRoute from "../components/atoms/PrivateRoute"
import "../features/authentication/bootstrap"

// Import all the page components
import Login from "./Login"
import { FroalaEditorPage } from "./FroalaEditorPage"
import CoursesPage from "./CoursesPage"
import ExportPage from "./ExportPage"
import FindPage from "./FindPage"
import SharePage from "./SharePage"
import TrashPage from "../features/trash/TrashPage"
import IntelligentUpdatePage from "./IntelligentUpdatePage"
import HistoryPage from "./HistoryPage"
import HistoryDetailsPage from "./HistoryDetailsPage"
import GiveCoursePage from "./GiveCoursePage"
import CreateCoursePage from "./CreateCoursePage"
import CreationProgressPage from "./CreationProgressPage"
import ToolsPage from "./ToolsPage"
import BuildMetaPage from "./BuildMetaPage"
import ReadonlyEditorPage from "./ReadonlyEditorPage"
import BrandPage from "./BrandPage"
import HelpPage from "./HelpPage"
import BrandingPage from "./BrandingPage"
import MicrolearningPage from "./MicrolearningPage"
import { TranslationRulesPage } from "./TranslationRulesPage"

// Import new UX components
import NewCourseScreenUX from "../features/uxRefresh/pages/coursesPage/CoursesPage"
import LearningPathsPage from "../features/uxRefresh/pages/learningPathsPage/LearningPathsPage"
import NewBrandingScreenUX from "../features/uxRefresh/pages/brandingPage/BrandingPage"
import NewTrashScreenUX from "../features/uxRefresh/pages/trashPage/TrashPage"
import NewTranslationScreenUX from "../features/uxRefresh/pages/translationRules/TranslationRulesPage"
import AuditPage from "../features/uxRefresh/pages/auditPage/AuditPage"
import AuditDetailsPage from "../features/uxRefresh/pages/auditPage/AuditDetailsPage"
import NewMicrolearningScreenUX from "../features/uxRefresh/pages/microlearningPage/MicrolearningPage"
import SettingsPage from "../features/uxRefresh/pages/settingsPage/SettingsPage"

/**
 * AppRoutes component that handles all routing logic for the application
 * @returns {JSX.Element} The Switch component containing all routes
 */
function AppRoutes() {
  const useNewUX = useFlag("rollout-new-home-page-ux")
  const newMicrolearning = useFlag("rollout-refreshed-microlearning-ux")
  const displaySettingsPage = useFlag("rollout-editor-audio-file-upload")
  const decisions = useFeatureDecisions()

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/"
        component={useNewUX ? NewCourseScreenUX : CoursesPage}
      />
      <PrivateRoute
        exact
        path="/creating-course"
        component={CreationProgressPage}
      />
      <PrivateRoute exact path="/export" component={ExportPage} />
      <PrivateRoute
        exact
        path="/microlearning"
        component={
          newMicrolearning ? NewMicrolearningScreenUX : MicrolearningPage
        }
      />
      <PrivateRoute exact path="/create-course" component={CreateCoursePage} />
      {!useNewUX && <PrivateRoute exact path="/tools" component={ToolsPage} />}
      <PrivateRoute exact path="/editor" component={FroalaEditorPage} />
      <PrivateRoute
        exact
        path="/intelligent-update"
        component={IntelligentUpdatePage}
      />
      <PrivateRoute
        exact
        path="/history"
        component={useNewUX ? AuditPage : HistoryPage}
      />
      <PrivateRoute
        exact
        path="/history-details"
        component={useNewUX ? AuditDetailsPage : HistoryDetailsPage}
      />
      <PrivateRoute exact path="/find" component={FindPage} />
      <PrivateRoute exact path="/share" component={SharePage} />
      <PrivateRoute exact path="/give" component={GiveCoursePage} />
      <PrivateRoute
        exact
        path="/trash"
        component={useNewUX ? NewTrashScreenUX : TrashPage}
      />
      <PrivateRoute exact path="/help" component={HelpPage} />
      <PrivateRoute
        exact
        path="/branding"
        component={useNewUX ? NewBrandingScreenUX : BrandingPage}
      />
      <PrivateRoute
        exact
        path="/translation-rules"
        component={useNewUX ? NewTranslationScreenUX : TranslationRulesPage}
      />
      <Route key="login" exact path="/login" component={Login} />
      <Route exact path="/meta/build" component={BuildMetaPage} />
      <Route path="/share/:courseId" component={ReadonlyEditorPage} />
      {displaySettingsPage && (
        <PrivateRoute exact path="/settings" component={SettingsPage} />
      )}
      <PrivateRoute
        exact
        path="/brand"
        component={decisions.enableBrand() ? BrandPage : CoursesPage}
      />
      {useNewUX && (
        <PrivateRoute exact path="/paths" component={LearningPathsPage} />
      )}
    </Switch>
  )
}

export default AppRoutes
