import { Menu, MenuItem } from "@mui/material"
import { SortOption, SortSelectProps } from "../../welcome/SortSelect"
import { useState } from "react"
import { Sort } from "@mui/icons-material"
import MenuControlButton from "./MenuControlButton"

/**
 * Custom dropdown for selecting sortable options
 * @param props - Component props
 */
const SortByControl = (props: SortSelectProps) => {
  const { options, sortBy, handleSelectSortOption } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const selectedOption = options.find((option) => option.value === sortBy)

  return (
    <>
      <MenuControlButton
        icon={<Sort />}
        onClick={(event: any) => setAnchorEl(event.currentTarget)}
      >
        {selectedOption ? selectedOption.label : "Sort By"}
      </MenuControlButton>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {options.map((option: SortOption) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => {
              handleSelectSortOption(option.value)
              setAnchorEl(null)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SortByControl
