import EditorScreen from "../components/screens/EditorScreen"
import { useAuth } from "../contexts/authorization"
import { Redirect } from "react-router-dom"
import GuestEmailDialog from "../components/widgets/GuestEmailDialog"
import * as api from "../api"
import { useEffect, useState } from "react"

const ReadonlyEditorPage = ({ match }) => {
  const { courseId } = match.params
  const [isOwner, setIsOwner] = useState(false)

  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      api.loadCourse(courseId).then((response) => {
        const ownerId = response.data.tenant_id
        setIsOwner(user.id === ownerId)
      })
    }
  }, [courseId, user])

  // if the user is signed in, redirect to full editor screen
  return !isOwner ? (
    <>
      <EditorScreen key={courseId} courseId={courseId} readOnly />
      <GuestEmailDialog />
    </>
  ) : (
    <Redirect
      to={{
        pathname: "/editor",
        search: `?course=${courseId}`,
      }}
      replace
    />
  )
}

export default ReadonlyEditorPage
