import React, { useEffect, useState } from "react"
import axiosInstance from "../../api/axios"
import { useFitText } from "../../hooks/useFitText"
import {
  LabeledImageDisplay,
  labeledImageCustomElement,
} from "../../custom-elements/labeledImageCustomElement"
import {
  FlipCardGridCustomComponent,
  flipCardGridCustomElement,
} from "../../custom-elements/flipCardGridCustomElement"
import { IBranding } from "../branding/hooks/useBranding"
import { Alert } from "@mui/material"
import { styled } from "@mui/material/styles"

/** Style for outer slide container */
const SlideContainer = styled("div")(({ theme }) => ({
  fontSize: 22,

  // Basic table styles
  "& table": {
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid #A5A5A5",
    "& th": {
      background: "#A5A5A5",
      color: "white",
    },
    "& th, & td": {
      border: 0,
      borderRight: "1px solid white",
      borderBottom: "1px solid #A5A5A5",
    },
    // Remove right border from last cell in each row
    "& th:last-child, & td:last-child": {
      borderRight: 0,
    },
    // Alternating row backgrounds (even rows)
    "& tr:nth-of-type(even):not(:first-child)": {
      backgroundColor: "#F0F0F0",
    },
  },

  // Table headers and footers
  "& .fr-thead": {
    fontWeight: "bold",
    "& td, & th": {
      backgroundColor: "inherit",
    },
  },
  "& .fr-thead-first": {
    // First row in header
    borderTop: "2px solid #000",
    backgroundColor: "#e8e8e8",
  },
  "& .fr-thead-last": {
    // Last row in header
    borderBottom: "2px solid #000",
    backgroundColor: "#e8e8e8",
  },

  "& .fr-tfoot": {
    backgroundColor: "#e8e8e8",
    fontStyle: "italic",
    "& td, & th": {
      backgroundColor: "inherit",
    },
  },
  "& .fr-tfoot-first": {
    // First row in footer
    borderTop: "2px solid #000",
    backgroundColor: "#e8e8e8",
  },
  "& .fr-tfoot-last": {
    // Last row in footer
    borderBottom: "2px solid #000",
    backgroundColor: "#e8e8e8",
  },

  // Thick borders
  "& .fr-thick": {
    borderWidth: "2px",
  },

  // Highlighted cells - using double red border
  "& td.fr-highlighted, & th.fr-highlighted": {
    border: "1px double red",
  },

  // Combined thick and highlighted
  "& .fr-thick.fr-highlighted": {
    borderWidth: "2px",
    backgroundColor: "#fff3cd",
  },

  // Tables
  "& .fr-dashed-borders": {
    "& td, & th": {
      borderStyle: "dashed",
    },
  },
  "& .fr-alternate-rows": {
    "& tbody tr:nth-child(2n)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))

/** Single slide of a presentation */
export type Slide =
  | LeftImageSlide
  | RightImageSlide
  | TwoColumnSlide
  | TwoImageSlide
  | TextSlide
  | TitleSlide
  | ImageSlide
  | VideoSlide
  | FlipCardGridSlide
  | LabeledImageSlide
  | AudioSlide

/** Slide with image on the left */
export interface LeftImageSlide {
  layout: "left-image"
  /** URL of the image */
  image: string
  /** Title of the slide */
  title: string
  /** HTML of the right column */
  content: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Slide with image on the right */
export interface RightImageSlide {
  layout: "right-image"
  /** URL of the image */
  image: string
  /** Title of the slide */
  title: string
  /** HTML of the left column */
  content: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Slide with two columns of text */
export interface TwoColumnSlide {
  layout: "two-column"
  /** Title of the slide */
  title: string
  /** HTML of the left column */
  left: string
  /** HTML of the right column */
  right: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Slide with two images */
export interface TwoImageSlide {
  layout: "two-image"
  /** Title of the slide */
  title: string
  /** URL of the left image */
  left: string
  /** URL of the right image */
  right: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Slide with text only */
export interface TextSlide {
  layout: "text"
  /** Title of the slide */
  title: string
  /** HTML of the slide */
  content: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Slide with title and optional subtitle */
export interface TitleSlide {
  layout: "title"
  /** Title of the slide */
  title: string
  /** Optional subtitle for the slide */
  subtitle?: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Slide with image only */
export interface ImageSlide {
  layout: "image"
  /** Title of the slide */
  title: string
  /** URL of the image */
  image: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

export type Track = {
  src: string
  lang: string
  kind: string
}

export interface VideoSlide {
  layout: "video"
  /** Title of the slide */
  title: string
  /** Optional lead in text for the video */
  leadIn?: string
  /** URL of the video */
  video: string
  /** Aspect ratio of the video */
  aspectRatio: number
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string

  tracks: Track[]
}

/** Slide with flip card grid */
export interface FlipCardGridSlide {
  layout: "flip-card-grid"
  /** Title of the slide */
  title: string
  /** Flip cards html */
  html: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Slide with labeled image */
export interface LabeledImageSlide {
  layout: "labeled-image"
  /** Title of the slide */
  title: string
  /** Labeled image html */
  html: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Slide with audio image */
export interface AudioSlide {
  layout: "audio"
  /** Title of the slide */
  title: string
  /** Audio url */
  audio: string
  /** Audio name */
  audioName?: string
  /** Optional HTML speakerNotes for the slide */
  speakerNotes?: string
}

/** Props for the preview content component */
export interface PreviewPowerpointContentProps {
  /** URL of the preview data */
  previewUrl: string

  /** Branding to use */
  branding: IBranding

  /** Slide numbers mode (faint, normal) */
  slideNumbersMode: "faint" | "normal"
}

/**
 * Renders the preview content of a powerpoint export
 *
 * @param props Props for the preview content
 */
export function PreviewPowerpointContent(props: PreviewPowerpointContentProps) {
  const { previewUrl, branding } = props

  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)

  // Get slides JSON from server
  useEffect(() => {
    // Fetch the preview data including cookies
    axiosInstance
      .get(previewUrl, { withCredentials: true })
      .then((response) => {
        const data = response.data
        setData(data)
      })
      .catch((error) => {
        setError(`Fetch error: ${error.message}`)
        console.error(error)
      })
  }, [previewUrl])

  if (error) {
    return (
      <Alert severity="error">
        LEAi was unable to load the slide preview. Please try again later.
      </Alert>
    )
  }

  if (!data) return null

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ fontFamily: bodyFontFamily, width: 800 }}>
        {data.slides.map((slide: Slide, index: number) => (
          <SlideDisplay
            key={index}
            slide={slide}
            branding={branding}
            slideNumber={index + 1}
            slideNumbersMode={props.slideNumbersMode}
          />
        ))}
      </div>
    </div>
  )
}

// Total height of the slide
const slideWidth = 800

// Slide is 16:9 aspect ratio
const slideHeight = (slideWidth / 16) * 9

// Top padding for the slide in fraction of slide height
const slideTopPaddingFraction = 0.1

// Bottom padding for the slide in fraction of slide height
const slideBottomPaddingFraction = 0.1

// Left padding for the slide in fraction of slide width
const slideLeftPaddingFraction = 0.05

// Right padding for the slide in fraction of slide width
const slideRightPaddingFraction = 0.05

// Height of the header in fraction of slide height
const headerHeightFraction = 0.1

// Gap between header and content in fraction of slide height
const headerContentGapFraction = 0.05

// These are PowerPoint default fonts if we don't have a branding font
const headingFontFamily = "'Calibri Light', Arial"
const bodyFontFamily = "Calibri, Arial"

// Content height fraction
const contentHeightFraction =
  1 -
  headerHeightFraction -
  headerContentGapFraction -
  slideTopPaddingFraction -
  slideBottomPaddingFraction

// Logo height fraction
const logoHeightFraction = 0.08

// Logo padding fraction
const logoPaddingFraction = 0.01

interface SlideProps {
  slide: Slide
  branding: IBranding
  slideNumber: number
  slideNumbersMode: "faint" | "normal"
}

/**
 * Renders a single slide
 * @param props Slide props
 */
function SlideDisplay(props: SlideProps) {
  const { slide, branding, slideNumber, slideNumbersMode } = props

  // Fits various parts of the slide by automatically adjusting the font size
  const { fontSize: headerFontSize, ref: headerRef } = useFitText()
  const { fontSize: content1FontSize, ref: content1Ref } = useFitText()
  const { fontSize: content2FontSize, ref: content2Ref } = useFitText()

  /** Renders the content of the slide (for non-title slides), not including the header */
  function renderContent() {
    return (
      <div
        style={{
          fontFamily: branding.body.name ?? bodyFontFamily,
          height: slideHeight * contentHeightFraction,
          position: "absolute",
          top:
            slideHeight *
            (slideTopPaddingFraction +
              headerHeightFraction +
              headerContentGapFraction),
          left: slideWidth * slideLeftPaddingFraction,
          right: slideWidth * slideRightPaddingFraction,
        }}
      >
        {renderInnerContent()}
      </div>
    )
  }

  /** Renders the inner part of the content inside a sized container
   * for non-title slides, not including the header.
   */
  function renderInnerContent() {
    switch (slide.layout) {
      case "left-image":
        return (
          <div style={{ display: "flex", gap: 20, maxHeight: "100%" }}>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <img
                src={slide.image}
                alt=""
                style={{
                  maxWidth: "100%",
                  maxHeight: slideHeight * contentHeightFraction,
                  objectFit: "contain",
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  fontSize: content1FontSize,
                  maxHeight: "100%",
                  overflow: "hidden",
                }}
                ref={content1Ref}
                dangerouslySetInnerHTML={{
                  __html: slide.content,
                }}
              />
            </div>
          </div>
        )
      case "right-image":
        return (
          <div style={{ display: "flex", gap: 20, maxHeight: "100%" }}>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  fontSize: content1FontSize,
                  maxHeight: "100%",
                  overflow: "hidden",
                }}
                ref={content1Ref}
                dangerouslySetInnerHTML={{
                  __html: slide.content,
                }}
              />
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <img
                src={slide.image}
                alt=""
                style={{
                  maxWidth: "100%",
                  maxHeight: "300px",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        )
      case "two-column":
        return (
          <div style={{ display: "flex", gap: 20, maxHeight: "100%" }}>
            <div style={{ flex: 1 }}>
              <div
                style={{ fontSize: content1FontSize, maxHeight: "100%" }}
                ref={content1Ref}
                dangerouslySetInnerHTML={{ __html: slide.left }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div
                style={{ fontSize: content2FontSize, maxHeight: "100%" }}
                ref={content2Ref}
                dangerouslySetInnerHTML={{ __html: slide.right }}
              />
            </div>
          </div>
        )
      case "two-image":
        return (
          <div style={{ display: "flex", gap: 20, maxHeight: "100%" }}>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <img
                src={slide.left}
                alt=""
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <img
                src={slide.right}
                alt=""
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        )
      case "text":
        return (
          <div
            style={{ fontSize: content1FontSize, maxHeight: "100%" }}
            ref={content1Ref}
            dangerouslySetInnerHTML={{ __html: slide.content }}
          />
        )
      case "image":
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={slide.image}
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: contentHeightFraction * slideHeight,
                objectFit: "contain",
              }}
            />
          </div>
        )
      case "audio":
        return (
          <>
            <div
              style={{ flexGrow: 0, fontSize: 16, textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: slide.audioName || "" }}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <audio controls preload="metadata" src={slide.audio} />
            </div>
          </>
        )
      case "video":
        const videoEmbedType = determineVideoEmbedType(slide.video)

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{ flexGrow: 0, fontSize: 16, textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: slide.leadIn || "" }}
            />
            {videoEmbedType === "video" ? (
              <>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  crossOrigin="use-credentials"
                  controls
                  style={{
                    width: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    flexGrow: 1,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <source src={slide.video} />
                  {slide.tracks.map((track) => (
                    <track
                      src={track.src}
                      kind={track.kind}
                      srcLang={track.lang}
                    />
                  ))}
                </video>
              </>
            ) : (
              <iframe
                title={slide.video}
                src={slide.video}
                style={{
                  width: "auto",
                  maxWidth: "100%",
                  flexGrow: 1,
                  aspectRatio: `${slide.aspectRatio}`,
                  border: "0",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            )}
          </div>
        )
      case "flip-card-grid": {
        // Parse HTML into element
        const parser = new DOMParser()
        const doc = parser.parseFromString(slide.html, "text/html")
        const flipCardGridElement = doc.body.firstChild as HTMLElement

        const flipCardGridData =
          flipCardGridCustomElement.getDataFromElement(flipCardGridElement)

        // Fits the flip card grid to the slide width
        const scale = slideWidth / 940

        return (
          <div
            style={{
              transformOrigin: "top left",
              transform: `scale(${scale})`,
              width:
                (slideWidth *
                  (1 - slideLeftPaddingFraction - slideRightPaddingFraction)) /
                scale,
            }}
          >
            <FlipCardGridCustomComponent
              data={flipCardGridData}
              editor={null}
              readOnly
              element={null as any}
              withStyles={(elem: any) => elem}
              overridePrimaryThemeColor={branding.colour ?? undefined}
            />
          </div>
        )
      }
      case "labeled-image": {
        // Parse HTML into element
        const parser = new DOMParser()
        const doc = parser.parseFromString(slide.html, "text/html")
        const labeledImageElement = doc.body.firstChild as HTMLElement

        const labeledImageData =
          labeledImageCustomElement.getDataFromElement(labeledImageElement)
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              maxWidth: "99%",
              maxHeight: "95%",
            }}
          >
            <div
              style={{
                width: "50%",
                textAlign: "center",
                maxHeight: "100%",
              }}
            >
              <LabeledImageDisplay data={labeledImageData} />
            </div>
            <div
              style={{
                width: "50%",
                fontSize: content1FontSize,
                maxHeight: "100%",
              }}
              ref={content1Ref}
            >
              <ol>
                {labeledImageData.labels.map((label, index) => (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: label.text }}
                  />
                ))}
              </ol>
            </div>
          </div>
        )
      }
    }
  }

  /**
   * Renders the title slide
   *
   * @param slide The title slide
   */
  function renderTitleSlide(slide: TitleSlide) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            top: slideTopPaddingFraction * slideHeight,
            height:
              (1 - slideTopPaddingFraction - slideBottomPaddingFraction) *
              slideHeight,
            left: slideLeftPaddingFraction * slideWidth,
            right: slideRightPaddingFraction * slideWidth,
            overflow: "hidden",
            fontSize: content1FontSize,
          }}
          ref={content1Ref}
        >
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 32,
              fontFamily: branding.heading.name ?? headingFontFamily,
              color: branding.colour ?? undefined,
            }}
            dangerouslySetInnerHTML={{ __html: slide.title }}
          />
          {slide.subtitle && (
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 18,
                paddingTop: 10,
                fontFamily: branding.heading.name ?? headingFontFamily,
                color: branding.colour ?? undefined,
              }}
              dangerouslySetInnerHTML={{ __html: slide.subtitle }}
            />
          )}
        </div>
        {branding.logo && (
          <div
            style={{
              position: "absolute",
              top: logoPaddingFraction * slideHeight,
              right: logoPaddingFraction * slideWidth,
              height: logoHeightFraction * slideHeight,
            }}
          >
            <img
              src={branding.logo}
              alt=""
              style={{
                maxHeight: logoHeightFraction * slideHeight,
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </>
    )
  }

  /** Renders the header of the slide */
  function renderHeader() {
    return (
      <>
        <div
          style={{
            fontSize: headerFontSize,
            fontWeight: "bold",
            fontFamily: branding.heading.name ?? headingFontFamily,
            color: branding.colour ?? undefined,
            overflow: "hidden",
            height: slideHeight * headerHeightFraction,
            position: "absolute",
            top: slideHeight * slideTopPaddingFraction,
            left: slideWidth * slideLeftPaddingFraction,
            right: slideWidth * slideRightPaddingFraction,
          }}
          ref={headerRef}
        >
          <div
            style={{ fontSize: 24, fontWeight: "bold" }}
            dangerouslySetInnerHTML={{ __html: slide.title }}
          />
        </div>
        {branding.logo && (
          <div
            style={{
              position: "absolute",
              bottom: logoPaddingFraction * slideHeight,
              left: logoPaddingFraction * slideWidth,
              height: logoHeightFraction * slideHeight,
            }}
          >
            <img
              src={branding.logo}
              alt=""
              style={{
                maxHeight: logoHeightFraction * slideHeight,
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </>
    )
  }

  /** Renders the slide number at the bottom right of the slide */
  function renderSlideNumber() {
    const colorMap = {
      faint: "#AAA",
      normal: "black",
    }

    return (
      <div
        style={{
          position: "absolute",
          bottom: (slideBottomPaddingFraction / 2) * slideHeight,
          right: slideRightPaddingFraction * slideWidth,
          fontSize: 14,
          color: colorMap[slideNumbersMode],
          transform: "translateY(50%)",
        }}
      >
        {slideNumber}
      </div>
    )
  }
  return (
    <div style={{ margin: 40 }}>
      <SlideContainer>
        <div
          style={{
            border: "solid 1px #666",
            padding: 20,
            margin: 10,
            width: slideWidth,
            height: slideHeight,
            position: "relative",
          }}
        >
          {slide.layout === "title" && renderTitleSlide(slide)}
          {slide.layout !== "title" && renderHeader()}
          {slide.layout !== "title" && renderContent()}
          {renderSlideNumber()}
        </div>
      </SlideContainer>
      {slide.speakerNotes && (
        <div
          style={{
            fontStyle: "italic",
            maxWidth: slideWidth,
            padding: 20,
            paddingTop: 0,
          }}
          dangerouslySetInnerHTML={{ __html: slide.speakerNotes }}
        />
      )}
    </div>
  )
}

/**
 * Determines if the URL is likely for a local video or an embedded video
 * @param url The video URL
 * @returns 'video' if the URL is likely for a local video, 'iframe' otherwise
 */
function determineVideoEmbedType(url: string): "iframe" | "video" {
  // List of common video file extensions
  const videoFileExtensions = [".mp4", ".webm", ".ogg", ".mov", ".avi", ".wmv"]

  try {
    const parsedUrl = new URL(url)

    // Check if the URL's path ends with a known video file extension
    if (
      videoFileExtensions.some((extension) =>
        parsedUrl.pathname.endsWith(extension)
      )
    ) {
      return "video"
    } else {
      return "iframe"
    }
  } catch (error) {
    console.error("Invalid URL", error)
    // Default to 'iframe' for invalid or unrecognized URLs
    return "iframe"
  }
}
