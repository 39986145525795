import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { useFullscreenDialog } from "../../components/screens/SelectUrlDialog"
import CheckboxSelect from "../microlearning/CheckboxSelect"
import { OrganizationStrategy } from "../microlearning/utils/constants"
import useImportExistingCourseSource from "./hooks/useImportExistingCourseSource"

type SelectExistingCourseSourceProps = {
  onInsertHTML: (html: string) => void
  open: boolean
  setOpen: (open: boolean) => void
  currentCourseID: string
}

/**
 * Dialog component for selecting sections from existing course and importing them to editor
 * @param props - Component props
 * @param props.onInsertHTML - Callback
 * @param props.open - True if dialog is current open
 * @param props.setOpen - Function to update open state of dialog
 * @param props.currentCourseID - ID of current course we are adding content to
 */
const SelectExistingCourseSourceDialog = ({
  onInsertHTML,
  open,
  setOpen,
  currentCourseID,
}: SelectExistingCourseSourceProps) => {
  const fullScreen = useFullscreenDialog()

  const {
    availableCourses,
    selectedCourseID,
    setSelectedCourseID,
    useFullCourse,
    setUseFullCourse,
    headingLevel,
    setHeadingLevel,
    availableSections,
    handleAddSource,
    checkedSections,
    setCheckedSections,
    availableHeadings,
    error,
    loadingHTML,
  } = useImportExistingCourseSource(onInsertHTML, currentCourseID)

  const disableSelectAll = checkedSections.length === availableSections.length
  const disableDeselectAll = checkedSections.length === 0
  const disableAddSource =
    !selectedCourseID || (!useFullCourse && checkedSections.length === 0)

  return (
    <Dialog
      fullWidth
      open={open}
      fullScreen={fullScreen}
      maxWidth="sm"
      keepMounted
    >
      <DialogTitle>Import from an existing course</DialogTitle>
      <DialogContent>
        {loadingHTML ? (
          <Stack gap={2} justifyContent="center" alignItems="center">
            <CircularProgress />
            <Typography variant="body2">
              Adding existing content to your course...
            </Typography>
          </Stack>
        ) : (
          <>
            <Stack gap={2} marginTop={2}>
              <FormControl fullWidth>
                <InputLabel id="select-course-label">
                  Select a Course
                </InputLabel>
                <Select
                  labelId="select-course-label"
                  value={selectedCourseID}
                  label="Select a Course"
                  onChange={(event: any) =>
                    setSelectedCourseID(event.target.value)
                  }
                >
                  {availableCourses.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
                <FormControlLabel
                  control={
                    <Switch
                      checked={useFullCourse}
                      disabled={selectedCourseID == null}
                      onChange={(e: any) => setUseFullCourse(e.target.checked)}
                      name="useFullCourse"
                    />
                  }
                  label="Use Full Course"
                />
              </FormControl>
              <Collapse in={!useFullCourse}>
                <Stack direction="row" gap={2}>
                  <FormControl sx={{ flexGrow: 3 }}>
                    <InputLabel id="select-heading-label">
                      Select a Heading Level
                    </InputLabel>
                    <Select
                      labelId="select-heading-label"
                      value={headingLevel}
                      label="Select a Heading Level"
                      onChange={(event: any) =>
                        setHeadingLevel(event.target.value)
                      }
                    >
                      {availableHeadings.map((item: any) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                      disabled={disableSelectAll}
                      onClick={() => {
                        setCheckedSections(
                          availableSections.map((option: any) => option.key)
                        )
                      }}
                    >
                      Select All
                    </Button>
                    <Button
                      disabled={disableDeselectAll}
                      onClick={() => {
                        setCheckedSections([])
                      }}
                    >
                      Deselect All
                    </Button>
                  </Stack>
                </Stack>
                <Box
                  height={(theme) => theme.spacing(28)}
                  sx={{ overflow: "auto" }}
                  flexGrow={2}
                >
                  <CheckboxSelect
                    organizeBy={
                      useFullCourse
                        ? OrganizationStrategy.FullDocument
                        : OrganizationStrategy.Section
                    }
                    options={availableSections}
                    loading={false}
                    selection={checkedSections}
                    onSelectionChange={(selected: any) => {
                      setCheckedSections(selected)
                    }}
                    previewIndex={0}
                    onPreviewIndexChange={() => {}}
                  />
                </Box>
              </Collapse>
              {error && <Alert severity="error">{error}</Alert>}
            </Stack>

            <Divider />

            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button
                disabled={disableAddSource}
                onClick={handleAddSource}
                variant="contained"
                color="primary"
              >
                Add to Course
              </Button>
            </DialogActions>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SelectExistingCourseSourceDialog
