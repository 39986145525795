export type ExportFormat = typeof FORMAT_OPTIONS[number]["value"]

export type ScormVersion = typeof SCORM_VERSION_OPTIONS[number]["value"]

export type QuizExportType = typeof QUIZ_EXPORT_OPTIONS[number]["value"]

export type LmsTargetName = "lms-skilljar" | "lms-scormcloud"
export interface LmsTarget {
  value: LmsTargetName
  label: string
}

export type ExportTargetName =
  | "lms-skilljar"
  | "lms-scormcloud"
  | "google-drive"
  | "download"
  | "preview"

/**
 * Selection Data for Course Format, Audience, Skill Level.
 */
export const MODALITY_OPTIONS = [
  "Instructor Led",
  "Self-paced",
  "Virtual Instructor Led",
  "Microlearning",
]
export const AUDIENCE_OPTIONS = ["Customer", "Partner", "Employee"]
export const SKILL_LEVEL_OPTIONS = [
  "Introductory - Basic level concepts requiring little or no prerequisite knowledge",
  "Beginner - Foundational or overview concepts requiring basic prerequisite knowledge",
  "Intermediate - Focus on detailed aspects of complex solutions intended for experienced participants",
  "Advanced - Narrow topic focus on highly complex concepts requiring solid prerequisite knowledge",
]

export const SCORM_VERSION_OPTIONS = [
  { value: "scorm-1.2", label: "SCORM v1.2" },
  { value: "scorm-2004.3", label: "SCORM 2004 3rd Ed." },
  { value: "scorm-xapi", label: "xapi" },
  { value: "scorm-cmi5", label: "cmi5" },
]

export const QUIZ_EXPORT_OPTIONS = [
  {
    value: "quiz-csv-certiverse",
    label: "Questions CSV Certiverse (.csv) BETA",
  },
  { value: "quiz-qti-v21", label: "Questions QTI v2.1 (.zip) BETA" },
  { value: "quiz-qti-v22", label: "Questions QTI v2.2 (.zip) BETA" },
  { value: "quiz-qti-v30", label: "Questions QTI v3.0 (.zip) BETA" },
]

export const SCORM_LMS_TARGETS: LmsTarget[] = [
  { value: "lms-scormcloud", label: "Publish to SCORMCloud" },
  { value: "lms-skilljar", label: "Publish to Skilljar" },
]

export const FORMAT_OPTIONS = [
  { value: "scorm", label: "eLearning (SCORM/xAPI)" },
  { value: "pptx", label: "Instructor-Led (.pptx)" },
  { value: "docx", label: "Learner Guide (.docx)" },
  { value: "adoc", label: "AsciiDoc (.adoc) BETA" },
  { value: "epub", label: "eBook (.epub)" },
  { value: "video-script", label: "Video Script (.docx)" },
  { value: "scorm-html", label: "Webpage (.html)" },
  { value: "html", label: "Archive for import to LEAi (.zip)" },
  { value: "quiz", label: "Questions (QTI/CSV) BETA" },
] as const

export const MAX_REGISTRATION_CAP = 10
export const DEFAULT_REGISTRATION_CAP = 10
export const DEFAULT_PASSING_SCORE = 80

/**
 * The export process can take time, and uses an Async Job Service
 * on the server to prepare the export file.
 *
 * The following objects define states and user messages for each of
 * the async job steps.
 */
export const asyncStatusMessage = {
  start: "Starting export process.",
  processing: "Export in progress.",
  fetch_course: "Loading course details.",
  create_export_assets:
    "Building export files. This may take several minutes to complete...",
  publish_url: "Preparing export for download.",
  complete: "",
  failed:
    "Export of course failed. Systems may be busy. Please try again later.",
}
//the server side enumerations
export const exportAsyncStatusEnum = {
  START: "start",
  FETCH_COURSE: "fetch_course",
  CREATE_EXPORT_ASSETS: "create_export_assets",
  PUBLISH_URL: "publish_url",
  COMPLETE: "complete",
  FAILED_NO_RETRY: "failed",
}
