/** Utility functions related to remarks */

import { Detection, DocumentLocation } from "../store/remarks/remarks"
import {
  getPathFromNode,
  isBlockElement,
  getElementByPath,
  getPrevPathElement,
  getNextPathElement,
  getChildElementsForPath,
} from "./domUtils"

/** Given an element (must be a block element), create the location structure (see docs/comments.md in lex-api)
 *
 * @param node - The element to create the location from.
 * @param rootNode - The root element of the document.
 * @returns The location structure or null if not a valid element
 */
export function getLocationFromElement(
  node: HTMLElement,
  rootNode: HTMLElement
): DocumentLocation | null {
  if (!node || !("tagName" in node)) {
    return null
  }

  // tag is the block element tag
  const tag = node.tagName.toLowerCase()

  // Get path of node
  const path = getPathFromNode(node, rootNode)
  const text = node.textContent ?? ""

  return { path, tag, text, offset: null }
}

/** Given a range, create the location structure (see docs/comments.md in lex-api)
 *
 * @param range - The range to create the location from.
 * @param rootNode - The root element of the document.
 * @returns The location structure or null if the range is invalid.
 */
export function getLocationFromRange(
  range: Range,
  rootNode: HTMLElement
): DocumentLocation | null {
  // Move back in tree order to get first block parent element
  let offset = 0
  let node = range.startContainer

  // Add fragment to offset if text node
  if (node.nodeType === Node.TEXT_NODE) {
    offset += range.startOffset
  }

  // Work backwards to block node
  while (!isBlockElement(node)) {
    // Move leftwards, adding text contents to offset
    while (node.previousSibling) {
      node = node.previousSibling
      offset += node.textContent?.length ?? 0
    }

    node = node.parentNode as HTMLElement
  }

  // Build text by moving forward until we reach the end of the range
  let text = ""
  let endNode: Node | null = range.startContainer
  let startOffset = range.startOffset

  while (true) {
    // If reached the end node
    if (endNode === range.endContainer) {
      text +=
        endNode.textContent?.substr(
          startOffset,
          range.endOffset - startOffset
        ) ?? ""
      break
    }
    // Add all text content if text node
    if (endNode.nodeType === Node.TEXT_NODE) {
      text += endNode.textContent?.substr(startOffset) ?? ""
    }

    startOffset = 0

    // Move forward to next node
    if (endNode.firstChild) {
      endNode = endNode.firstChild
    } else if (endNode.nextSibling) {
      endNode = endNode.nextSibling
    } else {
      endNode = endNode.parentNode as HTMLElement | null
      while (endNode && !endNode.nextSibling) {
        endNode = endNode.parentNode as HTMLElement | null
      }
      if (!endNode) {
        break
      }

      endNode = endNode.nextSibling as HTMLElement | null
    }
    if (!endNode) {
      break
    }

    // If has moved to another block element, break
    if (isBlockElement(endNode)) {
      break
    }
  }

  if (!node || !("tagName" in node)) {
    return null
  }

  // tag is the block element tag
  const tag = (node as HTMLElement).tagName.toLowerCase()

  // Get path of node
  const path = getPathFromNode(node as HTMLElement, rootNode)

  return { path, tag, offset, text }
}

/** Given a location, find the corresponding range in the editor element
 * It may require a fuzzy search, as the location may have moved in the
 * document since the comment was added.
 *
 * @param location - The location to find the range for.
 * @param rootElem - The root element of the document.
 * @returns a range or null if not found.
 */
export function findRangeForLocation(
  location: DocumentLocation,
  rootElem: HTMLElement
): { range: Range; matchElement: HTMLElement } | null {
  // Find the closest block element using the path
  let { element, path } = getElementByPath(location.path, rootElem)

  // For block comments, find a matching element with 75% similarity
  if (location.offset == null) {
    const { matchElement } = findClosestMatchingElement(
      element,
      path,
      location.tag,
      location.text
    )

    if (matchElement == null) {
      return null
    }

    if (location.numBlocks && location.numBlocks > 1) {
      // Initialize range to encompass multiple blocks
      const range = document.createRange()
      range.selectNodeContents(matchElement)

      // Get all path elements for the parent of the match element
      const pathElements = getChildElementsForPath(matchElement.parentElement!)

      // Find the index of the match element in the path elements
      const matchIndex = pathElements.indexOf(matchElement)

      // Get the index of the last of the path elements
      const lastIndex = Math.min(
        matchIndex + location.numBlocks - 1,
        pathElements.length - 1
      )

      // Set the end of the range to the last path element
      range.setEnd(
        pathElements[lastIndex],
        pathElements[lastIndex].childNodes.length
      )

      return { range, matchElement }
    } else {
      // Create range that surrounds the single element
      const range = document.createRange()
      range.selectNodeContents(matchElement)
      return { range, matchElement }
    }
  } else {
    // For range comments, first find matching element
    const { matchElement, matchText } = findClosestMatchingElement(
      element,
      path,
      location.tag,
      location.text
    )

    if (matchElement == null) {
      return null
    }

    // Get text of element
    const textContent = matchElement.textContent ?? ""

    // Find the offsets of the text in the element
    const matches = [
      ...textContent.matchAll(new RegExp(escapeRegExp(matchText!), "g")),
    ]

    let bestMatch = null
    let bestDistance = null
    for (const match of matches) {
      const distance = Math.abs(match.index! - location.offset)
      if (
        bestMatch == null ||
        (bestDistance != null && distance < bestDistance)
      ) {
        bestMatch = match
        bestDistance = distance
      }
    }

    if (bestMatch == null) {
      return null
    }

    const start = convertOffsetToTextNodeOffset(matchElement, bestMatch.index!)
    const end = convertOffsetToTextNodeOffset(
      matchElement,
      bestMatch.index! + matchText!.length!
    )
    if (start.node == null || end.node == null) {
      return null
    }
    const range = document.createRange()
    range.setStart(start.node, start.offset)
    range.setEnd(end.node, end.offset)
    return { range, matchElement }
  }
}

/** Finds the element that closest matches the text. Returns:
 * { matchElement, matchText } where matchText is the actual text found
 * that may be a substring of text */
function findClosestMatchingElement(
  element: HTMLElement,
  path: number[],
  tag: string,
  text: string
):
  | { matchElement: HTMLElement; matchText: string }
  | { matchElement: null; matchText: null } {
  // Threshold of matching words fraction to be considered a match
  const MATCH_THRESHOLD = 0.75

  // If element is a good match, return it
  const { matchText, score } = matchElementToTagAndText(element, tag, text)
  if (score >= MATCH_THRESHOLD) {
    return { matchElement: element, matchText }
  }

  // Search upward and downward simultaneously looking for a good match
  // as the element may have moved up or down
  let upwardResult: { element: HTMLElement | null; path: number[] } = {
    element,
    path,
  }
  let downwardResult: { element: HTMLElement | null; path: number[] } = {
    element,
    path,
  }

  while (true) {
    if (upwardResult.element) {
      upwardResult = getPrevPathElement(upwardResult.element, upwardResult.path)
      if (upwardResult.element != null) {
        const { matchText, score } = matchElementToTagAndText(
          upwardResult.element,
          tag,
          text
        )
        if (score >= MATCH_THRESHOLD) {
          return { matchElement: upwardResult.element, matchText }
        }
      }
    }

    if (downwardResult.element) {
      downwardResult = getNextPathElement(
        downwardResult.element,
        downwardResult.path
      )
      if (downwardResult.element != null) {
        const { matchText, score } = matchElementToTagAndText(
          downwardResult.element,
          tag,
          text
        )
        if (score >= MATCH_THRESHOLD) {
          return { matchElement: downwardResult.element, matchText }
        }
      }
    }

    if (upwardResult.element == null && downwardResult.element == null) {
      return { matchElement: null, matchText: null }
    }
  }
}

/** Determine how close of a match (0 - 1) the element is to the
 * tag and text. 0 means no match, 1 means perfect match.
 *
 * @param element - The element to match.
 * @param tag - The tag to match.
 * @param text - The text to match.
 * @returns { matchText, score }
 */
function matchElementToTagAndText(
  element: HTMLElement,
  tag: string,
  text: string
) {
  // If type doesn't match, no match
  if (element.tagName.toLowerCase() !== tag.toLowerCase()) {
    return { matchText: "", score: 0 }
  }

  // Try successively smaller text fragments until we find a match. Start must always match
  for (let frac = 1; frac > 0; frac -= 0.1) {
    const start = text.substr(0, Math.floor(text.length * frac))
    if (element.textContent?.includes(start)) {
      return { matchText: start, score: frac }
    }
  }
  return { matchText: "", score: 0 }
}

/** Escape regex characters
 *
 * @param s - The string to escape.
 * @returns The escaped string.
 */
function escapeRegExp(s: string) {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")
}

/** Using a starting element, convert an offset to an exact text node
 * and offset within the text node by traversing the DOM.
 *
 * @param startElem - The element to start the search from.
 * @param offset - The offset to convert.
 * @returns The text node and offset within the text node.
 */
function convertOffsetToTextNodeOffset(startElem: HTMLElement, offset: number) {
  let node: Node | null = startElem
  let nodeOffset = 0

  while (node != null) {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent ?? ""
      if (nodeOffset + text.length >= offset) {
        return { node, offset: offset - nodeOffset }
      }
      nodeOffset += text.length
    }
    node = getNextNode(node)
  }
  return { node: null, offset: 0 }
}

/** Given a node, return the next node in the DOM tree.
 *
 * @param node - The node to get the next node from.
 * @returns The next node in the DOM tree.
 */
function getNextNode(node: Node) {
  if (node.firstChild) {
    return node.firstChild
  }
  if (node.nextSibling) {
    return node.nextSibling
  }
  while (node != null && node.parentNode != null) {
    node = node.parentNode
    if (node.nextSibling) {
      return node.nextSibling
    }
  }
  return null
}

/** Check if a detection is a section detection
 *
 * @param detection The detection to check
 * @returns Whether the detection is a section detection
 */
export function isSectionDetection(detection: Detection) {
  return detection.rule === "interactive_component"
}

/** Fix for LD-2865: Check for and correct bad path sequence
 *
 * See https://learnexperts.atlassian.net/browse/LD-2865
 *
 * There was a bug in which the fr-element was not considered a parent element, which means that
 * the block elements at the root level were not being considered as path elements. This led to
 * it recursing up the DOM tree to find the correct parent element and ending up at the <html> element.
 *
 * The path has a very peculiar pattern to it, which we can use to determine if this is what occurred.
 *
 * The offset in the bad locations is all of the text in the document up to the range.
 *
 * We correct this by making that into a range and then recalculating its correct location.
 *
 * To do this, we look for any matches for the exact text string and rank them from the one that's
 * closest to the offset specified to the farthest.
 *
 * If no match is found, we then find the text node and position that contains the offset and return
 * that as the location with the text set to the original text.
 *
 * @param location - The location to fix.
 * @param rootElem - The root element of the document.
 * @returns The fixed location or null if the location is out of range and no match is found.
 */
export function fixLocationForLD2865(
  location: DocumentLocation | null,
  rootElem: HTMLElement
): DocumentLocation | null {
  if (location == null) {
    return null
  }

  // Check for the bad path sequence that only (likely) occurs in LD-2865
  if (
    !location.path.join(",").includes(",1,1,0,1,2,0,") ||
    location.offset == null
  ) {
    return location
  }

  // Find matching text as close as possible to the offset
  const matches: { range: Range; offset: number }[] = []

  // Recursively search through descendants to find all matches
  const findMatchesInElement = (
    element: Node,
    currentOffset: number
  ): number => {
    if (element.nodeType === Node.TEXT_NODE && element.textContent) {
      const text = element.textContent

      let index = 0
      // Find all occurrences of location.text in this text node
      while ((index = text.indexOf(location.text, index)) !== -1) {
        const range = document.createRange()
        range.setStart(element, index)
        range.setEnd(element, index + location.text.length)
        matches.push({
          range,
          offset: currentOffset + index,
        })
        index += location.text.length
      }

      return text.length
    }

    let offset = 0
    let child = element.firstChild
    while (child) {
      offset += findMatchesInElement(child, currentOffset + offset)
      child = child.nextSibling
    }
    return offset
  }

  // If there is text to search for, find all matches
  if (location.text) {
    findMatchesInElement(rootElem, 0)

    if (matches.length > 0) {
      // Find the closest match
      const bestMatch = matches.reduce((best, curr) => {
        return Math.abs(best.offset - location.offset!) <
          Math.abs(curr.offset - location.offset!)
          ? best
          : curr
      }, matches[0])

      // Get the location of the best match
      const bestMatchLocation = getLocationFromRange(bestMatch.range, rootElem)

      return bestMatchLocation
    }
  }

  // No match found, or no text to search for, so find the text node and position that contains the offset
  let currentOffset = 0
  let targetNode: Node | null = null
  let targetPosition = 0

  /** Find the text node and position that contains the offset
   *
   * @param node - The node to search within.
   * @returns Whether the offset was found.
   */
  const findOffsetPosition = (node: Node): boolean => {
    if (node.nodeType === Node.TEXT_NODE && node.textContent) {
      const length = node.textContent.length
      if (currentOffset + length > location.offset!) {
        targetNode = node
        targetPosition = location.offset! - currentOffset
        return true
      }
      currentOffset += length
    }

    let child = node.firstChild
    while (child) {
      if (findOffsetPosition(child)) {
        return true
      }
      child = child.nextSibling
    }
    return false
  }

  findOffsetPosition(rootElem)

  // If found a target node, create a range from it and return the new location
  if (targetNode) {
    const range = document.createRange()
    range.setStart(targetNode, targetPosition)
    range.setEnd(targetNode, targetPosition)
    const newLocation = getLocationFromRange(range, rootElem)
    // Override the text with the original text since the new location will have blank text
    // since it creates an empty range at the target position
    return newLocation ? { ...newLocation, text: location.text } : null
  }
  return null
}
