import { ExportFormat, FORMAT_OPTIONS } from "../utils/contants"
import { useFlag } from "../../../utilities/feature-management"

export type FormatOption = Readonly<{
  value: ExportFormat
  label: string
}>

/**
 * Provides the list of available export formats.
 */
const useFormatOptions = () => {
  const rolloutEpubExport = useFlag("rollout-epub-export")
  const rolloutAdocExport = useFlag("rollout-export-asciidoc")
  const rolloutCsvExport = useFlag("rollout-export-csv")
  const rolloutQtiExport = useFlag("rollout-export-qti")

  /**
   * Filter out unavailable format options
   * @param options - output format options
   */
  const filterOptions = (options: FormatOption): boolean => {
    if (options.value === "epub") {
      return rolloutEpubExport === true
    } else if (options.value === "adoc") {
      return rolloutAdocExport === true
    } else if (options.value === "quiz") {
      return rolloutCsvExport === true || rolloutQtiExport === true
    } else {
      return true
    }
  }

  const formatOptions: ReadonlyArray<FormatOption> =
    FORMAT_OPTIONS.filter(filterOptions)

  return { formatOptions }
}

export default useFormatOptions
