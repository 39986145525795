import { useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"

/**
 * Hook to prevent navigation when there are unsaved changes
 * @param options - Options for the hook
 * @param options.shouldPrevent - Boolean indicating if navigation should be prevented
 * @param options.message - Optional custom message to show in the confirmation dialog (only for navigation prevention)
 * @param options.onBlockedNavigation - Optional function to call when the user attempts to navigate away and was blocked
 * @returns void
 */
export function usePreventNavigation(options: {
  /** Boolean indicating if navigation should be prevented */
  shouldPrevent: boolean
  /** The message to show in the confirmation dialog */
  message?: string
  /** The function to call when the user attempts to navigate away and was blocked */
  onBlockedNavigation?: () => void
}) {
  const location = useLocation()
  const history = useHistory()

  const { shouldPrevent, message, onBlockedNavigation } = options

  // Handle browser close/refresh
  useEffect(() => {
    if (!shouldPrevent) return

    /**
     * Handle browser close/refresh
     * @param e - The beforeunload event
     */
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = message

      if (onBlockedNavigation) {
        setTimeout(() => {
          onBlockedNavigation()
        }, 100)
      }

      return message
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => window.removeEventListener("beforeunload", handleBeforeUnload)
  }, [shouldPrevent, message, onBlockedNavigation])

  // Handle route changes at react-router-dom level
  useEffect(() => {
    if (!shouldPrevent) return

    return history.block((location, action) => {
      if (onBlockedNavigation) {
        setTimeout(() => {
          onBlockedNavigation()
        }, 100)
      }
      return message
    })
  }, [location, history, shouldPrevent, message, onBlockedNavigation])
}
