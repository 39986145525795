/* eslint-disable jsx-a11y/media-has-caption */
import { flushSync, render } from "react-dom"
import { uuidv4 } from "../../utilities/domUtils"
import { AudioData } from "./InsertAudioDialog"

/**
 * Create basic audio element to be displayed in Froala editor
 * @param data - Audio data to create element from
 */
const createAudioHTML = (data: AudioData) => {
  const { url, title, fileName, id } = data

  const div = document.createElement("div")

  const uuid = uuidv4()

  flushSync(() => {
    render(
      <div
        id={`audio-${uuid}`}
        data-media-id={id}
        contentEditable="false"
        draggable="true"
        className="audio-clickable fr-non-draggable fr-deletable"
      >
        <span className="audio-title">{title}</span>
        <audio
          className="audio-url"
          preload="metadata"
          controls
          src={url}
          data-filename={fileName}
        />
      </div>,
      div
    )
  })

  const html = div.innerHTML
  return html
}

export default createAudioHTML
