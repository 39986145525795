import { Stack, Typography } from "@mui/material"
import ColourSelectionControl from "./ColourSelectionControl"
import ColourThemeSelectionControl from "./ColourThemeSelectionControl"
import FontSelectionControl from "./FontSelectionControl"
import ImageUploadControl from "./ImageUploadControl"
import SizeSelectionControl from "./SizeSelectionControl"
import Grid from "@mui/material/Grid"
import useFlag from "../../../../../utilities/feature-management/useFlag"
import BrandingStylesPicker from "../../../../branding/BrandingStylesPicker"
import OldImageUploadControl from "./OldImageUploadControl"
import EditorCustomFontsControl from "../EditorCustomFontsControl"

type BrandingFormProps = {
  branding: any
  onPropertyChange: (property: string, url: string | null, file: any) => void
}

/**
 * Component that displays controls for setting branding options
 * @param props - Component props
 */
const BrandingForm = (props: BrandingFormProps) => {
  const { branding, onPropertyChange } = props

  const useNewColorControls = useFlag("rollout-branding-new-color-controls")
  const enableNewInsertDialog = useFlag("rollout-new-insert-image-dialog")
  const enableCustomFontsInEditor = useFlag("rollout-custom-fonts-in-editor")

  return (
    <Grid spacing={2} columnSpacing={4} container>
      <Grid item xs={12} lg={6}>
        <Stack gap={2}>
          {enableNewInsertDialog ? (
            <>
              <ImageUploadControl
                title="Logo"
                imageUrl={branding.logo}
                onChange={(imageUrl: string | null, file: any) =>
                  onPropertyChange("logo", imageUrl, file)
                }
                imageDisplayWidth={140}
                helpText="Image should be at most 140 pixels wide and 80 pixels high."
              />
              <ImageUploadControl
                title="Brand Mark"
                imageUrl={branding.brandMark}
                onChange={(imageUrl: string | null, file: any) =>
                  onPropertyChange("brandMark", imageUrl, file)
                }
                maxWidth={24}
                imageDisplayWidth={40}
                helpText="Image should be square and at least 40 pixels wide."
              />
            </>
          ) : (
            <>
              <OldImageUploadControl
                title="Logo"
                imageUrl={branding.logo}
                onChange={(imageUrl: string | null, file: any) =>
                  onPropertyChange("logo", imageUrl, file)
                }
              />
              <OldImageUploadControl
                title="Brand Mark"
                imageUrl={branding.brandMark}
                onChange={(imageUrl: string | null, file: any) =>
                  onPropertyChange("brandMark", imageUrl, file)
                }
                maxWidth={24}
              />
            </>
          )}
          {useNewColorControls ? (
            <ColourThemeSelectionControl {...props} />
          ) : (
            <ColourSelectionControl {...props} />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack gap={2}>
          <Stack>
            <Typography gutterBottom variant="titleMedium">
              Fonts
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              For best results with Word and PowerPoint exports, please use
              embeddable fonts. Most TrueType (.ttf) and OpenType (.otf) fonts
              allow embedding, also avoid PostScript-based fonts as they may
              cause compatibility issues.
            </Typography>
            <Stack gap={2}>
              <FontSelectionControl
                title="Heading"
                variant="heading"
                {...props}
              />
              <FontSelectionControl title="Body" variant="body" {...props} />
            </Stack>
          </Stack>
          {enableCustomFontsInEditor && <EditorCustomFontsControl {...props} />}
          <SizeSelectionControl {...props} />
          <BrandingStylesPicker {...props} data-cy="styles-picker" />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default BrandingForm
