import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import CheckboxSelect from "./CheckboxSelect"
import { FORMAT_OPTIONS, OrganizationStrategy } from "./utils/constants"
import LoadingMicroLearningBackdrop from "./LoadingMicroLearningBackdrop"
import Breadcrumbs from "../../components/atoms/Breadcrumbs"
import pluralize from "../outline/utils/pluralize"
import useFlag from "../../utilities/feature-management/useFlag"
import { FormData } from "./hooks/useMicrolearning"
import {
  ButtonEvents,
  useMixpanelCourseTracker,
} from "../../utilities/mixpanel"
import ExportButton from "../export/ExportButton"
import { ExportLocation, GoogleWorkspaceType } from "../export/ExportTypes"
import useExportToGoogleDrive from "../export/hooks/useExportToGoogleDrive"
import { ExportTargetName } from "../export/utils/contants"
import GoogleDriveAccessDialog from "../createCourse/GoogleDriveAccessDialog"
import { GoogleDriveUploadResult } from "../export/api/uploadToGoogleDrive"
import GoogleUploadDialog from "../../components/screens/GoogleUploadDialog"

interface MicrolearningFormProps {
  headingLevel: string
  handleHeadingLevelSelect: (event: any) => void
  handleOrganizeBySelect: (event: any) => void
  handleExportMicroLearning: (
    exportTarget: ExportTargetName,
    saveTo?: ExportLocation
  ) => void
  handleExportMicrolearningToGoogleDrive: (
    location: ExportLocation
  ) => Promise<void>
  handleSaveAsMicroLearning: () => void
  exportInProgress: boolean
  isLoading: boolean
  optionList: any[]
  formData: FormData
  updateField: (field: keyof FormData, value: any) => void
  availableHeadings: any[]
  course: any
  createPath: boolean
  setCreatePath: React.Dispatch<React.SetStateAction<boolean>>
  courseCount: number
  previewIndex: number
  setPreviewIndex: React.Dispatch<React.SetStateAction<number>>
  availableFolders: any[]
  selectedFolder: string
  setSelectedFolder: React.Dispatch<React.SetStateAction<string>>
  saveToGoogleResult: GoogleDriveUploadResult | null
  setSaveToGoogleResult: (result: GoogleDriveUploadResult | null) => void
  onExit: () => void
}

/**
 * Form to show microlearning generation options
 * @param props - Component props
 * @param props.course - Course object for determining breadcrumb trail
 */
const MicrolearningForm = (props: MicrolearningFormProps) => {
  const {
    headingLevel,
    handleHeadingLevelSelect,
    handleOrganizeBySelect,
    handleExportMicroLearning,
    handleSaveAsMicroLearning,
    handleExportMicrolearningToGoogleDrive,
    exportInProgress,
    isLoading,
    optionList,
    formData,
    updateField,
    availableHeadings,
    course,
    createPath,
    setCreatePath,
    courseCount,
    previewIndex,
    setPreviewIndex,
    availableFolders,
    selectedFolder,
    setSelectedFolder,
    saveToGoogleResult,
    onExit,
    setSaveToGoogleResult,
  } = props

  const {
    checkedOptions,
    organizeBy,
    condensePercent,
    format,
    transformComponentsToText,
    transformMediaToText,
  } = formData

  const {
    handleGoogleDriveExport,
    googleDriveAccessPromptDialogOpen,
    handleCloseGoogleDriveAccessPromptDialog,
    handleOkGoogleDriveAccessPromptDialog,
  } = useExportToGoogleDrive(handleExportMicrolearningToGoogleDrive)

  const deselectAllDisabled =
    organizeBy === OrganizationStrategy.FullDocument || courseCount === 0

  const selectAllDisabled =
    organizeBy === OrganizationStrategy.FullDocument ||
    courseCount === optionList.length

  const foldersEnabled = useFlag("rollout-course-folders")

  const transformComponentsToTextEnabled = useFlag(
    "rollout-ml-transform-components-to-text"
  )

  const transformMediaToTextEnabled = useFlag(
    "rollout-ml-transform-media-to-text"
  )

  /**
   * Include course breadcrumb if we have it in state
   */
  const breadcrumbs = [
    { label: "Courses", href: "/" },
    ...(course
      ? [
          {
            label: `"${course.title}"`,
            href: `/editor?course=${course.id}`,
          },
        ]
      : []),
    { label: "Microlearning" },
  ]

  const trackExportButtonClick = useMixpanelCourseTracker(
    ButtonEvents.MicrolearningExportCourse,
    course
  )
  const trackSaveButtonClick = useMixpanelCourseTracker(
    ButtonEvents.MicrolearningPreviewCourse,
    course
  )

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        padding={2}
        flexGrow={1}
      >
        {/*@ts-ignore*/}
        <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ marginBottom: 2 }} />
        <Box>
          <Typography variant="h5" gutterBottom>
            Microlearning
          </Typography>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="microlearning-format-label">Organize By</InputLabel>
            <Select
              data-cy="Microlearning-format"
              labelId="microlearning-format-label"
              value={organizeBy}
              label="Organize By"
              onChange={handleOrganizeBySelect}
            >
              <MenuItem value={OrganizationStrategy.FullDocument}>
                Full Course
              </MenuItem>
              <MenuItem
                disabled={availableHeadings.length === 0}
                value={OrganizationStrategy.Section}
              >
                Section
              </MenuItem>
              <MenuItem value={OrganizationStrategy.Concept}>Concept</MenuItem>
            </Select>
            <FormHelperText>
              Control how the document will be organized into microlearning.
            </FormHelperText>
          </FormControl>
          {organizeBy === OrganizationStrategy.Section && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="microlearning-heading-label">
                Heading Level
              </InputLabel>
              <Select
                labelId="microlearning-heading-label"
                value={headingLevel}
                label="Heading Level"
                onChange={handleHeadingLevelSelect}
              >
                {availableHeadings.map((heading: any) => (
                  <MenuItem
                    key={heading.value}
                    value={heading.value}
                    data-cy="micro-learning-header-option"
                  >
                    {heading.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Typography variant="body1" gutterBottom>
            Summarize
          </Typography>
          <FormControl fullWidth sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <Slider
              data-cy="Microlearning-Summarize"
              aria-label="Summarize"
              value={condensePercent}
              onChange={(e: any, newValue: number | number[]) =>
                updateField("condensePercent", newValue as number)
              }
              marks={[
                { value: 0, label: "0%" },
                { value: 25, label: "25%" },
                { value: 50, label: "50%" },
                { value: 75, label: "75%" },
              ]}
              getAriaValueText={(value) => `${value}% reduction`}
              valueLabelDisplay="auto"
              step={25}
              min={0}
              max={75}
            />
            <FormHelperText>
              Control how much the results are condensed by. A higher value will
              produce smaller courses.
            </FormHelperText>
          </FormControl>

          {transformComponentsToTextEnabled && condensePercent > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2, mb: 2 }}
            >
              <Box>
                <Typography variant="body1">
                  Transform Interactive Components
                </Typography>
                <FormHelperText>
                  Convert interactive components into text
                </FormHelperText>
              </Box>
              <Switch
                data-cy="transform-components-switch"
                checked={transformComponentsToText}
                onChange={(e) =>
                  updateField("transformComponentsToText", e.target.checked)
                }
              />
            </Box>
          )}

          {transformMediaToTextEnabled && condensePercent > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2, mb: 2 }}
            >
              <Box>
                <Typography variant="body1">
                  Exclude Multimedia Elements
                </Typography>
                <FormHelperText>
                  Exclude images, video, and audio from the output
                </FormHelperText>
              </Box>
              <Switch
                data-cy="transform-media-switch"
                checked={transformMediaToText}
                onChange={(e) =>
                  updateField("transformMediaToText", e.target.checked)
                }
              />
            </Box>
          )}

          <Box marginTop={2} display="flex" justifyContent="flex-end">
            <Button
              disabled={selectAllDisabled}
              onClick={() => {
                updateField(
                  "checkedOptions",
                  optionList.map((option: any) => option.key)
                )
              }}
            >
              Select All
            </Button>
            <Button
              disabled={deselectAllDisabled}
              onClick={() => {
                updateField("checkedOptions", [])
              }}
            >
              Deselect All
            </Button>
          </Box>
        </Box>
        <Box sx={{ overflow: "auto" }} flexGrow={2}>
          <CheckboxSelect
            data-cy="microlearning-checkbox"
            organizeBy={organizeBy}
            options={optionList}
            selection={checkedOptions}
            onSelectionChange={(selected: any) =>
              updateField("checkedOptions", selected)
            }
            previewIndex={previewIndex}
            onPreviewIndexChange={setPreviewIndex}
            loading={isLoading}
          />
        </Box>
        <Stack spacing={2}>
          <Box marginTop={2}>
            <FormControl fullWidth>
              <InputLabel id="format-label">Format</InputLabel>
              <Select
                data-cy="Microlearning-format-label"
                labelId="format-label"
                id="format-select"
                value={format}
                label="Format"
                onChange={(e: any) => updateField("format", e.target.value)}
              >
                {FORMAT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box marginBottom={1}>
              <Typography variant="body1" sx={{ marginBottom: 1 }} gutterBottom>
                Save as Learning Path
              </Typography>
              <FormHelperText>
                The new microlearning courses will all be saved within a new
                learning path
              </FormHelperText>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Switch
              data-cy="Microlearning-learning-path"
              checked={courseCount <= 1 ? false : createPath}
              disabled={courseCount <= 1}
              size="medium"
              onChange={(e: any) => setCreatePath(e.target.checked)}
            />
          </Box>
          {foldersEnabled && (
            <Box marginTop={2}>
              <FormControl fullWidth>
                <InputLabel id="format-label">Destination Folder</InputLabel>
                <Select
                  data-cy="Microlearning-destination-folder"
                  labelId="folder-label"
                  id="folder-select"
                  value={selectedFolder}
                  label="Destination Folder"
                  onChange={(e: any) => setSelectedFolder(e.target.value)}
                >
                  <MenuItem value={"all_courses"}>All Courses</MenuItem>
                  {availableFolders.map((folder: any) => (
                    <MenuItem
                      key={folder.id}
                      value={folder.id}
                      data-cy="destination-folder-items"
                    >
                      {folder.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <ExportButton
              disabled={courseCount === 0}
              isLoading={isLoading}
              format={format}
              lmsTargets={[]}
              onExportToFile={() => {
                handleExportMicroLearning("download")
                trackExportButtonClick()
              }}
              onExportToGoogle={(saveTo: GoogleWorkspaceType) => {
                handleGoogleDriveExport(saveTo)
                trackExportButtonClick()
              }}
              onExportToLms={() => {}}
            />

            <Button
              data-cy="Microlearning-save-as"
              variant="contained"
              sx={{ boxShadow: "none" }}
              disabled={courseCount === 0}
              onClick={() => {
                handleSaveAsMicroLearning()
                trackSaveButtonClick()
              }}
            >
              Save as {pluralize("course", courseCount, true)}
            </Button>
          </Box>
        </Stack>
      </Box>
      <LoadingMicroLearningBackdrop open={exportInProgress} />
      <GoogleDriveAccessDialog
        open={googleDriveAccessPromptDialogOpen}
        onClose={handleCloseGoogleDriveAccessPromptDialog}
        onOk={handleOkGoogleDriveAccessPromptDialog}
        upload={true}
      />
      <GoogleUploadDialog
        courseTitle={course.title}
        result={saveToGoogleResult}
        onClose={() => {
          setSaveToGoogleResult(null)
          onExit()
        }}
      />
    </>
  )
}

export default MicrolearningForm
