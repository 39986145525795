/* Utilities related to DOM */
import { Detection } from "../store/remarks/remarks"
import { isSmartTemplateControl } from "./smartTemplates"
import FroalaEditor from "froala-editor"
import { uploadFroalaImage } from "../api"
import { v4 as uuid } from "uuid"

// Selector to find the Froala editor
const EDITOR_DIV_SELECTOR = "fr-wrapper"

// Default options for flash
const DEFAULT_FLASH_OPTIONS = {
  duration: 2000, // 2 seconds
  classname: "highlighted",
}
/**
 * Holds the base64 source of an image
 */
export interface Base64ImageData {
  replacedKey: string
  base64Src: string
}

/** Elements that LearnExperts considers block elements */
export const BLOCK_ELEMENTS = [
  "address",
  "article",
  "aside",
  "blockquote",
  "details",
  "dialog",
  "dd",
  "div",
  "dl",
  "dt",
  "fieldset",
  "figcaption",
  "figure",
  "footer",
  "form",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "header",
  "hgroup",
  "hr",
  "li",
  "main",
  "nav",
  "ol",
  "p",
  "pre",
  "section",
  "table",
  "tbody",
  "td",
  "tfoot",
  "th",
  "thead",
  "tr",
  "ul",
]

/** Compare two paths (arrays of indexes) and determine which is larger. 0 and null/undefined
 * are equal so that [2] is the same as [2, 0]
 */
export function comparePaths(a: number[], b: number[]): number {
  // Both null is end of list
  if (a[0] == null && b[0] == null) {
    return 0
  }
  // First part is equal, slice and redo
  if ((a[0] || 0) === 0 && (b[0] || 0) === 0) {
    return comparePaths(a.slice(1), b.slice(1))
  }
  if (a[0] > b[0]) {
    return -1
  }
  if (a[0] < b[0]) {
    return 1
  }
  if (a[0] == null && b[0] != null) {
    return 1
  }
  if (a[0] != null && b[0] == null) {
    return -1
  }
  // First part is equal, slice and redo
  return comparePaths(a.slice(1), b.slice(1))
}

/**
 * Momentarily highlight a given element.
 *
 * @param element is the element to flash
 * @param options is an object with duration and classname properties
 * @param options.duration is the number of milliseconds to flash for (default 2000)
 * @param options.classname is the class name to add to the element (default "highlighted")
 */
export function flash(
  element: HTMLElement,
  options: { duration: number; classname: string }
) {
  const { duration, classname } = { ...DEFAULT_FLASH_OPTIONS, ...options }

  element?.classList?.add(classname)
  setTimeout(() => {
    element?.classList?.remove(classname)
  }, duration)
}

/**
 * Gets child elements that are included in path calculations.
 * Excludes contentEditable=false elements.
 */
export function getChildElementsForPath(element: HTMLElement): HTMLElement[] {
  // Get child elements that are suitable for path (exclude non-editable)
  const childElements: HTMLElement[] = []
  for (const childElement of element.children) {
    // Skip non-editable
    if (childElement.getAttribute("contentEditable") === "false") {
      continue
    }
    // Skip smart template controls
    if ((childElement as HTMLElement).dataset.smartTemplateControl) {
      continue
    }
    childElements.push(childElement as HTMLElement)
  }
  return childElements
}

/** Gets an element within the specified element. index is 0, 1, 2... for the
 * top-level element number. User for detection paths only
 *
 * @param index is the index of the element to get
 * @param element is the element to get the child from
 */
export function getElementByDetectionPathIndex(
  index: number,
  element: HTMLElement
): HTMLElement | null {
  let currentNode = element.firstElementChild as HTMLElement | null

  while (currentNode != null && index > 0) {
    currentNode = currentNode.nextElementSibling as HTMLElement | null

    // Skip non-editable blocks since they don't exist on server version of document
    if (
      currentNode !== null &&
      currentNode.getAttribute("contentEditable") !== "false"
    ) {
      index -= 1
    }
  }

  return currentNode
}

/** Uses a path (array indexes of element to recurse into) to get an element.
 * @param path is the path to the element
 * @param parent is the element to get the child from
 * @returns { element, path } where element is the element found, and path is
 * the path to the element (which may be different if the exact element is not found)
 */
export function getElementByPath(
  path: number[],
  parent: HTMLElement
): { element: HTMLElement; path: number[] } {
  let currentElement = parent

  for (let i = 0; i < path.length; i++) {
    // Get child elements that are suitable for path (exclude non-editable)
    const childElements = getChildElementsForPath(currentElement)

    // If has no children, return self
    if (childElements.length === 0) {
      return { element: currentElement, path: path.slice(0, i) }
    }

    const child = childElements[path[i]]
    // If index is out of range, return last child that is editable
    if (child == null) {
      return {
        element: childElements[childElements.length - 1] as HTMLElement,
        path: path.slice(0, i).concat([childElements.length - 1]),
      }
    }

    currentElement = child as HTMLElement
  }

  return { element: currentElement as HTMLElement, path }
}

/** Gets the next path element. Returns { element, path }
 * If last, will return { element: null, path: [] }
 * Path elements are elements that are do not have text siblings.
 */
export function getNextPathElement(
  element: HTMLElement,
  path: number[]
): { element: HTMLElement | null; path: number[] } {
  // Move down tree if is a parent element
  if (isParentElement(element)) {
    // Get child elements that are suitable for path (exclude non-editable)
    const childElements = getChildElementsForPath(element)

    // Go to first child element, adjusting path
    element = childElements[0] as HTMLElement
    path = path.concat([0])

    return { element, path }
  }

  // Move to next sibling, moving up tree if necessary
  while (path.length > 0) {
    let nextElement = element.nextElementSibling
    while (
      nextElement != null &&
      nextElement.getAttribute("contentEditable") === "false"
    ) {
      nextElement = nextElement.nextElementSibling
    }
    if (nextElement != null) {
      element = nextElement as HTMLElement

      path = path.slice()
      path[path.length - 1] += 1

      return { element, path }
    }

    // Move up tree, since had no sibling
    element = element.parentNode as HTMLElement
    path = path.slice()
    path.pop()
  }
  return { element: null, path }
}

/** Gets the previous path element. Returns { element, path }
 * If last, will return { element: null, path: [] }.
 * Path elements are elements that are do not have text siblings.
 */
export function getPrevPathElement(element: HTMLElement, path: number[]) {
  // Move down tree if path is empty
  if (path.length === 0) {
    while (isParentElement(element)) {
      // Get child elements that are suitable for path (exclude non-editable)
      const childElements = getChildElementsForPath(element)

      // Go to last child element, adjusting path
      path = path.concat([childElements.length - 1])
      element = element.lastElementChild as HTMLElement
    }
    return { element, path }
  }

  // Move to prev sibling, moving up tree if necessary
  let prevElement = element.previousElementSibling
  while (
    prevElement != null &&
    prevElement.getAttribute("contentEditable") === "false"
  ) {
    prevElement = prevElement.previousElementSibling
  }

  if (prevElement != null) {
    element = prevElement as HTMLElement
    path = path.slice()
    path[path.length - 1] -= 1

    // Move down tree if is a parent element
    while (isParentElement(element)) {
      // Go to last child element, adjusting path
      const childElements = getChildElementsForPath(element)

      path = path.concat([childElements.length - 1])
      element = childElements[childElements.length - 1] as HTMLElement
    }

    return { element, path }
  }

  // Move up tree, since had no sibling
  element = element.parentNode as HTMLElement
  path = path.slice()
  path.pop()

  return { element: path.length > 0 ? element : null, path }
}

/**
 * Gets scroll path of given selection within the editor
 */
export function getPathFromSelection(selection: Selection) {
  // Get first element of document
  let elem = document.getElementsByClassName("fr-element")[0]
    .firstChild as Node | null
  if (!elem) {
    return []
  }

  let path = []

  while (true) {
    let index = 0

    // Move through children
    while (true) {
      // Skip non-editable since they don't exist on server
      if (
        elem != null &&
        elem.nodeType === 1 &&
        (elem as HTMLElement).getAttribute("contentEditable") !== "false" &&
        (elem as HTMLElement).hasAttribute("data-smart-template-control") ===
          false
      ) {
        if (elem.contains(selection.anchorNode)) {
          break
        }
        // Increment index since is an element
        index += 1
      }
      if (!elem?.nextSibling) {
        break
      }
      elem = elem.nextSibling
    }

    // Update path
    path.push(index)

    // If not parent element, return
    if (!isParentElement(elem!)) {
      return path
    }

    // Recurse inside
    elem = elem!.firstChild
  }
}

/** Gets the path of a block element (either leaf or parent element) */
export function getPathFromNode(node: HTMLElement, rootNode: HTMLElement) {
  let path = []
  let currentNode: HTMLElement | null = node

  while (currentNode != null && currentNode !== rootNode) {
    path.unshift(0)

    while (currentNode.previousElementSibling != null) {
      if (currentNode.getAttribute("contentEditable") !== "false") {
        path[0] += 1
      }
      currentNode = currentNode.previousElementSibling as HTMLElement
    }

    currentNode = currentNode.parentNode as HTMLElement | null
  }

  return path
}

/**
 * Get the "location" for a given element.
 *
 * @param element
 * @param options
 * @param options.rootElement
 */
export const getElementLocation = (
  element: HTMLElement,
  options: { rootElement?: HTMLElement } = {}
) => {
  const {
    rootElement = document.getElementsByClassName(
      "fr-element"
    )[0] as HTMLElement,
  } = options

  return {
    tag: element.tagName.toLowerCase(),
    path: getPathFromNode(element, rootElement),
    text: element.textContent,
    offset: null,
  }
}

/** Convert html string to a DOM element */
export function htmlToElement(html: string) {
  var template = document.createElement("template")
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = html
  return template.content.firstChild
}

/** Add an element to the front of a parent's children */
export function prependChild(
  parentElement: HTMLElement,
  newChild: HTMLElement
) {
  if (parentElement.firstChild?.nextSibling != null) {
    parentElement.insertBefore(newChild, parentElement.firstChild.nextSibling)
  } else {
    parentElement.appendChild(newChild)
  }
}

/** Determine if the element is in the list of what LearnExperts considers
 * a block element.
 */
export function isBlockElementType(element: HTMLElement) {
  return BLOCK_ELEMENTS.includes(element.tagName.toLowerCase())
}

/** Determine if a node is a block element (either a leaf or parent element) */
export function isBlockElement(node: Node) {
  return node.parentNode && isParentElement(node.parentNode)
}

/** Determine if a node is a parent element (a parent element is an
 * element that only contains other elements, such as a table, tbody, tr, ul or ol.).
 * There can also be stray whitespace, so this does not exclude it from being a parent.
 * To be a parent element, it must also have at least one child block.
 */
export function isParentElement(node: Node) {
  // If not an element, it is not a parent
  if (node.nodeType !== 1) {
    return false
  }
  // fr-element is still a parent element, even though it is not editable
  if ((node as HTMLElement).classList.contains("fr-element")) {
    return true
  }
  // If it has no children, it is not a parent
  if (node.childNodes.length === 0) {
    return false
  }
  // If it is non-editable, it is not a parent
  if ((node as HTMLElement).getAttribute("contentEditable") === "false") {
    return false
  }
  // If it contains no path-worth child elements, it is not a parent
  if (getChildElementsForPath(node as HTMLElement).length === 0) {
    return false
  }

  let containsElem = false
  for (let i = 0; i < node.childNodes.length; i++) {
    // If contains non-trivial text, it is not a parent
    if (
      node.childNodes[i].nodeType === 3 &&
      !node.childNodes[i].nodeValue?.match(/^\s*$/)
    ) {
      return false
    }
    // It must contain at least one element to be a parent
    if (node.childNodes[i].nodeType === 1) {
      containsElem = true

      // Node must be a block element
      if (!isBlockElementType(node.childNodes[i] as HTMLElement)) {
        return false
      }
    }
  }
  return containsElem
}

/** Determine how close of a match (0 - 1) the element is to the
 * detection. 0 means no match, 1 means perfect match.
 */
function matchDetection(element: HTMLElement, detection: Detection) {
  // If type doesn't match, no match
  if (element.tagName.toLowerCase() !== detection.type.toLowerCase()) {
    return 0
  }

  // Get all tokens in the element text
  const elementTokens = element.textContent!.split(/\s+/)
  const detectionTokens = detection.text.split(/\s+/)

  // Zero length is no match
  if (detectionTokens.length === 0) {
    return 0
  }

  // Create a map of all tokens in the element
  const elementTokenMap: Record<string, boolean> = {}
  for (const token of elementTokens) {
    elementTokenMap[token] = true
  }

  // Count matches
  let matches = 0
  for (const detectionToken of detectionTokens) {
    if (elementTokenMap[detectionToken]) {
      matches++
    }
  }
  return matches / detectionTokens.length
}

/** Scroll editor to an element, putting it at top if possible
 * @param elem Element to scroll to
 */
export function scrollEditorTo(elem: HTMLElement | null) {
  if (elem != null) {
    let editorPane = document.getElementsByClassName(EDITOR_DIV_SELECTOR)[0]

    // Get viewports
    const editorRect = editorPane.getBoundingClientRect()
    const elemRect = elem.getBoundingClientRect()

    // If not at top, scroll to make so
    if (elemRect.top !== editorRect.top) {
      editorPane.scrollBy({
        top: elemRect.top - editorRect.top,
        behavior: "smooth",
      })
    }
  }
}

/** Scroll the rectangle into view, attempting to make it completely visible */
export function scrollIntoView(
  rect: ClientRect,
  scrollPane: HTMLElement,
  options: { alignToTop?: boolean } = {}
) {
  const { alignToTop = false } = options

  // Get viewports
  const scrollRect = scrollPane.getBoundingClientRect()

  // If fully visible, return
  if (rect.top >= scrollRect.top && rect.bottom <= scrollRect.bottom) {
    return
  }

  // If top is above, scroll up
  if (rect.top < scrollRect.top || alignToTop) {
    scrollPane.scrollBy({
      top: rect.top - scrollRect.top,
      behavior: "smooth",
    })
    return
  }

  // If bottom is below and can fully fit, make bottom visible
  if (rect.bottom > scrollRect.bottom && rect.height <= scrollRect.height) {
    scrollPane.scrollBy({
      top: rect.bottom - scrollRect.bottom,
      behavior: "smooth",
    })
    return
  }

  // Make top visible
  scrollPane.scrollBy({
    top: rect.top - scrollRect.top,
    behavior: "smooth",
  })
}

/** Find parent of a given element in which to scroll in **/
export function getScrollPane(element: HTMLElement) {
  /** @type {Element} */
  let scrollPane = element.parentElement
  while (true) {
    if (
      !scrollPane ||
      scrollPane === document.body ||
      window.getComputedStyle(scrollPane, null).overflowY === "auto" ||
      window.getComputedStyle(scrollPane, null).overflowY === "scroll"
    ) {
      break
    }
    scrollPane = scrollPane.parentElement
  }
  return scrollPane
}

/**
 * Find a section by its ordinal offset.
 *
 * @param {number} offset
 * @returns {?HTMLElement}
 */
export const findSectionHeadingByOffset = (offset: number) => {
  const editor = document.getElementsByClassName("fr-element")[0] as HTMLElement
  return findSectionHeadingByOffsetInElement(editor, offset)
}

/**
 * Find a section by its ordinal offset in an elemment
 *
 * @param {Element} element
 * @param {number} offset
 * @returns {?Element}
 */
export function findSectionHeadingByOffsetInElement(
  element: HTMLElement,
  offset: number
) {
  if (!element) {
    return null
  }
  return getHeadings(element)[offset] ?? null
}
/**
 * Determine if an element is currently visible in the LearnAdvisor viewport
 * @param element - Element to assess visilibity of
 * @param container - Enclosing container of element
 */
export function isVisibleInContainer(
  element: HTMLElement,
  container: HTMLElement
) {
  if (!container || !element) {
    return false
  }
  const containerRect = container.getBoundingClientRect()
  const elementRect = element.getBoundingClientRect()
  /*
    We only need to check the top and bottom intersections for element visibility,
     since the container is at a fixed width
  */
  return (
    elementRect.top > containerRect.top &&
    elementRect.bottom < containerRect.bottom
  )
}

/** Determine if an element is currently visible in the viewport */
export function isElementVisible(el: HTMLElement) {
  let rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = (x: number, y: number) => {
      let target = document.elementFromPoint(x, y)

      if (target?.classList?.contains("MuiBackdrop-root")) {
        /**
         * This is an edge case where during the scrolling calculation period, a popup menu was clicked.
         * Basically this causes the element extraction via coordinates to incorrectly return a top-level
         * backdrop root element, which is not what we are searching for.
         *
         * If the selected element is a backdrop root, then we start by inspecting the entire list of elements
         * underneath the supplied coordinates.
         */
        let targetList = document.elementsFromPoint(x, y)

        /**
         * Next we locate the position of the first non-overlay element by a criteria of both ID and the
         * ariaHidden property. When we know this location, we can skip the first actual element we wish
         * to compare based on the coordinates.
         */
        const index = targetList.findIndex(
          (e) => e.ariaHidden && e.id === "outline-menu"
        )

        // return the first element following the overlayed MUI menu items
        return targetList[index + 1]
      }

      return target
    }

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  )
    return false

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  )
}

/**
 * Result from dom visit functions
 * @typedef {{headerNode: Element, currentNode: Element, currentHeaderId:number, elementId: number, found:boolean }} DomVisitNodeInfo
 */

/**
 * Find the first visble node in the scrolling view
 *
 * @param {Element} editor
 * @returns {DomVisitNodeInfo}
 */
export function findSelectedElementByScrollPosition(editor: HTMLElement) {
  return visitDomElements(editor, ({ currentNode }) => {
    return isElementVisible(currentNode)
  })
}

/**
 * Find the header that 'owns' the node at nodeIndex
 *
 * @param {Element} editor
 * @param {Element} nodeIndex
 * @returns {DomVisitNodeInfo}
 */
export function findSelectedHeaderById(editor: HTMLElement, nodeIndex: number) {
  return visitDomElements(editor, ({ elementId }) => {
    return elementId === nodeIndex
  })
}

/**
 * Gets an element within the specified element. index is 0, 1, 2... for the
 * top-level element number.
 *
 * @param {Element} element
 * @param {number} index
 * @returns {Element}
 */
export function getElementByIndex(index: number, element: HTMLElement) {
  const { currentNode, found } = visitDomElements(element, ({ elementId }) => {
    return elementId === index
  })

  if (found) {
    return currentNode
  } else {
    return null
  }
}

/**
 * Find the editor element
 *
 * @param {Element} editor - Initial section of the document the user is currently in
 * @param {Element} selectionNode
 * @returns {DomVisitNodeInfo}
 *
 */
export function findElementBySelection(
  editor: HTMLElement,
  selectionNode: Node
) {
  return visitDomElements(editor, ({ currentNode }) => {
    return currentNode?.contains(selectionNode)
  })
}

/**
 * Callback for visitDomElements
 *
 * @callback visitDomCallback
 * @param { } currentNode
 * @returns {boolean}
 */
type VisitDomCallback = (arg: {
  headerNode: HTMLElement | null
  currentNode: HTMLElement
  currentHeaderId: number
  elementId: number
}) => boolean

/**
 * Visit editor elements in top to bottom order, calling visitFunc for each one.
 * visitFunc - return false to stop the visiting
 *
 * @param {Element} editor - Initial section of the document the user is currently in
 * @param {visitDomCallback} visitFunc
 * @returns {DomVisitNodeInfo}
 *
 */
function visitDomElements(editor: HTMLElement, visitFunc: VisitDomCallback) {
  let currentNode = editor.firstElementChild as HTMLElement | null
  let sectionDepth = 0
  let elementId = 0
  let currentHeaderId = -1
  let headerNode = editor.firstElementChild as HTMLElement | null

  while (currentNode) {
    if (
      ["h1", "h2", "h3", "h4", "h5", "h6"].includes(
        currentNode?.tagName.toLowerCase()
      )
    ) {
      headerNode = currentNode
      currentHeaderId += 1
    }

    if (visitFunc({ headerNode, currentNode, currentHeaderId, elementId })) {
      return {
        headerNode,
        currentNode,
        currentHeaderId,
        elementId,
        found: true,
      }
    }

    // if we are traversing within a section and reach the end, exit to the parent node and move to the next sibling
    while (sectionDepth > 0 && currentNode!.nextElementSibling == null) {
      currentNode = currentNode!.parentNode as HTMLElement | null
      sectionDepth -= 1
    }
    currentNode = currentNode!.nextElementSibling as HTMLElement | null

    // don't count smart templates for id's
    if (!isSmartTemplateControl(currentNode)) {
      elementId += 1
    }
  }

  return {
    headerNode: null,
    currentNode: null,
    currentHeaderId: -1,
    elementId: 0,
    found: false,
  }
}

/**
 * Retrieve all heading elements within a given container. A heading
 * element must be at the root level. If it is within a table or other
 * block, it does not show up in TOC, nor does it divide up the document.
 *
 * @param {HTMLElement} container The container element.
 * @returns {Element[]} A list of heading elements.
 */
export function getHeadings(container: HTMLElement) {
  const headings = []
  for (const child of container.children) {
    if (["H1", "H2", "H3", "H4", "H5", "H6"].includes(child.tagName)) {
      headings.push(child)
    }
  }

  return headings
}

/**
 * Generate a random UUID
 * @returns {string} UUID v4
 */
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const random = crypto.getRandomValues(new Uint8Array(1))[0] & 15
    const value = c === "x" ? random : (random & 0x3) | 0x8
    return value.toString(16)
  })
}

/** Place selection immediately after first text in element and focus
 * the editor.
 * @param element The element to place the selection after
 * @param editor The Froala editor
 */
export function selectAfterText(element: HTMLElement, editor: any) {
  const range = document.createRange()
  range.setStartAfter(element.firstChild!)
  range.collapse(true)
  const selection = window.getSelection()
  selection?.removeAllRanges()
  selection?.addRange(range)

  // Froala requires a timeout to focus the editor after inserting content
  setTimeout(() => {
    editor.events.focus(true)
  }, 10)
}

/**
 * Check if provided HTML string is wrapped in a single div.
 * @param html - String of document HTML
 */
function hasSingleParentDiv(html: string) {
  // Create a new DOM parser
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")

  // Get the body's children
  const bodyChildren = doc.body.children

  // Check if there's exactly one child and it's a div
  return (
    bodyChildren.length === 1 && bodyChildren[0].tagName.toLowerCase() === "div"
  )
}

/**
 * With the Froala update, the model is wrapped in a parent div which can interfere with other places
 * we use the HTML state (saves and detections). This checks for the parent wrapper in the HTML string
 * and removes it if it exists.
 * @param html - String of document HTML
 */
export function removeDivWrapper(html: string | null) {
  if (html && hasSingleParentDiv(html)) {
    return html.slice(5, -6) // removes opening and close <div> tags
  }
  return html ?? ""
}

/**
 * Find nearest HTMLElement in editor from a given node. Numerous Froala operations require an HTMLElement
 * @param node - Node in editor HTML
 * @param editor - Current editor instance
 */
export function findHTMLElementFromNode(node: any, editor: any) {
  let element = node
  while (
    element &&
    element instanceof HTMLElement === false &&
    element.parentNode !== editor
  ) {
    element = element.parentNode
  }
  return element
}

/**
 * Function to convert a base64-encoded image string to a File object
 * @param base64 - base64 encoded image
 */
export function base64ToFile(base64: string | null): File | null {
  if (!base64) return null

  // Extract only the Base64 data
  const [, mimeType, base64Data] =
    base64?.match(/^data:(image\/[a-zA-Z0-9+.-]+);base64,(.*)$/) || []
  if (!base64Data) return null
  // Decode Base64
  const byteCharacters = atob(base64Data)
  const byteArray = new Uint8Array(byteCharacters.length)

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i)
  }

  // Create Blob from binary data
  const blob = new Blob([byteArray], { type: mimeType })

  // Extract file extension safely
  const extension = mimeType?.split("/")[1]?.split("+")[0] || "png"
  const fileName = `image.${extension}`

  // Create File object
  return new File([blob], fileName, { type: mimeType || "image/png" })
}

/**
 * Uploads Base64 images and replaces their temporary keys in the editor with the uploaded image URLs.
 *
 * @param editor - The FroalaEditor instance.
 * @param base64ImageData - An array of Base64ImageData objects containing replaced keys and Base64 image sources.
 * @returns A promise that resolves when all uploads and replacements are complete.
 */
export async function uploadBase64Images(
  editor: FroalaEditor,
  base64ImageData: Base64ImageData[]
) {
  // Create a map of replacedKey to img elements for quick lookup using data-pending-image-id
  const imgMap = new Map<string, HTMLImageElement>()
  const imgTags = editor.el.querySelectorAll("img[data-pending-image-id]")

  imgTags.forEach((img: any) => {
    const pendingId = img.getAttribute("data-pending-image-id")
    if (pendingId) {
      imgMap.set(pendingId, img)
    }
  })

  // Process all Base64ImageData objects in parallel
  await Promise.all(
    base64ImageData.map(async (data) => {
      const img = imgMap.get(data.replacedKey)
      if (img) {
        try {
          const file: any = base64ToFile(data.base64Src)
          if (file) {
            const imageUrl = await uploadFroalaImage(file)
            if (imageUrl) {
              img.src = imageUrl
              img.removeAttribute("data-pending-image-id")
            } else {
              // Remove the image from editor content
              img.remove()
            }
          } else {
            img.remove()
          }
        } catch (error) {
          console.error("Error uploading base64 image:", error)
          // Remove the image from editor content
          img.remove()
        }
      }
    })
  )
}

/**
 * Retrieves all `<img>` elements within a given HTML element, including the element itself
 * if it is an `<img>` tag.
 *
 * @param element - The root HTML element to search for `<img>` elements.
 * @returns An array of all `<img>` elements found within the given element, including
 * the element itself if it is an `<img>` tag.
 */
function getAllImages(element: HTMLElement): HTMLImageElement[] {
  // Get all descendant images
  const childImages = Array.from(
    element.querySelectorAll("img")
  ) as HTMLImageElement[]

  // If the element itself is an image, combine it with child images
  if (element.tagName === "IMG") {
    // Convert NodeList to Array and add the element itself
    return [element as HTMLImageElement, ...childImages]
  }

  return childImages
}

/**
 * Extracts and temporarily replaces the `src` attribute of `<img>` elements containing Base64-encoded images
 * within the provided HTML elements. Returns an array of objects containing the original Base64 data and
 * the corresponding `<img>` elements.
 *
 * @param $elements - A single HTMLElement or an array of HTMLElements to process.
 *
 * @returns An array of `Base64ImageData` objects, each containing the original Base64 data and the associated
 * `<img>` element.
 */
export function stripBase64Images(
  $elements: HTMLElement | HTMLElement[]
): Base64ImageData[] {
  // Ensure elements are in an array
  const elementsArray = Array.isArray($elements) ? $elements : [$elements]

  // Initialize an array to collect results
  const result: Base64ImageData[] = []

  elementsArray.forEach(($element) => {
    const allImages = getAllImages($element)
    allImages.forEach((img) => {
      const data = tempReplaceBase64src(img)
      if (data) {
        result.push(data)
      }
    })
  })

  return result // Return the array of Base64ImageData
}

/**
 * Temporarily replaces the `src` attribute of an HTMLImageElement if it contains a Base64-encoded image.
 * The `src` is replaced with a unique placeholder key, and the original Base64 string is returned.
 *
 * @param img - The HTMLImageElement whose `src` attribute is to be checked and potentially replaced.
 * @returns An object containing the unique placeholder key and the original Base64 `src` string if the replacement occurs, or `null` if no replacement is made.
 */
function tempReplaceBase64src(img: HTMLImageElement): Base64ImageData | null {
  const src = img.getAttribute("src")

  if (src && src.startsWith("data:image/")) {
    const uniqueId = uuid() // Assuming uuid() generates a valid unique string
    img.setAttribute("src", "") // Clear the src to remove the base64
    img.setAttribute("data-pending-image-id", uniqueId)

    return {
      replacedKey: uniqueId,
      base64Src: src,
    }
  }
  return null
}
