import React, { useTheme } from "@emotion/react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material"
import CopyToClipboardField from "../atoms/CopyToClipboardField"
import PropTypes from "prop-types"

/**
 * A modal presenting the learner URL for a course
 *
 */

const LearnerLinkDialog = (props) => {
  const fullScreen = useFullscreenDialog()
  const { courseTitle, url, open, onClose } = props

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      scroll={fullScreen ? "paper" : "body"}
      keepMounted
      open={open}
      onClose={onClose}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>Public Learner Link</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>{courseTitle}</DialogContentText>
        <DialogContentText sx={{ mb: 1 }}>
          Send this link to learners. They can use it to launch your course.
        </DialogContentText>
        <CopyToClipboardField
          value={url}
          fullWidth
          helperText="Anyone on the internet with this link will be able to launch the
          course."
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LearnerLinkDialog.propTypes = {
  courseTitle: PropTypes.string,
  url: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

LearnerLinkDialog.defaultProps = {
  open: false,
}

export default LearnerLinkDialog

/**
 * adding fullScreen for Dialog
 */
const useFullscreenDialog = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}
