import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { SmartTemplatePanelContainer } from "./smartTemplateControlCustomElement"
import _ from "lodash"
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  IconButton,
} from "@mui/material"
import { findSimilarContent, mergeSimilarContent } from "../../api"
import {
  getContentById,
  replaceSimilarContent,
  simpleMergeSimilarContent,
} from "./similarContentUtils"
import { PreviewHtmlContent } from "./PreviewHtmlContent"
import {
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  AutoFixHigh as AutoFixHighIcon,
  MoveDown as MoveDownIcon,
  Check as CheckIcon,
  Compare as CompareIcon,
} from "@mui/icons-material"
import { stripTemporaryElements } from "../../utilities/smartTemplates"
import { removeDivWrapper } from "../../utilities/domUtils"

/** A section that is similar to another section */
interface SimilarSection {
  /** The section ID */
  id: string
  /** The section title */
  title: string
  /** Similarity percentage (0-100) */
  similarity: number
}

/**
 * Panel for smart template control for finding similar content.
 *
 * The panel is in one of two states, either selecting which sections to
 * merge or previewing the final result.
 *
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.onClose Callback to close the panel.
 * @param props.withStyles Function to wrap children in styles.
 */
export function SimilarSmartTemplatePanel(props: {
  editor: any
  element: HTMLElement
  onClose: () => void
  withStyles: (children: React.ReactElement) => React.ReactElement
}) {
  const { editor, element, onClose, withStyles } = props

  const sourceSectionId = element.dataset.smartTemplateId!

  // State of the panel (selecting or previewing)
  const [state, setState] = useState<"selecting" | "previewing">("selecting")

  // Similar sections results
  const [similarSections, setSimilarSections] = useState<SimilarSection[]>()

  // Selected section ids
  const [selectedSections, setSelectedSections] = useState<string[]>([])

  // Merged content as html
  const [mergedContent, setMergedContent] = useState<string>()

  // Server message
  const [message, setMessage] = useState("")

  // Modal open to compare two sections side by side
  const [compareModalHtmls, setCompareModalHtmls] = useState<{
    left: string
    right: string
  }>()

  // Load similar sections from api on mount
  useEffect(() => {
    const html = removeDivWrapper(editor.html.get())

    findSimilarContent(html, sourceSectionId)
      .then((results) => {
        setSimilarSections(results.similar_content)
        setMessage("")
      })
      .catch((error) => {
        setMessage(`Error: ${error}`)
      })
  }, [editor, element, sourceSectionId])

  /** Merge similar content into the current section using simple merge algorithm. See simpleMergeSimilarContent for details. */
  function appendContent() {
    // Root element of the editor is parent of the element
    setMergedContent(
      simpleMergeSimilarContent(
        element.parentElement!,
        sourceSectionId,
        selectedSections
      )
    )
    setState("previewing")
    setMessage("")
  }

  /** Merge similar content into the current section using AI merge */
  function mergeContent() {
    setMergedContent("")
    setState("previewing")
    setMessage("")

    // Get html without temporary content
    const html = stripTemporaryElements(editor.html.get())

    // Call api
    mergeSimilarContent(html, sourceSectionId, selectedSections)
      .then((result) => {
        if (result.error_message) {
          setState("selecting")
          setMessage(result.error_message)
          return
        }
        setMergedContent(result.merged_content)
      })
      .catch((error) => {
        setState("selecting")
        setMessage(`Error: ${error}`)
      })
  }

  /** Apply the merge to the editor, replacing the current section */
  function applyMerge() {
    replaceSimilarContent(
      element.parentElement!,
      sourceSectionId,
      selectedSections,
      mergedContent!
    )

    editor.undo.saveStep()
    onClose()
  }

  /** Handle comparing two sections.
   * @param targetSectionId The section ID to compare to
   */
  function handleCompare(targetSectionId: string) {
    setCompareModalHtmls({
      left: getContentById(element.parentElement!, sourceSectionId),
      right: getContentById(element.parentElement!, targetSectionId),
    })
  }

  return (
    <>
      {compareModalHtmls && (
        <SideBySideModal
          handleClose={() => setCompareModalHtmls(undefined)}
          leftHTML={compareModalHtmls.left}
          rightHTML={compareModalHtmls.right}
          editor={editor}
        />
      )}
      {withStyles(
        <SmartTemplatePanelContainer>
          <Stack gap={1}>
            <Box key="content" sx={{ p: 1 }}>
              <Box
                sx={{
                  overflowY: "auto",
                  height: "50vh",
                  // eslint-disable-next-line jsdoc/require-jsdoc
                  backgroundColor: (theme) => theme.palette.background.paper,
                  p: 1,
                }}
              >
                {state === "selecting" &&
                  (!similarSections ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <SimilarSectionList
                      sections={similarSections}
                      selected={selectedSections}
                      onSelectChange={setSelectedSections}
                      onCompare={(sectionId) => {
                        handleCompare(sectionId)
                      }}
                    />
                  ))}

                {state === "previewing" && !mergedContent && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Box>
                      <LinearProgress />
                      <Typography variant="caption" align="center">
                        Merging content. This may take a few minutes.
                      </Typography>
                    </Box>
                  </Box>
                )}

                {state === "previewing" && mergedContent && (
                  <PreviewHtmlContent editor={editor} html={mergedContent} />
                )}
              </Box>
            </Box>
            {message !== "" && <Alert severity="warning">{message}</Alert>}
            <Stack
              key="footer"
              sx={{
                /**
                 * Set background to be shade of MUI grey
                 * @param theme - MUI theme
                 */
                backgroundColor: (theme) => theme.palette.grey[200],
                p: 2,
                gap: 1,
              }}
              direction="row"
              alignItems="center"
              justifyContent={
                state === "selecting" ? "flex-end" : "space-between"
              }
            >
              {state === "selecting" && !similarSections && (
                <Button
                  variant="text"
                  onClick={() => {
                    onClose()
                  }}
                  startIcon={<CloseIcon fontSize="inherit" />}
                >
                  Cancel
                </Button>
              )}

              {state === "selecting" && similarSections && (
                <Stack spacing={2} direction="row">
                  <Button
                    key="append"
                    variant="text"
                    disabled={selectedSections.length === 0}
                    onClick={() => {
                      appendContent()
                    }}
                    startIcon={<MoveDownIcon fontSize="inherit" />}
                  >
                    Move
                  </Button>
                  <Button
                    key="merge"
                    variant="text"
                    disabled={selectedSections.length === 0}
                    onClick={() => {
                      mergeContent()
                    }}
                    startIcon={<AutoFixHighIcon fontSize="inherit" />}
                  >
                    AI Merge
                  </Button>
                  <Button
                    key="cancel"
                    variant="text"
                    onClick={() => {
                      onClose()
                    }}
                    startIcon={<CloseIcon fontSize="inherit" />}
                  >
                    Cancel
                  </Button>
                </Stack>
              )}

              {state === "previewing" && (
                <>
                  <Button
                    key="back"
                    variant="text"
                    disabled={selectedSections.length === 0}
                    onClick={() => {
                      setState("selecting")
                    }}
                    startIcon={<ArrowBackIcon fontSize="inherit" />}
                  >
                    Back
                  </Button>
                  <Stack direction="row" spacing={2}>
                    <Button
                      key="apply"
                      variant="text"
                      disabled={!mergedContent}
                      onClick={() => {
                        applyMerge()
                      }}
                      startIcon={<CheckIcon fontSize="inherit" />}
                    >
                      Confirm
                    </Button>
                    <Button
                      key="discard"
                      variant="text"
                      onClick={() => {
                        onClose()
                      }}
                      startIcon={<DeleteIcon fontSize="inherit" />}
                    >
                      Discard
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </SmartTemplatePanelContainer>
      )}
    </>
  )
}

/**
 * List of similar sections props
 */
interface SimilarSectionListProps {
  /** An array of SimilarSection objects. */
  sections: SimilarSection[]
  /** An array of selected section IDs. */
  selected: string[]
  /** Function to handle changes in the selected sections. */
  onSelectChange: (selected: string[]) => void
  /** Called when a section is to be compared. */
  onCompare: (sectionId: string) => void
}

/**
 * List of similar sections where each one can be selected.
 * @param props See above.
 */
export function SimilarSectionList(props: SimilarSectionListProps) {
  const { sections, selected, onSelectChange, onCompare } = props

  /** Handle toggling a section.
   * @param value The section ID.
   */
  const handleToggle = (value: string) => {
    let newChecked: string[] = []

    if (selected.includes(value)) {
      newChecked = selected.filter((id) => id !== value)
    } else {
      newChecked = [...selected, value]
    }

    onSelectChange(newChecked)
  }

  return (
    <List>
      {sections.map((section) => {
        const labelId = `checkbox-list-label-${section.id}`

        const similarityDisplay = (
          <Box sx={{ width: 80 }}>
            <LinearProgress variant="determinate" value={section.similarity} />
          </Box>
        )

        return (
          <ListItem
            key={section.id}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="show"
                className="compare-icon"
                sx={{ visibility: "hidden" }}
                onClick={() => {
                  onCompare(section.id)
                }}
              >
                <CompareIcon />
              </IconButton>
            }
            disablePadding
            // Show compare icon on hover
            sx={{
              "&:hover .compare-icon": {
                visibility: "visible",
              },
            }}
          >
            <ListItemButton
              role={undefined}
              dense
              onClick={() => {
                handleToggle(section.id)
              }}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected.indexOf(section.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${section.title}`}
                secondary={similarityDisplay}
              />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

/** Props for side by side modal */
interface SideBySideModalProps {
  /** Editor (required for preview component) */
  editor: any
  /** Function to close the modal */
  handleClose: () => void
  /** HTML for left side */
  leftHTML: string
  /** HTML for right side */
  rightHTML: string
}

/**
 * Compare two sections side by side.
 * @param props See above.
 */
function SideBySideModal(props: SideBySideModalProps) {
  const { editor, handleClose, leftHTML, rightHTML } = props

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="side-by-side-dialog-title"
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle id="side-by-side-dialog-title">
        Section Comparison
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Paper elevation={3} sx={{ margin: 1, padding: 2 }}>
            <PreviewHtmlContent editor={editor} html={leftHTML} />
          </Paper>
          <Paper elevation={3} sx={{ margin: 1, padding: 2 }}>
            <PreviewHtmlContent editor={editor} html={rightHTML} />
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
