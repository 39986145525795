import {
  findHTMLElementFromNode,
  selectAfterText,
} from "../../utilities/domUtils"
import createAudioHTML from "./createAudioHTML"
import { AudioData, createInsertAudioDialog } from "./InsertAudioDialog"

const initialData: AudioData = {
  title: "",
  url: "",
  file: null,
  fileName: null,
  id: null,
}

/**
 * Callback to insert audio into HTML when dialog is closed
 * @param anchorNode - Location in editor
 * @param onClose - Callback to close dialog
 * @param editor - Froala editor instance
 */
const onCreateAudio = (anchorNode: any, onClose: () => void, editor: any) =>
  createInsertAudioDialog((data) => {
    if (data) {
      console.log(data)
      const anchorElement = findHTMLElementFromNode(anchorNode, editor)
      console.log(anchorElement)
      const html = createAudioHTML(data)
      selectAfterText(anchorElement, editor)

      // Add a paragraph after the audio so the user can type after it
      anchorElement.insertAdjacentHTML("afterend", html)
      editor.undo.saveStep()
      selectAfterText(anchorElement, editor)
    }

    onClose()
  }, initialData)

export default onCreateAudio
