import PropTypes from "prop-types"
import { Typography } from "@mui/material"

import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import ErrorMessage from "./ErrorMessage"
import { dialogConfig } from "../../config/dialogConfig"

// eslint-disable-next-line jsdoc/require-jsdoc
const ErrorDialog = (props) => {
  const { message, onClose } = props
  return (
    <Dialog {...dialogConfig} open={message != null}>
      <DialogContent>
        <ErrorMessage title="Oh no!" subtitle={message}>
          <Typography variant="body2">
            Please try again later, contact LearnExperts if the problem
            persists.
          </Typography>
        </ErrorMessage>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ErrorDialog.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string,
}
export default ErrorDialog
