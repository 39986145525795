/**
 * Count the number of words contained in a text or element.
 *
 * @type {(value: string | Node) => number}
 */
const countWords = (value) => {
  if (value instanceof Text) {
    return countWords(value.data)
  }

  if (value instanceof Element) {
    /**
     * Ignore <iframe> elements to prevent counting any content inside them.
     * This avoids miscounting hidden Froala markers (e.g., <span class="fr-mk">)
     * that may be injected within iframes for cursor positioning or editor functionality.
     */
    if (value.tagName === "IFRAME") {
      return 0
    } else {
      return [...value.childNodes].reduce((acc, node) => {
        return acc + countWords(node)
      }, 0)
    }
  }

  // Other node types (e.g. Comment, CData, etc.) are not counted.
  if (value instanceof Node) {
    return 0
  }

  return value.trim().split(/\s+/).filter(Boolean).length
}

export default countWords
