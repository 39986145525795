import { useEffect, useRef, useState } from "react"
import { throttle } from "lodash"
import { useSnackbar } from "notistack"
import {
  getPathFromSelection,
  findSelectedElementByScrollPosition,
  findElementBySelection,
  findSectionHeadingByOffset,
  uuidv4,
} from "../utilities/domUtils"
import useRemarksDispatch from "./useRemarksDispatch"
import { useStableCallback } from "./useStableCallback"
import {
  cleanupSmartTemplateControls,
  addSmartTemplate,
  findOwningSectionHeaderElement,
} from "../utilities/smartTemplates"
import observeMutations from "../features/outline/utils/observeMutations"
import { CustomElements } from "../custom-elements/CustomElements"
import { questionCustomElement } from "../custom-elements/questionCustomElement"
import { flipCardGridCustomElement } from "../custom-elements/flipCardGridCustomElement"
import { labeledImageCustomElement } from "../custom-elements/labeledImageCustomElement"
import { setupQuickInsert } from "../features/quick-insert/setupQuickInsert"
import { addDragDropToFroala } from "../utilities/addDragDropToFroala"
import useDocumentStats from "../features/outline/hooks/useDocumentStats"
import { useFlag } from "../utilities/feature-management"
import { smartTemplateControlCustomElement } from "../custom-elements/smart_templates/smartTemplateControlCustomElement"
import { tabsCustomElement } from "../custom-elements/tabsCustomElement"
import { categoriesCustomElement } from "../custom-elements/categoriesCustomElement"
import { processCustomElement } from "../custom-elements/processCustomElement"
import BottomControl from "../features/importSourcesToEditor/bottomControlCustomElement"
import { createPortal } from "react-dom"
import { ParagraphInserter } from "../features/ParagraphInserter"
import { styledListCustomElement } from "../custom-elements/StyledList/styledListCustomElement"
import { AutoAltTextPopup } from "../components/widgets/Editor/AutoAltTextPopup"
import { calloutBoxCustomElement } from "../custom-elements/calloutBoxCustomElement"
import { useSelector } from "react-redux"
import useEditorDispatch from "../store/editor/useEditorDispatch"
import { selectCurrentSectionNumber } from "../store/editor/selectors"
import initializeEditorBranding from "../features/branding/utils/initializeEditorBranding"
import { defaultBranding } from "../features/branding/hooks/useBranding"
import { removeDivWrapper } from "../utilities/domUtils"
import { useMixpanelFroalaToolbarTracker } from "../utilities/mixpanel"
import { usePreventNavigation } from "./usePreventNavigation"
import { useCourse } from "./useCourse"
import { stripTemporaryElements } from "../utilities/smartTemplates"
import { useLocation } from "react-router"

const EDITOR_DIV_SELECTOR = "fr-wrapper"

/**
 * Hook to manage the state of the editor
 * @param courseId - ID of the course
 * @param readOnly - Is the editor in read-only mode?
 */
const useEditorState = (courseId, readOnly) => {
  const editorRef = useRef()
  const trackToolbarClick = useMixpanelFroalaToolbarTracker()

  /** Incrementing flag if scroll updates are disabled (i.e. ignore scroll events)
   * Temporary disabled when externally scrolling and re-enabled when the user stops scrolling
   * as determined by debounce on scroll events. Since there may be multiple external
   * updates, this is an integer that gets incremented when the external event is received
   * and decremented when the debounce fires. If this is zero, scroll events are enabled.
   */
  const scrollUpdateDisabledFlag = useRef(0)

  /** Whether the html is being updated externally. Since this triggers a model change,
   * we don't want to trigger a save when this happens, as the internal version of the course
   * that Froala uses is slightly different with the temporary elements etc.
   */
  const externallyUpdatingHtmlRef = useRef(false)

  /** Update the editor with the course HTML if it has been updated externally */
  const onExternalHtmlUpdate = useStableCallback((course) => {
    externallyUpdatingHtmlRef.current = true

    try {
      editorRef.current.editor.html.set(course.assembled_html)
      editorRef.current.editor.undo.saveStep()
    } finally {
      externallyUpdatingHtmlRef.current = false
    }
  })

  /** Trigger a refresh of the comments when the async save completes */
  const onSaveAsyncComplete = useStableCallback((courseId, version) => {
    remarks.refreshComments()
  })

  const {
    /** The course object or null if loading */
    course,
    /** The state of the save */
    saveState,
    /** The error message from the last save attempt */
    saveError,
    /** The error message from the initial load attempt */
    loadError,
    /**
     * Update the course
     * @param html - The HTML to update the course with
     * @param duration - The duration of the course in minutes
     */
    updateCourse,
    /** Perform an immediate save of the course (does nothing if not unsaved) */
    saveCourse,
  } = useCourse({ courseId, onExternalHtmlUpdate, onSaveAsyncComplete })

  // Froala html which has temporary elements, etc and is used directly by the React component
  const [html, setHtml] = useState(null)

  const [initialized, setInitialized] = useState(false)
  const [error, setError] = useState()
  const [editor, setEditor] = useState()
  const [branding, setBranding] = useState(null)

  const currentSectionNumber = useSelector(selectCurrentSectionNumber)
  const remarks = useRemarksDispatch()

  const { setScrollPath, setCurrentSectionHeader, setCurrentSectionNumber } =
    useEditorDispatch()

  const location = useLocation()

  const editorContainer = editor?.$el?.[0]
  const { duration } = useDocumentStats(editorContainer)

  const enableTabsComponent = useFlag("rollout-tabbed-content-interaction")
  const enableCategoriesComponent = useFlag("rollout-categories-interaction")
  const enableProcessComponent = useFlag("rollout-process-interaction")
  const enableImportSources = useFlag("rollout-editor-import-sources")
  const enableTwoColumnsComponent = useFlag("rollout-two-column-content")
  const enableParagraphInserter = useFlag("rollout-insert-between-sections")
  const enableStyledListsComponent = useFlag("rollout-styled-lists")
  const enableFavourites = useFlag("rollout-quick-insert-menu-favourites")
  const enableCalloutBoxes = useFlag("rollout-callout-boxes")
  const enableAudioPlayer = useFlag("rollout-audio-clips-in-editor")
  const enableNewImageInsertDialog = useFlag("rollout-new-insert-image-dialog")
  const enableBrandingInEditor = useFlag("rollout-branding-styles-in-editor")

  // Set popup error message if there is an error loading the course
  useEffect(() => {
    if (loadError) {
      setError({
        message: loadError,
      })
    }
  }, [loadError])

  // Get the enqueueSnackbar and closeSnackbar functions from the useSnackbar hook
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  // Display the save error snackbar notification
  useEffect(() => {
    const saveErrorSnackbarConfig = {
      key: "leai/save-error",
      // Save will continue trying, so it's not an error
      variant: "warning",
      preventDuplicate: true,
      persist: true,
    }

    if (saveError) {
      enqueueSnackbar(
        "We are unable to save your course. LEAi will continue trying.",
        saveErrorSnackbarConfig
      )
    }
    return () => {
      closeSnackbar(saveErrorSnackbarConfig.key)
    }
  }, [enqueueSnackbar, closeSnackbar, saveError])

  // True if the course has been loaded
  const courseLoaded = course != null

  useEffect(() => {
    if (!courseLoaded) {
      // Set the course as pending remarks loading
      remarks.pending(courseId)
      return
    }

    // Set the html for the Froala editor once course is loaded
    setHtml(course.assembled_html)

    // Set branding once the course has been loaded
    if (!readOnly && enableBrandingInEditor && course.branding) {
      initializeEditorBranding(course.branding)
        .then(setBranding)
        .catch((error) => {
          setError({
            message:
              "We were unable to load your branding settings, so the editor will use the default branding configuration. You can still edit and save your course.",
          })
          setBranding(defaultBranding)
        })
    } else {
      setBranding(defaultBranding)
    }

    // Set comments once the course has been loaded
    remarks.fromCourseResponse({
      data: course,
      status: 200,
    })
  }, [courseLoaded])

  useEffect(() => {
    const isReadyForDetections = html !== null
    if (!isReadyForDetections) {
      return
    }

    remarks.fetchDetections(removeDivWrapper(html), readOnly)
  }, [html, remarks, readOnly])

  /**
   * Updates various parts of state to reflect the newly selected section and its accompanying parent header node
   *
   * @param headerNode - The parent header (H1, H2 etc...) node of whatever section has been selected
   * @param currentNode - Node that has been selected to be set as the current from some previous logic
   * @param titleNode - Node representing the top-level, H1 node of the entire document
   * @param elementId - Numeric ID representing the ordinal position of the currentNode
   */
  const updateCurrentSection = useStableCallback(
    (headerNode, currentNode, titleNode, elementId) => {
      try {
        if (!headerNode || typeof headerNode.tagName !== "string") {
          console.warn("Invalid headerNode provided to updateCurrentSection")
          return
        }

        currentNode = headerNode.nextSibling
        let largerHeaderTags = getLargerHeaderTags(headerNode.tagName)
        while (currentNode) {
          // Skip text nodes
          if (currentNode.nodeType === 3) {
            currentNode = currentNode.nextSibling
            continue
          }
          if (
            currentNode.tagName &&
            largerHeaderTags.includes(currentNode.tagName.toLowerCase())
          ) {
            break
          }

          currentNode = currentNode.nextSibling
        }

        if (
          headerNode.tagName &&
          largerHeaderTags.includes(headerNode.tagName.toLowerCase())
        ) {
          setCurrentSectionHeader(headerNode.innerHTML || "")
        } else {
          setCurrentSectionHeader(null)
        }
      } catch (error) {
        console.error("Error in updateCurrentSection:", error)
      }
    }
  )

  /**
   * Determine new scroll path to be synced with the LearnAdvisor pane. Old behavior
   * calculates this via scroll position of viewport, while the new behavior determines
   * this by the current selection within the document.
   */
  const calculateScrollPath = useStableCallback((selection) => {
    const newPath = getPathFromSelection(selection)
    setScrollPath(newPath)
  })

  // Update selected element from current selection.
  useEffect(() => {
    const updateSelectedElementBySelection = throttle(
      (e) => {
        let editor = document.getElementsByClassName("fr-element")[0]

        let selection = window.getSelection()
        const isSelectionInEditor =
          editor &&
          selection &&
          editor.contains(selection.anchorNode) &&
          editor.contains(selection.focusNode)

        if (!isSelectionInEditor) {
          return
        }

        const { headerNode, currentNode, currentHeaderId, elementId, found } =
          findElementBySelection(editor, selection.anchorNode)

        if (found) {
          const titleNode = editor.firstElementChild
          updateCurrentSection(headerNode, currentNode, titleNode, elementId)
          setCurrentSectionNumber(currentHeaderId)

          calculateScrollPath(selection)
        }
      },
      1000 /* 1s */,

      { trailing: false }
    )

    document.addEventListener(
      "selectionchange",
      updateSelectedElementBySelection
    )
    return () => {
      document.removeEventListener(
        "selectionchange",
        updateSelectedElementBySelection
      )
    }
  }, [calculateScrollPath, updateCurrentSection])

  const updateSize = useStableCallback(() => {})

  // Listen for resize events
  useEffect(() => {
    window.addEventListener("resize", updateSize)
    return () => {
      window.removeEventListener("resize", updateSize)
    }
  }, [updateSize])

  /** Update the current section using the scroll position */
  const updateSelectedElementByScrollPosition = useStableCallback(() => {
    // Ignore if external scrolling is in progress
    if (scrollUpdateDisabledFlag.current > 0) {
      return
    }

    let editor = document.getElementsByClassName("fr-element")[0]
    if (!editor) {
      return
    }

    const { headerNode, currentNode, currentHeaderId, elementId, found } =
      findSelectedElementByScrollPosition(editor)

    if (found) {
      const titleNode = editor.firstChild
      updateCurrentSection(headerNode, currentNode, titleNode, elementId)
      setCurrentSectionNumber(currentHeaderId)
    }
  })

  // Prevent navigation when there are unsaved changes or saving
  usePreventNavigation({
    shouldPrevent:
      /**
       * If the transformation summary is open, the course is newly created and unmodified.
       * We don't want to block the ability to close the dialog if a background save occurs with it open.
       * Once the dialog is closed, openTransformationSummary will be permanently false and navigation
       * blocking based on save state can operate as normal.
       * See LD-3368.
       */
      location.state?.openTransformationSummary !== true &&
      (saveState === "unsaved" || saveState === "saving"),
    message: "You have unsaved changes. Are you sure you want to leave?",
    /**
     * If the user attempts to navigate away and was blocked, save the course
     */
    onBlockedNavigation: () => {
      if (saveState === "unsaved") {
        saveCourse()
      }
    },
  })

  /** State that contains React elements to display within React tree such
   * as modals. This is so that events can cause modals to be displayed
   * within the React tree.
   */
  const [reactElements, setReactElements] = useState([])

  /** Displays a react element. Call with a function that takes an onClose
   * argument and returns a react element. The onClose argument is a function
   * that can be called to close the modal.
   */
  const displayReactElement = useStableCallback((elementFn) => {
    setReactElements((elements) => {
      let element

      // Save scroll position
      const scrollPosition = document.querySelector(".fr-wrapper").scrollTop

      /**
       * Closes the react element and restores the scroll position
       */
      const closeReactElement = () => {
        setReactElements((elements) => {
          return elements.filter((e) => e !== element)
        })
        // Restore scroll position. This is done in a timeout to ensure that
        // the scroll position is restored. Froala jumps to the top of the editor
        // when the modal is closed after being clicked.
        setTimeout(() => {
          document.querySelector(".fr-wrapper").scrollTop = scrollPosition
        }, 10)
      }
      element = elementFn(() => closeReactElement())
      return [...elements, element]
    })
  })

  /** Ref that contains function to call when editor is being destroyed
   * Called with editor as only argument
   */
  const handleDestroyRef = useRef()

  // Handle initialized event from Froala editor
  const handleInitialized = useStableCallback((editor) => {
    setEditor(editor)
    setInitialized(true)

    const editorWrapperDiv =
      document.getElementsByClassName(EDITOR_DIV_SELECTOR)[0]
    const editorDiv = document.getElementsByClassName("fr-element")[0]

    // If read only ensure videos don't have class attributes set can cause issues playing videos
    if (readOnly) {
      let elements = [...document.getElementsByClassName("fr-video")]
      for (let elm of elements) {
        elm.setAttribute("class", "")
      }
    }

    if (!readOnly) {
      // Add toolbar click tracking
      editor.events.on("commands.before", function (cmd) {
        trackToolbarClick(cmd)
      })
    }

    /**
     * If we are redirected from a readonly editor to the full editor due to the user owning the course,
     * a second render + reinitialization of the editor occurs. Since the redirect occurs quickly, it
     * unmounts the initial readonly editor before this initialize function can grab the editor_div
     * element. If this occurs, we know that a second initialization will happen where it can correctly
     * grab the editor_div element. Therefore we can place a guard here as a safety for if a redirect
     * occurs.
     */
    if (editorWrapperDiv) {
      cleanupEditorHtml()

      setTimeout(() => {
        updateSize()
        updatePlaceholderWhenChanged()
      }, 300)

      /**
       * Disable browser default for ctrl+s
       * @param e keydown event
       */
      function handleDocumentKeydown(e) {
        if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
          e.preventDefault()
        }
      }

      /**
       * Save course on ctrl+s
       * @param e keydown event
       */
      function handleEditorDivKeydown(e) {
        if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
          saveCourse()
        }
      }

      editorWrapperDiv.addEventListener(
        "scroll",
        updateSelectedElementByScrollPosition
      )

      // Disable browser default for ctrl+s
      document.addEventListener("keydown", handleDocumentKeydown, false)

      // ctrl+s keyboard shortcut to save
      editorWrapperDiv.addEventListener("keydown", handleEditorDivKeydown)

      // Listen for mutations to trigger cleaning html
      const removeMutationObserver = observeMutations(
        editorWrapperDiv,
        cleanupEditorHtml,
        {
          childList: true,
          subtree: true,
        }
      )

      // NOTE: Any custom elements that are relevant during a rewrite content preview
      // should also be added to RewriteSmartTemplateCustomComponent.tsx
      const customElementConfigs = [
        questionCustomElement,
        flipCardGridCustomElement,
        labeledImageCustomElement,
      ]
      customElementConfigs.push(smartTemplateControlCustomElement)
      if (enableTabsComponent) {
        customElementConfigs.push(tabsCustomElement)
      }
      if (enableCategoriesComponent) {
        customElementConfigs.push(categoriesCustomElement)
      }
      if (enableProcessComponent) {
        customElementConfigs.push(processCustomElement)
      }
      if (enableStyledListsComponent) {
        customElementConfigs.push(styledListCustomElement)
      }
      if (enableCalloutBoxes) {
        customElementConfigs.push(calloutBoxCustomElement)
      }

      // Display custom elements, mounting as a React element
      // so that context is passed through
      displayReactElement((onClose) => (
        <CustomElements
          key="custom-elements"
          editor={editor}
          editorDiv={editorDiv}
          configs={customElementConfigs}
          readOnly={readOnly}
        />
      ))

      // Display auto alt text popup
      displayReactElement((onClose) => (
        <AutoAltTextPopup key="auto-alt-text-popup" />
      ))

      /**
       * Append a tag at the bottom of the editor to be hydrated with the
       * BottomControl custom element component.
       */
      if (enableImportSources && !readOnly) {
        const bottomControlElement = document.createElement("div")
        editorWrapperDiv.appendChild(bottomControlElement)

        displayReactElement(() =>
          createPortal(
            <BottomControl editor={editor} editorDiv={editorWrapperDiv} />,
            bottomControlElement
          )
        )
      }

      // Display paragraph inserter
      if (enableParagraphInserter && !readOnly) {
        displayReactElement((onClose) => (
          <ParagraphInserter
            key="paragraph-inserter"
            editor={editor}
            editorWrapper={editorWrapperDiv}
          />
        ))
      }

      // Add quick insert
      let removeQuickInsert = null
      if (!readOnly) {
        removeQuickInsert = setupQuickInsert({
          editor,
          editorWrapper: editorWrapperDiv,
          /**
           * Inserts a smart template into the editor
           * @param template smart template type to insert
           * @param anchorElement element to insert template after
           */
          insertSmartTemplate: (template, anchorElement) => {
            const headerElement = findOwningSectionHeaderElement(
              document.getElementsByClassName("fr-element")[0],
              anchorElement
            )
            if (!headerElement) {
              return
            }
            addSmartTemplate(
              editorRef.current.editor,
              template,
              headerElement
            ).catch((e) => {
              setError({
                message: "Something went wrong creating your template.",
              })
            })
          },
          displayReactElement,
          enableFavourites,
          enableTabs: enableTabsComponent,
          enableCategories: enableCategoriesComponent,
          enableProcess: enableProcessComponent,
          enableImport: enableImportSources,
          enableTwoColumns: enableTwoColumnsComponent,
          enableStyledLists: enableStyledListsComponent,
          enableCalloutBoxes: enableCalloutBoxes,
          enableAudioPlayer: enableAudioPlayer,
          enableNewImageInsertDialog,
        })
      }

      // Add drag and drop functionality to Froala
      let removeDragDrop = null
      if (!readOnly) {
        removeDragDrop = addDragDropToFroala(editor, editorWrapperDiv)
      }

      /**
       * Create function to remove listeners
       */
      handleDestroyRef.current = () => {
        editorWrapperDiv.removeEventListener(
          "scroll",
          updateSelectedElementByScrollPosition
        )

        document.removeEventListener("keydown", handleDocumentKeydown)

        editorWrapperDiv.removeEventListener("keydown", handleEditorDivKeydown)

        removeMutationObserver()

        if (removeDragDrop) {
          removeDragDrop()
        }

        if (removeQuickInsert) {
          removeQuickInsert()
        }

        // Self-destroy ref
        handleDestroyRef.current = undefined
      }
    }
  })

  /** Handle the editor's destroy event by calling the registered
   * destroy function, if present.
   */
  const handleDestroy = useStableCallback((editor) => {
    if (handleDestroyRef.current) {
      handleDestroyRef.current(editor)
    }
  })

  /** Clean the html (in the DOM tree) of the editor both initially and when
   * a mutation has taken place
   */
  const cleanupEditorHtml = useStableCallback(() => {
    if (readOnly) {
      return
    }

    // Get editor control
    const innerEditor =
      document.getElementsByClassName(EDITOR_DIV_SELECTOR)[0].firstElementChild

    // Ensure that all heading have ids
    for (const heading of innerEditor.children) {
      if (
        ["H1", "H2", "H3", "H4", "H5", "H6"].includes(heading.tagName) &&
        !heading.id
      ) {
        heading.id = uuidv4()
      }
    }

    // Ensure that all smart template controls are properly placed
    cleanupSmartTemplateControls(innerEditor)
  })

  const handleModelChange = useStableCallback((newHtml) => {
    if (readOnly) {
      return
    }

    // If actual change
    if (newHtml !== html) {
      // Always update the Froala html
      setHtml(newHtml)

      // If initialized and not externally updating, flag as changed and clean placeholders
      if (initialized && !externallyUpdatingHtmlRef.current) {
        // Only update the course if the html has changed *and* it's stable (initialized flag is set)
        const cleanNewHtml = stripTemporaryElements(removeDivWrapper(newHtml))
        const cleanOldHtml = stripTemporaryElements(removeDivWrapper(html))
        if (cleanNewHtml !== cleanOldHtml) {
          updateCourse(cleanNewHtml, duration?.values?.minutes)
        }

        updatePlaceholderWhenChanged()
      }
    }
  })

  /** Update document because of header list change */
  const updateDocument = useStableCallback((html, options = {}) => {
    const { triggerSave } = options
    // Update the editor
    editorRef.current.editor.html.set(html)
    editorRef.current.editor.undo.saveStep()

    // Update the course so that the save state is updated
    updateCourse(html, duration?.values?.minutes)

    // Save the course if the triggerSave flag is set
    if (triggerSave) {
      saveCourse()
    }
  })

  /**
   * Add a template at the appropriate location in the editor.
   */
  const handleAddTemplateSection = useStableCallback((templateType) => {
    const sectionHeading = findSectionHeadingByOffset(currentSectionNumber)

    addSmartTemplate(
      editorRef.current.editor,
      templateType,
      sectionHeading
    ).catch((e) => {
      setError({ message: "Something went wrong creating your template." })
    })
  })

  return {
    displayReactElement,
    editorRef,
    handleDestroy,
    handleInitialized,
    handleModelChange,
    html,
    handleAddTemplateSection,
    reactElements,
    saveCourse,
    saveState,
    title: course?.title,
    updateDocument,
    error,
    editor,
    version: course?.version_id,
    editorContainer,
    branding,
  }
}

export default useEditorState

const PLACEHOLDER_ARRAY = [
  "COURSE TITLE PLACEHOLDER",
  "[Use an action word to give your course a title that describes the task and what the audience will learn.]",
  "[LEARNING OBJECTIVES PLACEHOLDER: This section provides the list of knowledge or skills that the learner should have after they have completed the course. To help create the objectives, LEAi uses the headings from the course sections. Typically, each objective should start with an action verb ending in 'ing' such as: Building, Identifying, Using, or Creating; similar to the task-based headings for the sections of your course.]",
  "SECTION TITLE PLACEHOLDER",
  "[Use an action word to give your section a title that describes the task and what the audience will learn.]",
  "[IMAGE PLACEHOLDER: Use an image to support visual learners and increase engagement.]",
  "[VIDEO PLACEHOLDER: Use a video to enhance your course, supporting both visual and auditory learners.]",
  "[Demonstrations show the learner how to combine multiple concepts or steps covered in the instructions text above using a practical business scenario that achieves a desired outcome or result. You can present this in a video format where the subject matter expert or course developer records the demonstration and inserts the video into this course. Alternatively, you can write a list of steps for the learner to follow either during a live/virtual classroom or role-play practice.]",
  "[Measure a learner's progress and retention of the topics in the section through test questions.]",
  "[Exercises enable the learner to get practical experience on the topics they have learned. It is best practice to combine multiple concepts or steps covered in the section and / or demonstration, to accomplish a larger task using a practical business scenario that achieves a desired outcome or result. Following the practical business scenario set up, present the learner with a list of steps for them to complete independently. The concepts and tasks covered are typically similar to the demonstration(s) in the section to reinforce the learning, but with the added real-world scenario included.]",
]

/** Remove placeholder class when placeholder text changes  */
function updatePlaceholderWhenChanged() {
  let editor = document.getElementsByClassName("fr-element")[0]
  if (!editor) {
    return
  }
  let placeholders = editor.querySelectorAll(".placeholder")
  placeholders.forEach((placeholder) => {
    if (!placeholder.classList.contains("keep-placeholder")) {
      let placeholderText = placeholder.textContent

      if (!PLACEHOLDER_ARRAY.includes(placeholderText)) {
        placeholder.classList.remove("placeholder")
      }
    }
  })
}

/** Get list of header tags that are larger or equal to the passed in header tag.
 * For example getLargerHeaderTags("h2") would return ["h1", "h2"]
 * @param header_tag the header tag to get larger tags for
 */
function getLargerHeaderTags(header_tag) {
  const header_tags = ["h1", "h2", "h3", "h4", "h5", "h6"]
  return header_tags.slice(0, header_tags.indexOf(header_tag.toLowerCase()) + 1)
}
