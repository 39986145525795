import useFlag from "../utilities/feature-management/useFlag"
import { acceptedMimeTypes, audioMimeTypes } from "../config"
/** The document mime-types currently accepted for course creation. */
type MimeTypes = {
  [key: string]: string[]
}

const acceptedTypes: MimeTypes = acceptedMimeTypes

/**
 * Return upload mime types filtered by feature flags
 */
const useUploadMimeTypes = () => {
  const rolloutUploadMarkdown = useFlag("rollout-upload-markdown")
  const rolloutImportPdf = useFlag("rollout-import-pdf")

  /**
   * Return allowed upload file types
   */
  const allowedMimeTypes = (): MimeTypes => {
    let uploadMimeTypes = { ...acceptedTypes, ...audioMimeTypes }

    if (!rolloutUploadMarkdown) {
      if ("text/markdown" in uploadMimeTypes) {
        delete (uploadMimeTypes as { [key: string]: any })["text/markdown"]
      }
    }

    if (!rolloutImportPdf) {
      if ("application/pdf" in uploadMimeTypes) {
        delete (uploadMimeTypes as { [key: string]: any })["application/pdf"]
      }
    }

    return uploadMimeTypes
  }

  /**
   * Return a list of unique file extensions in the original order
   */
  const allowedFileExtensions = (): string[] => {
    const mimeTypes = allowedMimeTypes()
    const extensions: string[] = []
    const orderedTypes = [
      ...Object.keys(acceptedTypes),
      ...Object.keys(audioMimeTypes),
    ]

    // Iterate over the original objects in order
    orderedTypes.forEach((key) => {
      if (key in mimeTypes) {
        mimeTypes[key].forEach((extension) => {
          if (!extensions.includes(extension)) {
            extensions.push(extension)
          }
        })
      }
    })

    return extensions
  }

  /**
   * Return true if this mime type can have sub-headings
   * @param mimeType - mimetype to check
   */
  const mimeTypeAllowsHeaders = (mimeType: string): boolean => {
    return mimeType === "folder" || acceptedTypes[mimeType] != null
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const isBetaExtension = (ext: string): boolean => ext === ".pdf"

  return {
    allowedMimeTypes,
    allowedFileExtensions,
    mimeTypeAllowsHeaders,
    isBetaExtension,
  }
}

export default useUploadMimeTypes
