import { useCallback } from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { gapi } from "gapi-script"
import { UploadItem, UploadFile } from "../../../utilities/fileUpload"
import useUploadMimeTypes from "../../../hooks/useUploadMimeTypes"
import { useGapi } from "../../../contexts/GapiProvider"

import config, {
  imageMimeTypes,
  spreadsheetMimeTypes,
  editorAudioMimeTypes,
  fontMimeTypes,
  MimeTypeCategory,
} from "../../../config"
import { isAccessTokenExpired } from "../../../utilities/gapiUtils"

// mime types used in this file
const mimeType = {
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  google_slides: "application/vnd.google-apps.presentation",
  google_doc: "application/vnd.google-apps.document",
}

/**
 * Convert list of selections from the Google Drive picker
 * into a list of UploadItems.
 * UploadItems will be used in the create course UI
 * to display, select and upload to lex API
 *
 * @param {object[]} itemList Google drive selections.
 * @returns {UploadItem[]}
 */
const processSelectedItems = (itemList: any[]) => {
  const uploadItems = []

  for (const [index, selectedItem] of itemList.entries()) {
    // Figure out what to convert to, .pptx or .docx
    let exportType = null
    let fileName = selectedItem.name

    if (selectedItem.mimeType === mimeType.google_slides) {
      // Only google slides should be converted to pptx
      exportType = mimeType.pptx
      fileName = fileName + ".pptx"
    } else if (selectedItem.mimeType === mimeType.google_doc) {
      // Only google docs should be converted to docx
      exportType = mimeType.docx
      fileName = fileName + ".docx"
    }

    // Get file info of selected drive file
    const fileInfo = {
      id: selectedItem.id, // used later to download from drive
      name: fileName,
      type: selectedItem.mimeType,
      exportType: exportType,
      path: fileName,
    }

    // Create an UploadItem with one UploadFile
    const uploadFile = new UploadFile(fileInfo, index, null)
    const uploadItem = new UploadItem(
      [uploadFile],
      fileName,
      selectedItem.mimeType,
      "google-drive"
    )

    // Add UploadItem to list
    uploadItems.push(uploadItem)
  }

  return uploadItems
}

// Define the props interface
interface UseGoogleDrivePickerProps {
  onAccepted: (uploadItems: UploadItem[]) => void
  setGoogleDriveAccessPromptDialogOpen: (open: boolean) => void
  mimeTypeCategories?: MimeTypeCategory[]
}

/**
 * Google provides a picker for drive files, the react-google-drive-picker adds
 * auth checking before opening the picker
 * @param props - props for picker
 * @param props.onAccepted - file accepted function
 * @param props.setGoogleDriveAccessPromptDialogOpen - show request access dialog
 * @param props.mimeTypeCategories - array of mimetypes supported
 */
const useGoogleDrivePicker = ({
  onAccepted,
  setGoogleDriveAccessPromptDialogOpen,
  mimeTypeCategories = [],
}: UseGoogleDrivePickerProps) => {
  const { allowedMimeTypes } = useUploadMimeTypes()

  const mimeTypeLookup =
    [
      { category: MimeTypeCategory.IMAGE, mimeTypes: imageMimeTypes },
      { category: MimeTypeCategory.SHEET, mimeTypes: spreadsheetMimeTypes },
      { category: MimeTypeCategory.FONTS, mimeTypes: fontMimeTypes },
      { category: MimeTypeCategory.AUDIO, mimeTypes: editorAudioMimeTypes },
    ].find((item) => mimeTypeCategories.includes(item.category))?.mimeTypes ||
    allowedMimeTypes()

  const { oauthToken, setOauthToken } = useGapi()

  const mimeTypes = Object.keys(mimeTypeLookup).join(",")

  const googleLogin = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/drive.file",
    overrideScope: true,
    prompt: "consent",
    // eslint-disable-next-line jsdoc/require-jsdoc
    onSuccess: async (tokenResponse) => {
      setOauthToken(tokenResponse)
      doOpenDrivePicker(tokenResponse.access_token)
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    onError: (errorResponse) => console.error(errorResponse),
  })

  const doOpenDrivePicker = useCallback(
    async (access_token) => {
      const pickerApi = (gapi as any).picker.api
      // Determine the viewId based on the selected categories
      const viewId = mimeTypeCategories.includes(MimeTypeCategory.IMAGE)
        ? pickerApi.ViewId.DOCS_IMAGES
        : pickerApi.ViewId.DOCS

      const view = new pickerApi.DocsView(pickerApi.ViewId[viewId])
        .setMimeTypes(mimeTypes)
        .setIncludeFolders(true)

      const picker = new pickerApi.PickerBuilder()
        .setAppId(config.googleApiCredentials.clientId)
        .setOAuthToken(access_token)
        .setDeveloperKey(config.googleApiCredentials.apiKey)
        .enableFeature(pickerApi.Feature.MULTISELECT_ENABLED)
        .addView(view)
        .setCallback((data: any) => {
          if (data.action === "picked") {
            if (data.docs.length > 0) {
              const uploadItems = processSelectedItems(data.docs)
              onAccepted(uploadItems)
            }
          }
        })

      // See https://stackoverflow.com/questions/78208244/google-picker-in-electron-returns-403-when-packaged-but-not-local-dev
      picker.vc.set(
        "parent",
        window.location.protocol + "//" + window.location.host + "/favicon.ico"
      )

      picker.build().setVisible(true)
    },
    [mimeTypeCategories, mimeTypes, onAccepted]
  )

  const openDrivePicker = useCallback(async () => {
    const prompt_for_access =
      oauthToken == null ||
      (await isAccessTokenExpired(oauthToken.access_token))

    if (prompt_for_access) {
      setGoogleDriveAccessPromptDialogOpen(true)
    } else {
      doOpenDrivePicker(oauthToken.access_token)
    }
  }, [doOpenDrivePicker, oauthToken, setGoogleDriveAccessPromptDialogOpen])

  /** Called when access is allowed by user */
  const onAccessPermitted = () => {
    googleLogin()
  }

  return { openDrivePicker, onAccessPermitted }
}

export default useGoogleDrivePicker
