import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  Switch,
  Theme,
  Typography,
} from "@mui/material"
import { GoogleDriveUploadResult } from "../../../../export/api/uploadToGoogleDrive"
import GoogleUploadDialog from "../../../../../components/screens/GoogleUploadDialog"
import { useFlag } from "../../../../../utilities/feature-management"
import {
  useMixpanelCourseTracker,
  ButtonEvents,
} from "../../../../../utilities/mixpanel"
import GoogleDriveAccessDialog from "../../../../createCourse/GoogleDriveAccessDialog"
import ExportButton from "../../../../export/ExportButton"
import {
  ExportLocation,
  GoogleWorkspaceType,
} from "../../../../export/ExportTypes"
import useExportToGoogleDrive from "../../../../export/hooks/useExportToGoogleDrive"
import { ExportTargetName } from "../../../../export/utils/contants"
import LoadingMicroLearningBackdrop from "../../../../microlearning/LoadingMicroLearningBackdrop"
import {
  FORMAT_OPTIONS,
  OrganizationStrategy,
} from "../../../../microlearning/utils/constants"
import pluralize from "../../../../outline/utils/pluralize"
import { FormData } from "../../../../microlearning/hooks/useMicrolearning"
import MicrolearningSectionSelect from "./MicrolearningSectionSelect"

interface MicrolearningFormProps {
  headingLevel: string
  handleHeadingLevelSelect: (event: any) => void
  handleOrganizeBySelect: (event: any) => void
  handleExportMicroLearning: (
    exportTarget: ExportTargetName,
    saveTo?: ExportLocation
  ) => void
  handleExportMicrolearningToGoogleDrive: (
    location: ExportLocation
  ) => Promise<void>
  handleSaveAsMicroLearning: () => void
  exportInProgress: boolean
  isLoading: boolean
  optionList: any[]
  formData: FormData
  updateField: (field: keyof FormData, value: any) => void
  availableHeadings: any[]
  course: any
  createPath: boolean
  setCreatePath: React.Dispatch<React.SetStateAction<boolean>>
  courseCount: number
  previewIndex: number
  setPreviewIndex: React.Dispatch<React.SetStateAction<number>>
  availableFolders: any[]
  selectedFolder: string
  setSelectedFolder: React.Dispatch<React.SetStateAction<string>>
  saveToGoogleResult: GoogleDriveUploadResult | null
  setSaveToGoogleResult: (result: GoogleDriveUploadResult | null) => void
  onExit: () => void
}

/**
 * Form to show microlearning generation options
 * @param props - Component props
 * @param props.course - Course object for determining breadcrumb trail
 */
const MicrolearningForm = (props: MicrolearningFormProps) => {
  const {
    headingLevel,
    handleHeadingLevelSelect,
    handleOrganizeBySelect,
    handleExportMicroLearning,
    handleSaveAsMicroLearning,
    handleExportMicrolearningToGoogleDrive,
    exportInProgress,
    isLoading,
    optionList,
    formData,
    updateField,
    availableHeadings,
    course,
    createPath,
    setCreatePath,
    courseCount,
    previewIndex,
    setPreviewIndex,
    availableFolders,
    selectedFolder,
    setSelectedFolder,
    saveToGoogleResult,
    onExit,
    setSaveToGoogleResult,
  } = props

  const {
    checkedOptions,
    organizeBy,
    condensePercent,
    format,
    transformComponentsToText,
    transformMediaToText,
  } = formData

  const {
    handleGoogleDriveExport,
    googleDriveAccessPromptDialogOpen,
    handleCloseGoogleDriveAccessPromptDialog,
    handleOkGoogleDriveAccessPromptDialog,
  } = useExportToGoogleDrive(handleExportMicrolearningToGoogleDrive)

  const foldersEnabled = useFlag("rollout-course-folders")

  const transformComponentsToTextEnabled = useFlag(
    "rollout-ml-transform-components-to-text"
  )

  const transformMediaToTextEnabled = useFlag(
    "rollout-ml-transform-media-to-text"
  )

  const trackExportButtonClick = useMixpanelCourseTracker(
    ButtonEvents.MicrolearningExportCourse,
    course
  )
  const trackSaveButtonClick = useMixpanelCourseTracker(
    ButtonEvents.MicrolearningPreviewCourse,
    course
  )

  return (
    <>
      <Paper
        elevation={0}
        sx={(theme: Theme) => ({
          borderRadius: "20px",
          flex: "1 1 0",
          backgroundColor: theme.scheme.surfaceContainerLow,
          height: "100%",
          maxHeight: "100%",
          maxWidth: "40%",
          overflow: "hidden",
        })}
      >
        <Stack height="100%">
          <Stack
            padding={2}
            sx={(theme: Theme) => ({
              backgroundColor: theme.scheme.onPrimary,
            })}
          >
            <Typography variant="h5">Microlearning</Typography>
          </Stack>

          <Stack
            paddingY={1}
            paddingX={2}
            sx={(theme: Theme) => ({
              backgroundColor: theme.scheme.onPrimary,
              overflow: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none", // IE and Edge
              "scrollbar-width": "none", // Firefox
            })}
            flexGrow={2}
            gap={2}
          >
            <FormControl fullWidth size="small">
              <InputLabel id="microlearning-format-label">
                Organize By
              </InputLabel>
              <Select
                data-cy="Microlearning-format"
                labelId="microlearning-format-label"
                value={organizeBy}
                label="Organize By"
                onChange={handleOrganizeBySelect}
              >
                <MenuItem value={OrganizationStrategy.FullDocument}>
                  Full Course
                </MenuItem>
                <MenuItem
                  disabled={availableHeadings.length === 0}
                  value={OrganizationStrategy.Section}
                >
                  Section
                </MenuItem>
                <MenuItem value={OrganizationStrategy.Concept}>
                  Concept
                </MenuItem>
              </Select>
              <FormHelperText>
                Control how the document will be organized into microlearning.
              </FormHelperText>
            </FormControl>
            {organizeBy === OrganizationStrategy.Section && (
              <FormControl fullWidth size="small">
                <InputLabel id="microlearning-heading-label">
                  Heading Level
                </InputLabel>
                <Select
                  labelId="microlearning-heading-label"
                  value={headingLevel}
                  label="Heading Level"
                  onChange={handleHeadingLevelSelect}
                >
                  {availableHeadings.map((heading: any) => (
                    <MenuItem
                      key={heading.value}
                      value={heading.value}
                      data-cy="micro-learning-header-option"
                    >
                      {heading.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {organizeBy !== OrganizationStrategy.FullDocument && (
              <MicrolearningSectionSelect
                optionList={optionList}
                checkedOptions={checkedOptions}
                updateField={updateField}
                organizeBy={organizeBy}
                isLoading={isLoading}
                previewIndex={previewIndex}
                setPreviewIndex={setPreviewIndex}
                courseCount={courseCount}
              />
            )}
            <FormControl fullWidth size="small">
              <Typography variant="titleSmall" gutterBottom>
                Summarize
              </Typography>

              <Slider
                sx={{ width: "90%", marginLeft: 2, marginRight: 2 }}
                data-cy="Microlearning-Summarize"
                aria-label="Summarize"
                value={condensePercent}
                onChange={(e: any, newValue: number | number[]) =>
                  updateField("condensePercent", newValue as number)
                }
                marks={[
                  { value: 0, label: "0%" },
                  { value: 25, label: "25%" },
                  { value: 50, label: "50%" },
                  { value: 75, label: "75%" },
                ]}
                getAriaValueText={(value) => `${value}% reduction`}
                valueLabelDisplay="auto"
                step={25}
                min={0}
                max={75}
              />
              <FormHelperText>
                Control how much the results are condensed by. A higher value
                will produce smaller courses.
              </FormHelperText>
            </FormControl>
            {transformComponentsToTextEnabled && condensePercent > 0 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="titleSmall">
                    Transform Interactive Components
                  </Typography>
                  <FormHelperText>
                    Convert interactive components into text
                  </FormHelperText>
                </Box>
                <Switch
                  data-cy="transform-components-switch"
                  checked={transformComponentsToText}
                  onChange={(e) =>
                    updateField("transformComponentsToText", e.target.checked)
                  }
                />
              </Box>
            )}

            {transformMediaToTextEnabled && condensePercent > 0 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="titleSmall">
                    Exclude Multimedia Elements
                  </Typography>
                  <FormHelperText>
                    Exclude images, video, and audio from the output
                  </FormHelperText>
                </Box>
                <Switch
                  data-cy="transform-media-switch"
                  checked={transformMediaToText}
                  onChange={(e) =>
                    updateField("transformMediaToText", e.target.checked)
                  }
                />
              </Box>
            )}

            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box marginBottom={1}>
                <Typography
                  variant="titleSmall"
                  sx={{ marginBottom: 1 }}
                  gutterBottom
                >
                  Save as Learning Path
                </Typography>
                <FormHelperText>
                  The new microlearning courses will all be saved within a new
                  learning path
                </FormHelperText>
              </Box>
              <Switch
                data-cy="Microlearning-learning-path"
                checked={courseCount <= 1 ? false : createPath}
                disabled={courseCount <= 1}
                size="medium"
                onChange={(e: any) => setCreatePath(e.target.checked)}
              />
            </Box>
            <FormControl fullWidth size="small">
              <InputLabel id="format-label">Format</InputLabel>
              <Select
                data-cy="Microlearning-format-label"
                labelId="format-label"
                id="format-select"
                value={format}
                label="Format"
                onChange={(e: any) => updateField("format", e.target.value)}
              >
                {FORMAT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {foldersEnabled && (
              <FormControl fullWidth size="small">
                <InputLabel id="format-label">Destination Folder</InputLabel>
                <Select
                  data-cy="Microlearning-destination-folder"
                  labelId="folder-label"
                  id="folder-select"
                  value={selectedFolder}
                  label="Destination Folder"
                  onChange={(e: any) => setSelectedFolder(e.target.value)}
                >
                  <MenuItem value={"all_courses"}>All Courses</MenuItem>
                  {availableFolders.map((folder: any) => (
                    <MenuItem
                      key={folder.id}
                      value={folder.id}
                      data-cy="destination-folder-items"
                    >
                      {folder.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
          <Box display="flex" justifyContent="flex-end" gap={2} padding={2}>
            <ExportButton
              disabled={courseCount === 0}
              isLoading={isLoading}
              format={format}
              lmsTargets={[]}
              onExportToFile={() => {
                handleExportMicroLearning("download")
                trackExportButtonClick()
              }}
              onExportToGoogle={(saveTo: GoogleWorkspaceType) => {
                handleGoogleDriveExport(saveTo)
                trackExportButtonClick()
              }}
              onExportToLms={() => {}}
            />
            )
            <Button
              data-cy="Microlearning-save-as"
              variant="contained"
              sx={{ boxShadow: "none" }}
              disabled={courseCount === 0}
              onClick={() => {
                handleSaveAsMicroLearning()
                trackSaveButtonClick()
              }}
            >
              Save as {pluralize("course", courseCount, true)}
            </Button>
          </Box>
        </Stack>
      </Paper>
      <LoadingMicroLearningBackdrop open={exportInProgress} />
      <GoogleDriveAccessDialog
        open={googleDriveAccessPromptDialogOpen}
        onClose={handleCloseGoogleDriveAccessPromptDialog}
        onOk={handleOkGoogleDriveAccessPromptDialog}
        upload={true}
      />
      <GoogleUploadDialog
        courseTitle={course.title}
        result={saveToGoogleResult}
        onClose={() => {
          setSaveToGoogleResult(null)
          onExit()
        }}
      />
    </>
  )
}

export default MicrolearningForm
