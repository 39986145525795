import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  Switch,
  Typography,
} from "@mui/material"
import useCreateCourse from "../createCourse/hooks/useCreateCourse"
import CourseDropzone from "../createCourse/CourseDropzone"
import Button from "@mui/material/Button"
import React, { useEffect, useState } from "react"
import useUploadByID from "../../hooks/useUploadByID"
// @ts-ignore
import { useLocation } from "react-router"
import { parseQueryString } from "../../utilities/queryString"
import { getAdditionalSourceDocument } from "./api/getAdditionalSourceDocument"
import { useDispatch } from "react-redux"
import jobsSlice from "../../store/jobs/jobsSlice"
import Skeleton from "@mui/material/Skeleton"
import { formatStageCaption } from "../../components/screens/CreationProgressScreen"
import ErrorMessage from "../../components/widgets/ErrorMessage"
import useCourses from "../../hooks/useCourses"

/**
 * Render Add Source dialog
 * @param onClose - Called when dialog is closed with HTML if created
 */
export function createAddSourceDocumentDialog(onClose: (data: any) => void) {
  return (
    <AddSourceDocumentDialog
      onInsert={onClose}
      onCancel={() => onClose(null)}
    />
  )
}

/**
 * Dialog for adding additional source documents to course
 * @param props - Component props
 */
export function AddSourceDocumentDialog(props: any) {
  const { onInsert, onCancel } = props

  const location = useLocation()
  const courseID = parseQueryString(location.search).course

  const [uploadID, setUploadID] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>()
  const [applyTrainingStructure, setApplyTrainingStructure] =
    useState<boolean>(true)

  const { onStart, onGetHeadings, ...createCourseProps } = useCreateCourse()
  const { cancelCourseCreation } = useCourses()

  const dispatch = useDispatch()
  const { upload, stage, isComplete } = useUploadByID(uploadID)

  /**
   * Monitor upload job status. When upload is marked as "complete",
   * proceed with fetching the generated HTML from S3 using the
   * uploadID as an identifier.
   */
  useEffect(() => {
    if (uploadID && upload && isComplete) {
      getAdditionalSourceDocument({
        uploadID,
        courseID,
      })
        .then(({ html }) => {
          // After fetching the HTML, insert it into the editor
          onInsert(html)
        })
        .catch((e) => {
          setError(e)
          setLoading(false)
        })
    }
  }, [courseID, isComplete, onInsert, upload, uploadID])

  /**
   * Called when inserting new source
   */
  const onInsertSource = async () => {
    setLoading(true)
    const jobId = await onStart({
      applyTemplates: applyTrainingStructure,
      learningPath: false,
      courseID,
    })
    setUploadID(jobId)
  }

  /**
   * Reset state to allow user to retry import
   */
  const resetImport = () => {
    createCourseProps.setUploadItems([])
    createCourseProps.setFileNames([])
    setLoading(false)
    setError(null)
    setUploadID(null)
  }

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogContent>
        {!loading ? (
          !error ? (
            <>
              {/*@ts-ignore*/}
              <CourseDropzone
                onInsertHTML={(html: string) => {
                  onInsert(html)
                }}
                onGetHeadings={(fileInfo: any, options: any) =>
                  onGetHeadings(fileInfo, {
                    applyTemplates: applyTrainingStructure,
                    ...options,
                  })
                }
                courseID={courseID}
                {...createCourseProps}
              />
              <Box
                display="flex"
                marginTop={4}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h6">
                    Let LEAi Structure Your Content
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    LEAi will structure your uploaded content for learning.
                  </Typography>
                </Box>
                <Box display="flex">
                  <Switch
                    checked={applyTrainingStructure}
                    onChange={(e) =>
                      setApplyTrainingStructure(e.target.checked)
                    }
                    inputProps={{ "aria-label": "Apply Training Structure" }}
                    size="medium"
                  />
                </Box>
              </Box>
              <Box
                marginTop={4}
                gap={4}
                display="flex"
                justifyContent="flex-end"
              >
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                  disabled={createCourseProps.uploadItems.length === 0}
                  variant="contained"
                  onClick={onInsertSource}
                >
                  Add
                </Button>
              </Box>
            </>
          ) : (
            <ErrorMessage
              title="Oh no!"
              subtitle="A problem occurred while importing content to your course."
            >
              <Typography variant="body2">
                Please try again, and contact LearnExperts if the problem
                persists.
              </Typography>
              <Box
                display="flex"
                justifyContent="flex-end"
                gap={4}
                marginTop={4}
              >
                <Button onClick={onCancel}>Cancel</Button>
                <Button variant="outlined" onClick={resetImport}>
                  Retry
                </Button>
              </Box>
            </ErrorMessage>
          )
        ) : (
          <Box>
            <Typography fontWeight={500} variant="body1" gutterBottom>
              Importing sources
            </Typography>
            <LinearProgress />
            <Typography variant="caption" align="right" display="block">
              {uploadID && stage ? (
                formatStageCaption(stage)
              ) : (
                <Skeleton width={200} />
              )}
            </Typography>
            <Box display="flex" justifyContent="flex-end" marginTop={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  if (uploadID) {
                    dispatch(
                      jobsSlice.actions.jobCanceled({ async_id: uploadID })
                    )
                    await cancelCourseCreation(uploadID)
                  }
                  onCancel()
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
