import { useState, useEffect } from "react"
import axiosInstance from "../../../api/axios"
import config from "../../../config"
import { useGapi } from "../../../contexts/GapiProvider"
import useCourseId from "../../../hooks/useCourseId"
import { useFlag } from "../../../utilities/feature-management"
import {
  UploadItem,
  getGoogleDriveFilesFromUploadItems,
  downloadDriveUploadItem,
} from "../../../utilities/fileUpload"
import {
  GoogleImportEvents,
  useMixpanelAudioTracker,
  useMixpanelSystemEvent,
} from "../../../utilities/mixpanel"
import useMediaStorage from "../../uxRefresh/pages/settingsPage/hooks/useMediaStorage"
import checkForValidAudioURL from "../checkForValidAudioURL"
import uploadFile from "../../../api/uploadImportFileWithRetry"
import { AudioData } from "../InsertAudioDialog"

export const UPLOAD_TAB = 0
export const URL_TAB = 1

const UPLOAD_URL = `${config.apiUrl}/storage/authenticate`

const UPLOAD_SIZE_LIMIT = 20 * 1024 * 1024

/**
 * Hook for handling audio upload logic
 * @param initialData - Initial data from loading dialog
 * @param editMode - Whether this is a new insertion or an edit operation
 * @param onSave - Save callback to be called when audio is inserted
 */
const useInsertAudio = (
  initialData: AudioData,
  editMode: boolean = false,
  onSave: (data: AudioData) => void
) => {
  const { availableBytes } = useMediaStorage()

  const audioUploadEnabled = useFlag("rollout-editor-audio-file-upload")

  const [data, setData] = useState<AudioData>(initialData)
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)
  const [urlTextInput, setUrlTextInput] = useState(initialData.url)
  const [uploading, setUploading] = useState(false)
  const [fetchingGoogleDriveFile, setFetchingGoogleDriveFile] = useState(false)

  const [audioSource, setAudioSource] = useState(() => {
    /**
     * Case 1: Newly inserting audio
     */
    if (audioUploadEnabled && initialData.url === "") {
      return UPLOAD_TAB
    }

    /**
     * Case 2: Editing existing audio
     */
    // Check if existing audio's URL is being kept in our own storage
    const belongsToCDN = /^https:\/\/cdn\..*\.learnexperts/.test(
      initialData.url
    )

    if (belongsToCDN) {
      return UPLOAD_TAB
    }

    return URL_TAB
  })

  const { downloadDriveFile } = useGapi()

  const { title, file, fileName } = data

  useEffect(() => {
    if (urlTextInput === "") {
      setInvalidMessage(null)
      return
    }
    const message = checkForValidAudioURL(urlTextInput)
    setInvalidMessage(message)
  }, [urlTextInput])

  useEffect(() => {
    if (!availableBytes) {
      return
    }

    console.log("Available storage (bytes): " + availableBytes)

    if (availableBytes <= 0) {
      setInvalidMessage(
        "You have exceeded your storage limit. Please delete some files to free up space."
      )
    }
  }, [availableBytes])

  const trackAudio = useMixpanelAudioTracker()
  const { trackEvent } = useMixpanelSystemEvent()

  const courseId = useCourseId()

  /**
   * Attach the file to the browser and obtain a signed URL for uploading
   * @param file - File to attach for upload
   */
  const handleFileAttach = async (file: any) => {
    if (file.size > UPLOAD_SIZE_LIMIT) {
      setInvalidMessage(
        "File size exceeds 20 MB. Please choose a smaller file."
      )
      return
    }

    if (availableBytes && file.size > availableBytes) {
      setInvalidMessage(
        "File size exceeds your available storage limit. Please choose a smaller file."
      )
      return
    }

    setInvalidMessage(null)
    setData((prev) => ({
      ...prev,
      file: file,
      fileName: file.name,
    }))
  }

  /**
   * Upload the currently attached file and insert it into the editor
   */
  const handleFileUpload = async () => {
    if (file == null) {
      return
    }
    setUploading(true)

    const options = {
      headers: {
        "Content-Type": file.type,
      },
    }

    axiosInstance
      .post(UPLOAD_URL, {
        file_name: file.name,
        file_size: file.size,
        course_id: courseId,
      })
      .then(({ data: responseData }) => {
        const { signed_upload_url, object_url, media_id } = responseData
        console.log(responseData)

        uploadFile(signed_upload_url, file, options)
          .then((response) => {
            setUploading(false)
            if (!editMode) {
              trackAudio(title, object_url, courseId || "noCourseID")
            }

            onSave({
              ...data,
              url: object_url,
              id: media_id,
            })
          })
          .catch((e) => {
            console.log(e)
          })
      })
  }

  /**
   * Download the Google drive file
   * @param uploadItems - Files selected in Google
   */
  const getGoogleDriveFile = async (uploadItems: UploadItem[]) => {
    setInvalidMessage(null)
    setFetchingGoogleDriveFile(true)
    const fileObjects = getGoogleDriveFilesFromUploadItems(uploadItems)
    if (fileObjects.length > 0) {
      const uploadFile = fileObjects[0]

      let errorMessage = await downloadDriveUploadItem(
        uploadFile,
        downloadDriveFile
      )

      if (errorMessage != null) {
        // try a second time - google drive sometimes fails on the first try
        errorMessage = await downloadDriveUploadItem(
          uploadFile,
          downloadDriveFile
        )
      }

      setFetchingGoogleDriveFile(false)
      if (errorMessage != null) {
        setInvalidMessage(errorMessage)
        return null
      } else {
        trackEvent(GoogleImportEvents.AudioUpload, {
          uploadItems,
        })
        return uploadFile.file
      }
    }
  }

  /**
   * Callback for when the user hits the save button on the dialog
   */
  const onInsertAudio = () => {
    if (audioSource === UPLOAD_TAB && file !== null) {
      handleFileUpload()
    } else if (audioSource === URL_TAB && urlTextInput !== "") {
      const cleanedURL = urlTextInput.trim().replace(/['"\s]/g, "")

      if (!editMode) {
        trackAudio(title, cleanedURL, courseId || "noCourseID")
      }
      onSave({
        ...data,
        url: cleanedURL,
      })
    } else {
      onSave(data)
    }
  }

  const insertDisabled =
    (audioSource === UPLOAD_TAB && file === null && fileName === null) ||
    (audioSource === URL_TAB && urlTextInput === "") ||
    title === "" ||
    invalidMessage !== null

  return {
    data,
    setData,
    audioSource,
    setAudioSource,
    urlTextInput,
    setUrlTextInput,
    invalidMessage,
    insertDisabled,
    audioUploadEnabled,
    uploading,
    handleFileAttach,
    onInsertAudio,
    getGoogleDriveFile,
    fetchingGoogleDriveFile,
    availableBytes,
  }
}

export default useInsertAudio
