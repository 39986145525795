import React from "react"
import * as Sentry from "@sentry/react"
import { Box, Button, Typography } from "@mui/material"
import ErrorMessage from "./ErrorMessage"

interface EditorErrorBoundaryProps {
  children: React.ReactNode
}

/**
 * Error boundary component specifically for the Froala editor
 * Catches any errors that occur within the editor and displays a user-friendly error message
 * @param root0 - the params
 * @param root0.children - what we are wrapped around
 */
export function AppErrorBoundary({ children }: EditorErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <ErrorMessage title="We're Sorry." subtitle="Something went wrong.">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                mt: 2,
              }}
            >
              <Typography variant="body2" align="center">
                Please try again later, contact LearnExperts if the problem
                persists.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  resetError()
                }}
              >
                Reload Page
              </Button>
            </Box>
          </ErrorMessage>
        </Box>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
