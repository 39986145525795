import { Alert, Fade, Stack, Theme, Typography } from "@mui/material"
import ListContainer from "../../itemList/ListContainer"
import PageLayout from "../../layouts/PageLayout"
import useMediaStorage from "./hooks/useMediaStorage"
import StorageUsageBar from "./components/StorageUsageBar"

/**
 * Page for showing branding options
 * @param props - Component props
 * @param props.location - Router location
 */
const SettingsPage = (props: { location: any }) => {
  const { location } = props

  const {
    percentageOfLimit,
    size,
    maxSize,
    storageWarning,
    loadingStorageData,
  } = useMediaStorage()

  return (
    <PageLayout navRail location={location}>
      <ListContainer
        title="User Settings"
        controls={
          <Stack>
            <Typography variant="bodyMedium" paragraph>
              View and manage your user settings.
            </Typography>
          </Stack>
        }
      >
        <Stack
          width="100%"
          padding="24px"
          sx={(theme: Theme) => ({
            backgroundColor: theme.scheme.onPrimary,
            overflowX: "hidden",
            overflow: "auto",
            flex: "1 1 0",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          })}
        >
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="titleMedium">Storage</Typography>

              <Typography variant="bodySmall">
                {loadingStorageData
                  ? "Loading Storage Usage"
                  : `${size} of ${maxSize} Used`}
              </Typography>
            </Stack>
            <StorageUsageBar
              loadingStorageData={loadingStorageData}
              percentageOfLimit={percentageOfLimit}
            />
            <Typography variant="bodySmall" paragraph>
              Audio and video files are stored and factored into your total
              storage usage.
            </Typography>
            <Fade in={storageWarning != null}>
              <Alert severity="warning">
                You have used {percentageOfLimit?.toFixed(0)}% of your storage
                limit.
              </Alert>
            </Fade>
          </Stack>
        </Stack>
      </ListContainer>
    </PageLayout>
  )
}

export default SettingsPage
