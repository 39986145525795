import { useCallback, useEffect, useRef, useState, useReducer } from "react"
import * as api from "../api"
import { Comment } from "../store/remarks/remarks"
import { useFlag } from "../utilities/feature-management"

/** States for the saving of a course */
export type SaveState = "loading" | "unsaved" | "saving" | "saved" | "error"

/** Simplified course object for saving/loading */
export interface Course {
  title: string
  assembled_html: string
  version_id: string
  duration_minutes?: number
  branding: any
  comments: Comment[]
}

/**
 * Result of the useCourse hook
 */
export interface UseCourseResult {
  /** The course object or null if loading */
  course: Course | null
  /** The state of the save */
  saveState: SaveState
  /** The error message from the last save attempt */
  saveError: string | null
  /** The error message from the initial load attempt */
  loadError: string | null
  /**
   * Update the course
   * @param html - The HTML to update the course with
   * @param duration - The duration of the course in minutes
   */
  updateCourse: (html: string, duration?: number) => void
  /** Perform an immediate save of the course (does nothing if not unsaved) */
  saveCourse: () => void
}

/**
 * Hook that handles loading and saving a course.
 *
 * It ensures that it has the latest version from the server, while not overwriting the local changes
 * if there are unsaved changes. It also regularly saves the course if changed, and allows for manual
 * saving.
 *
 * The asynchronous part of the save is run after it reports success, since it does non-critical updates.
 * When it is done, it calls the onSaveAsyncComplete function.
 *
 * @param options - The options for the hook
 * @param options.courseId - The id of the course to load and save
 * @param options.onExternalHtmlUpdate - The function to call when the course HTML is updated externally (e.g. from server)
 * it does not get called for local updates (via update()) nor for version changes from saving
 * @param options.onSaveAsyncComplete - The function to call when the course successfully completed the async part of the save
 */
export const useCourse = (options: {
  /** The id of the course to load and save */
  courseId: string
  /** The function called when the course is updated externally (e.g. from server) */
  onExternalHtmlUpdate?: (course: Course) => void
  /** Called when async save completes and comments can be refreshed */
  onSaveAsyncComplete?: (courseId: string, version: string) => void
}): UseCourseResult => {
  const { courseId, onExternalHtmlUpdate, onSaveAsyncComplete } = options

  // Stable onExternalHtmlUpdate ref
  const onExternalHtmlUpdateRef = useRef(onExternalHtmlUpdate)
  useEffect(() => {
    onExternalHtmlUpdateRef.current = onExternalHtmlUpdate
  }, [onExternalHtmlUpdate])

  // Stable onSaveAsyncComplete ref
  const onSaveAsyncCompleteRef = useRef(onSaveAsyncComplete)
  useEffect(() => {
    onSaveAsyncCompleteRef.current = onSaveAsyncComplete
  }, [onSaveAsyncComplete])

  /** The refresh rate in milliseconds between syncs which both save and refresh */
  const REFRESH_RATE =
    useFlag<number | undefined>("configure-save-interface") ?? 180000

  const refreshRateRef = useRef(REFRESH_RATE)

  // Current error message
  const [saveError, setSaveError] = useState<string | null>(null)
  const [loadError, setLoadError] = useState<string | null>(null)

  // Incremented to trigger render
  const [, forceRender] = useReducer((x: number) => x + 1, 0)

  /** The course being saved (cleaned html). null if no save is in progress */
  const savingCourse = useRef<Course | null>(null)
  /** The current course (cleaned html) which starts as a clone of the base course */
  const currentCourse = useRef<Course | null>(null)
  /** The base course (cleaned html) which is the course that we received from the server that the current course is based on */
  const baseCourse = useRef<Course | null>(null)

  /** Whether the hook has unmounted */
  const unmountedRef = useRef(false)

  /** The timeout id for the sync */
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null)

  /** The time in milliseconds until the next sync */
  const nextSyncInRef = useRef(REFRESH_RATE)

  // Set the unmountRef to true when the hook unmounts
  useEffect(() => {
    return () => {
      unmountedRef.current = true

      // Clear the timeout if it exists
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current)
      }
    }
  }, [])

  // If the refresh rate was changed in LaunchDarkly, change the ref accordingly
  useEffect(() => {
    console.log(REFRESH_RATE)
    refreshRateRef.current = REFRESH_RATE
  }, [REFRESH_RATE])

  /** Synchronization function to run repeatedly */
  const synchronize = useCallback(() => {
    // If unmounted, don't run
    if (unmountedRef.current) {
      return
    }

    timeoutIdRef.current = setTimeout(() => {
      // If next sync in is greater than 0ms, wait again
      if (nextSyncInRef.current > 0) {
        nextSyncInRef.current -= 500
        synchronize()
        return
      }

      // If unmounted, don't run
      if (unmountedRef.current) {
        return
      }

      // Reset the next sync in time to the refresh rate
      nextSyncInRef.current = refreshRateRef.current

      // If not saving and local course has changed, save
      if (
        savingCourse.current == null &&
        currentCourse.current !== baseCourse.current
      ) {
        // Set the course that is being saved
        savingCourse.current = currentCourse.current

        // Force render
        forceRender()

        /** Handle errors
         *
         * @param message - The error message
         */
        const handleError = (message: string) => {
          if (unmountedRef.current) {
            return
          }

          setSaveError(message)

          // Error uploading. Unset upsertingDoc
          savingCourse.current = null
          forceRender()

          // Run the sync again
          synchronize()
        }

        // Save the course
        saveCourseAsync(
          courseId,
          savingCourse.current!.assembled_html,
          savingCourse.current!.duration_minutes,
          onSaveAsyncCompleteRef.current
        )
          .then(async (response) => {
            // If unmounted, don't run
            if (unmountedRef.current) {
              return
            }

            setSaveError(null)

            // Create the updated course
            const updatedCourse = {
              ...savingCourse.current!,
              version_id: response.version,
            }

            // If local course is unchanged, then can update current course
            if (currentCourse.current === savingCourse.current) {
              // Set current and base to new course
              baseCourse.current = updatedCourse
              currentCourse.current = updatedCourse
              savingCourse.current = null
            } else {
              // Otherwise just update base to course that was saved
              baseCourse.current = updatedCourse
              savingCourse.current = null
            }

            // Force render
            forceRender()

            // Run the sync again
            synchronize()
          })
          .catch((error) => {
            handleError(error.message)
          })

        return
      }

      // If course is modified, don't refresh, since we can't update on top of change
      if (currentCourse.current !== baseCourse.current) {
        synchronize()
        return
      }

      // If save is in progress, don't refresh
      if (savingCourse.current) {
        synchronize()
        return
      }

      // Fetch the course from the server
      api
        .loadCourse(courseId)
        .then((response) => {
          if (unmountedRef.current) {
            return
          }

          const course = response.data

          // If modified, ignore
          if (currentCourse.current !== baseCourse.current) {
            synchronize()
            return
          }

          // If upsert in progress, ignore
          if (savingCourse.current) {
            synchronize()
            return
          }

          // If course is same revision, ignore
          if (course.version_id === baseCourse.current?.version_id) {
            synchronize()
            return
          }

          // Set current and base to new course
          baseCourse.current = course
          currentCourse.current = course

          // Force render
          forceRender()

          // Call the external update function if it exists
          if (onExternalHtmlUpdateRef.current) {
            onExternalHtmlUpdateRef.current(course)
          }

          // Run the sync again
          synchronize()
        })
        .catch((error) => {
          if (unmountedRef.current) {
            return
          }
          // Ignore errors as refresh is low priority, but log them
          console.warn("Sync refresh failed:", error)
          synchronize()
        })
    }, 500)
  }, [courseId])

  /** Reset and load course if courseId changes */
  useEffect(() => {
    // Reset the error messages
    setSaveError(null)
    setLoadError(null)

    // Reset the course
    baseCourse.current = null
    currentCourse.current = null
    savingCourse.current = null

    // Reset the next sync in time
    nextSyncInRef.current = refreshRateRef.current
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }
    timeoutIdRef.current = null

    // Force render
    forceRender()

    // Load the course
    api
      .loadCourse(courseId)
      .then((response) => {
        if (unmountedRef.current) {
          return
        }

        const course = response.data

        // Set the base and current course
        baseCourse.current = course
        currentCourse.current = course

        // Force render
        forceRender()

        // Start synchronization
        synchronize()
      })
      .catch((e) => {
        console.log("Failed to load course")
        console.log(e)
        setLoadError(e.message)
      })
  }, [courseId, synchronize])

  // Determine save state
  let saveState: SaveState
  if (!currentCourse.current) {
    saveState = "loading"
  } else if (savingCourse.current) {
    saveState = "saving"
  } else if (currentCourse.current !== baseCourse.current) {
    saveState = "unsaved"
  } else {
    saveState = "saved"
  }

  return {
    course: currentCourse.current,
    saveState,
    saveError,
    loadError,
    /**
     * Update the course
     * @param html - The HTML to update the course with
     * @param duration - The duration of the course in minutes
     */
    updateCourse: (html: string, duration?: number) => {
      // Create the updated course
      const updatedCourse = {
        ...currentCourse.current!,
        assembled_html: html,
        duration_minutes: duration,
      }

      // Set the updated course
      currentCourse.current = updatedCourse

      // Wait at least 5 seconds before attempting to sync to prevent too-frequent sync
      nextSyncInRef.current = Math.max(nextSyncInRef.current, 5000)

      // Force render
      forceRender()
    },
    /**
     * Save the course as soon as possible
     */
    saveCourse: () => {
      // Set the next sync to 0 to force a sync
      nextSyncInRef.current = 0
    },
  }
}

/**
 * Perform an asynchronous save.
 * Send the course to the server to be saved. An async job will start.
 *
 * The process is finished when the status is 'complete'
 *
 * Detections will run and will be cached on the server and returned
 *
 * @param courseId - The id of the course to save
 * @param html - The HTML to save
 * @param duration - The duration of the course in minutes
 * @param onSaveAsyncComplete - The function to call when the course successfully completed the async part of the save
 * @returns The version of the course
 */
async function saveCourseAsync(
  courseId: string,
  html: string,
  duration?: number,
  onSaveAsyncComplete?: (courseId: string, version: string) => void
): Promise<{ version: string }> {
  // Defines how long to wait between async job checks
  const pollDelayTime = 1000

  /**
   * Send a request to start the async task that returns an async id
   * that is used to track async job progress
   */
  const { jobId, version } = await api.saveCourse(courseId, {
    html,
    duration: duration,
  })

  /**
   * Check the async job for status, completion or failure.
   * Keep checking until complete (or it fails)
   */
  async function checkSaveAsyncJob() {
    try {
      let job = await api.checkAsyncJob(jobId)
      let asyncJob = job.data

      // Check to see if the json data file is ready for download
      if (asyncJob.status === "complete") {
        //
        onSaveAsyncComplete?.(courseId, version)
      } else if (asyncJob.status === "failed_no_retry") {
        // If the job failed, log but don't throw
        console.error("Failed to complete async job for save course", courseId)
      } else {
        // Otherwise, wait and check again
        setTimeout(() => {
          checkSaveAsyncJob()
        }, pollDelayTime)
      }
    } catch (err) {
      // If the job failed, log but don't throw
      console.error("Failed to complete async job for save course", courseId)
      console.error(err)
    }
  }

  checkSaveAsyncJob()
  return { version }
}
