import { useEffect, useState } from "react"

/**
 * A TypeScript version of useState that persists the value to localStorage in the key specified.
 *
 * Also supports the ability to override the default value with a function that returns a modified version
 * of the value. This allows overriding part of the value despite what is persisted.
 *
 * @param key - The key to use when storing the value in localStorage.
 * @param defaultValue - The default value to use when the key is not found in localStorage.
 * @param overrides - An optional function that takes the persisted (or default) state and returns the initial state.
 * @returns A stateful value, and a function to update it.
 */
export function usePersistedState<T>(
  key: string,
  defaultValue: T,
  overrides?: (value: T) => Partial<T>
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(() => {
    // Get the stored value from localStorage and parse it
    const storedValueJson = window.localStorage.getItem(key)
    let storedValue =
      storedValueJson !== null ? JSON.parse(storedValueJson) : null

    // Use the stored value if it exists, otherwise use the default value
    const initialValue = storedValue ?? defaultValue

    // If an override function is provided, use it to modify the initial value
    if (overrides) {
      return overrides(initialValue)
    }

    // Otherwise, return the initial value
    return initialValue
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}
