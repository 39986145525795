import PropTypes from "prop-types"

import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"

const ConfirmDialog = (props) => {
  const {
    open = true,
    onCancel,
    onConfirm,
    title,
    message,
    confirmText,
    cancelText,
    ...otherProps
  } = props
  return (
    <Dialog open={open} {...otherProps} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}
export default ConfirmDialog
