import React, { useRef, useState } from "react"
import { Button, ButtonGroup, MenuItem, Menu } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import GoogleDriveIcon from "@mui/icons-material/Google"
import GoogleDocsIcon from "@mui/icons-material/Description"
import GoogleSlidesIcon from "@mui/icons-material/Slideshow"

import CloudIcon from "@mui/icons-material/Cloud"
import {
  ButtonEvents,
  useMixpanelButtonTracker,
} from "../../utilities/mixpanel"
import { LmsTarget, LmsTargetName } from "./utils/contants"
import { useFlag } from "../../utilities/feature-management"
import { GoogleWorkspaceType } from "./ExportTypes"

interface ExportButtonProps {
  disabled: boolean
  isLoading: boolean
  format: string
  lmsTargets: ReadonlyArray<LmsTarget>
  onExportToFile: () => void
  onExportToGoogle: (saveTo: GoogleWorkspaceType) => void
  onExportToLms: (lmsName: LmsTargetName) => void
}

/**
 * A Segmented button for exporting to different destinations
 *
 * @param root0 - the params
 * @param root0.disabled - disable the button
 * @param root0.isLoading - track when loading
 * @param root0.format - the ouput format
 * @param root0.lmsTargets - the lms destinations
 * @param root0.onExportToFile - callback for local export
 * @param root0.onExportToGoogle - callback for GDrive export
 * @param root0.onExportToLms - callback for LMS export
 */
const ExportButton = ({
  disabled,
  isLoading,
  format,
  lmsTargets,
  onExportToFile,
  onExportToGoogle,
  onExportToLms,
}: ExportButtonProps) => {
  const useExportToSlidesDocs = useFlag("rollout-export-to-google-slides-docs")

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const anchorRef = useRef<HTMLDivElement>(null)
  const trackExportButtonClick = useMixpanelButtonTracker(
    ButtonEvents.ExportCourse
  )

  /**
   * Click on export
   * @param e - mouse event
   */
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onExportToFile()
    trackExportButtonClick()
  }

  /**
   * Toggle the emenu
   * @param event - mouse event
   */
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close the menu
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick =
    // eslint-disable-next-line jsdoc/require-jsdoc
    (callback: () => void) => (e: React.MouseEvent) => {
      e.preventDefault()
      callback()
      trackExportButtonClick()
      handleClose()
    }

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        disabled={disabled || isLoading}
        aria-label="split button"
        sx={{
          boxShadow: "none",
          "& .MuiButtonGroup-grouped": {
            border: "none",
          },
        }}
      >
        <Button
          data-cy="export-screen-export-course-button"
          onClick={handleClick}
          startIcon={<FileDownloadIcon />}
        >
          Export
        </Button>
        <Button
          data-cy="export-screen-export-course-button-menu"
          size="small"
          onClick={handleToggle}
          sx={{
            px: 0.5,
            minWidth: "auto",
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={handleMenuItemClick(() => onExportToGoogle("drive"))}
        >
          <GoogleDriveIcon sx={{ mr: 1 }} />
          Export to Google Drive
        </MenuItem>
        {["docx", "video-script"].includes(format) && useExportToSlidesDocs && (
          <MenuItem
            onClick={handleMenuItemClick(() => onExportToGoogle("docs"))}
          >
            <GoogleDocsIcon sx={{ mr: 1 }} />
            Export to Google Docs
          </MenuItem>
        )}
        {format === "pptx" && useExportToSlidesDocs && (
          <MenuItem
            onClick={handleMenuItemClick(() => onExportToGoogle("slides"))}
          >
            <GoogleSlidesIcon sx={{ mr: 1 }} />
            Export to Google Slides
          </MenuItem>
        )}

        {format === "scorm" &&
          lmsTargets.map((target) => (
            <MenuItem
              key={target.value}
              onClick={handleMenuItemClick(() => onExportToLms(target.value))}
            >
              <CloudIcon sx={{ mr: 1 }} />
              {target.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

export default ExportButton
