import { useEffect, useState } from "react"
import axiosInstance from "../../../../../api/axios"

export const STORAGE_LIMIT = 100

type StorageUnit = "KB" | "MB" | "GB"
let STORAGE_LIMIT_UNITS: StorageUnit = "GB"

const ByteFactorMap = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
}

export interface StorageMetrics {
  size: string | null
  maxSize: string | null
  percentageOfLimit: number | null
  availableBytes: number | null
}

const defaultStorageMetrics: StorageMetrics = {
  size: null,
  maxSize: null,
  percentageOfLimit: null,
  availableBytes: null,
}

/**
 * Calculate storage metrics based on the provided bytes
 * @param bytes - Number of bytes to calculate metrics for
 */
export const calculateStorageMetrics = (bytes: number): StorageMetrics => {
  const byteFactor = ByteFactorMap[STORAGE_LIMIT_UNITS]

  let result = bytes / byteFactor

  const size = `${parseFloat(
    Math.min(result, STORAGE_LIMIT).toFixed(result < 1 ? 3 : 2)
  )} ${STORAGE_LIMIT_UNITS}`

  const storageLimitBytes = byteFactor * STORAGE_LIMIT

  const maxSize = `${STORAGE_LIMIT} ${STORAGE_LIMIT_UNITS}`
  const percentageOfLimit = Math.min((bytes / storageLimitBytes) * 100, 100)

  const availableBytes = storageLimitBytes - bytes

  return {
    size,
    maxSize,
    percentageOfLimit,
    availableBytes,
  }
}

/**
 * Hook for obtaining data regarding large media storage for a user
 */
const useMediaStorage = () => {
  const [storageData, setStorageData] = useState<StorageMetrics>(
    defaultStorageMetrics
  )
  const [storageWarning, setStorageWarning] = useState<string | null>(null)
  const [loadingStorageData, setLoadingStorageData] = useState(true)

  useEffect(() => {
    axiosInstance
      .get("/storage")
      .then((response) => {
        const { storage_usage } = response.data

        console.log("Storage usage (bytes): " + storage_usage)

        setStorageData(calculateStorageMetrics(storage_usage))
        setLoadingStorageData(false)
      })
      .catch((error) => {
        setStorageData(calculateStorageMetrics(0))

        console.error("There was an error fetching the storage data.", error)
      })
  }, [])

  useEffect(() => {
    const { percentageOfLimit } = storageData

    if (percentageOfLimit != null && percentageOfLimit >= 90) {
      setStorageWarning("You are approaching your storage limit.")
    } else {
      setStorageWarning(null)
    }
  }, [storageData, storageData.percentageOfLimit])

  return {
    ...storageData,
    storageWarning,
    loadingStorageData,
  }
}

export default useMediaStorage
