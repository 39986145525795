import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Alert,
  IconButton,
  LinearProgress,
  Box,
  Typography,
  Tooltip,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material"
import { useState } from "react"
import { dialogConfig } from "../../config/dialogConfig"

import {
  Add,
  AudioFileOutlined,
  Close,
  Upload,
  Link,
} from "@mui/icons-material"
import { useDropzone } from "react-dropzone"
import { useFlag } from "../../utilities/feature-management"
import { UploadItem } from "../../utilities/fileUpload"
import useGoogleDrivePicker from "../createCourse/hooks/useGoogleDrivePicker"
import SelectDocumentDialog from "../../components/screens/SelectDocumentDialog"
import GoogleDriveAccessDialog from "../createCourse/GoogleDriveAccessDialog"
import useInsertAudio, { UPLOAD_TAB, URL_TAB } from "./hooks/useInsertAudio"
import { audioMimeTypes, MimeTypeCategory } from "../../config"

export type AudioData = {
  title: string
  url: string
  fileName?: string | null
  file?: File | null
  id: string | null
}

type InsertAudioDialogProps = {
  initialData: AudioData
  onCancel: () => void
  onSave: (data: AudioData) => void
  editMode?: boolean
}

/**
 * Mount and Audio Clip dialog in the editor
 * @param onClose - Callback when dialog is closed
 * @param initialData - Data to display in form fields initially
 * @param editMode - Flag to determine if this is an edit operation or inserting a new audio file
 */
export const createInsertAudioDialog = (
  onClose: (data: any) => void,
  initialData: AudioData,
  editMode: boolean = false
) => {
  return (
    <InsertAudioDialog
      initialData={initialData}
      onSave={(data: any) => {
        onClose(data)
      }}
      onCancel={() => onClose(null)}
      editMode={editMode}
    />
  )
}

/**
 * Dialog to display for inserting audio clip
 * @param props - Component props
 */
const InsertAudioDialog = (props: InsertAudioDialogProps) => {
  const { initialData, onCancel, onSave, editMode } = props

  const {
    data,
    setData,
    audioSource,
    setAudioSource,
    urlTextInput,
    setUrlTextInput,
    insertDisabled,
    audioUploadEnabled,
    uploading,
    handleFileAttach,
    invalidMessage,
    onInsertAudio,
    getGoogleDriveFile,
    fetchingGoogleDriveFile,
    availableBytes,
  } = useInsertAudio(initialData, editMode, onSave)

  const { title, file, fileName } = data
  const [chooseDialogOpen, setChooseDialogOpen] = useState(false)
  const [
    googleDriveAccessPromptDialogOpen,
    setGoogleDriveAccessPromptDialogOpen,
  ] = useState(false)
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: audioMimeTypes,
    noClick: true,
    noKeyboard: true,
    /**
     * Handle file drop
     * @param acceptedFiles - Files dropped
     */
    onDrop: (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      if (file) {
        handleFileAttach(file)
      }
    },
  })

  const { openDrivePicker, onAccessPermitted } = useGoogleDrivePicker({
    setGoogleDriveAccessPromptDialogOpen,
    mimeTypeCategories: [MimeTypeCategory.AUDIO],
    /**
     * Download the selected file from Google drive and pass the file to the attach handler
     * @param googleDriveFiles - Files selected in Google
     */
    onAccepted: async (googleDriveFiles: UploadItem[]) => {
      const file = await getGoogleDriveFile(googleDriveFiles)
      if (file) {
        handleFileAttach(file)
      }
    },
  })

  const useGoogleDriveUpload = useFlag("rollout-upload-from-google")

  const maxStorageReached = availableBytes !== null && availableBytes <= 0

  return (
    <>
      <Dialog {...dialogConfig} open={true}>
        <DialogTitle>{editMode ? "Edit Audio" : "Insert Audio"}</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <TextField
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              margin="dense"
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              data-cy="audio-title-input-label"
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }}
              sx={{ flex: " 3 1 0" }}
            />

            {/**
             * Only allow switching between upload and URL insertion if we are creating a new audio element.
             */}
            {!editMode && audioUploadEnabled && (
              <Tabs
                value={audioSource}
                onChange={(e: React.SyntheticEvent, newValue: number) => {
                  setAudioSource(newValue)
                }}
                variant="fullWidth"
                sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
              >
                <Tab
                  key="upload"
                  disabled={uploading}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Upload sx={{ width: 20, height: 20 }} />
                      Upload
                    </Box>
                  }
                />
                <Tab
                  key="url"
                  disabled={uploading}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Link sx={{ width: 20, height: 20 }} />
                      URL
                    </Box>
                  }
                />
              </Tabs>
            )}
            {audioSource === UPLOAD_TAB && (
              <>
                {fileName === null && file == null && (
                  <Box
                    {...getRootProps()}
                    style={{
                      borderRadius: "16px",
                      border: "2px dashed #ccc",
                      padding: "20px",
                      textAlign: "center",
                    }}
                    gap={4}
                  >
                    <>
                      {fetchingGoogleDriveFile ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <input {...getInputProps()} />
                          <Typography variant="body2" gutterBottom>
                            Upload or drop in an audio file.
                          </Typography>
                          <Button
                            // component="label"
                            variant="contained"
                            startIcon={<Add />}
                            onClick={(e) => {
                              e.preventDefault()
                              setChooseDialogOpen(true)
                            }}
                            disabled={maxStorageReached}
                          >
                            Upload
                          </Button>
                        </>
                      )}
                    </>
                  </Box>
                )}

                {fileName && (
                  <Stack
                    borderRadius={1}
                    padding={1}
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    gap={1}
                    width="100%"
                    border="1px solid #ccc"
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <AudioFileOutlined />
                      <Typography variant="body1">{fileName}</Typography>
                    </Stack>
                    {audioUploadEnabled && (
                      <Tooltip title="Remove file">
                        <IconButton
                          onClick={() => {
                            setData((prev) => ({
                              ...prev,
                              url: "",
                              file: null,
                              fileName: null,
                            }))
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                )}
                {uploading && <LinearProgress />}
              </>
            )}

            {audioSource === URL_TAB && (
              <TextField
                margin="dense"
                label="Paste in an audio URL"
                variant="outlined"
                fullWidth
                disabled={maxStorageReached}
                value={urlTextInput}
                onChange={(e: any) => setUrlTextInput(e.target.value)}
              />
            )}
            {invalidMessage && <Alert severity="error">{invalidMessage}</Alert>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            data-cy="audio-insert"
            disabled={insertDisabled}
            color="primary"
            variant="contained"
            onClick={onInsertAudio}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <SelectDocumentDialog
        open={chooseDialogOpen}
        useGoogleDriveUpload={useGoogleDriveUpload}
        onSelect={(action: string) => {
          setChooseDialogOpen(false)
          if (action === "computer") {
            open()
          } else if (action === "google-drive") {
            openDrivePicker()
          }
        }}
      />
      <GoogleDriveAccessDialog
        open={googleDriveAccessPromptDialogOpen}
        onClose={() => setGoogleDriveAccessPromptDialogOpen}
        onOk={() => {
          setGoogleDriveAccessPromptDialogOpen(false)
          onAccessPermitted()
        }}
      />
    </>
  )
}

export default InsertAudioDialog
