import Froalaeditor from "froala-editor"

/**
 * Provide our own key value pairs for each font size.
 */
const fontSizeOptions = {
  "8px": 8,
  "9px": 9,
  "10px": 10,
  "11px": 11,
  "12px": 12,
  "14px": 14,
  "18px": 18,
  "24px": 24,
  "30px": 30,
  "36px": 36,
  "48px": 48,
  "60px": 60,
  "72px": 72,
  "96px": 96,
}

Froalaeditor.DefineIcon("fontSize", {
  NAME: "fontSize",
  SVG_KEY: "fontSize",
})

/**
 * Register our own custom font size dropdown. The built-in Froala font dropdown
 * doesn't include a "Default" setting which prevents the user from reverting back
 * to Branding-defined font size.
 */
const registerFontSizeCommand = () => {
  Froalaeditor.RegisterCommand("customFontSize", {
    type: "dropdown",
    title: "Font Size",
    icon: "fontSize",
    refreshAfterCallback: true,
    options: {
      "": "Default", // Selecting Default will clear inline font styles and fallback to branding-defined size
      ...fontSizeOptions,
    },
    defaultSelection: function (t: any) {
      return t.opts.fontSizeDefaultSelection
    },
    callback: function (t, e) {
      try {
        this.fontSize.apply(e)
      } catch (error) {
        console.error("Froala error with applying font size: " + error)
      }
    },
    // Callback on refresh.
    refresh: function (t) {
      try {
        this.fontSize.refresh(t)
      } catch (error) {
        console.error("Froala error with refreshing font size: " + error)
      }
    },
    // Callback on dropdown show.
    refreshOnShow: function (t, e) {
      try {
        this.fontSize.refreshOnShow(t, e)
      } catch (error) {
        console.error(
          "Froala error with refreshing on show for font size: " + error
        )
      }
    },
    plugin: "fontSize",
  })
}

export default registerFontSizeCommand
