import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { Circle, Close } from "@mui/icons-material"
import { TwitterPicker } from "react-color"
import { useEffect, useState } from "react"
import {
  makeColorThemeFromColor,
  SimpleColorTheme,
} from "../../../../branding/colorTheme"

/**
 * Configurable list of sensible default colours
 */

export interface ColorChoices {
  mainColor: string[]
  backgroundColor: string[]
}

// Sample colors from material color schemes
const colourChoices: ColorChoices = {
  mainColor: [
    "#984718",
    "#7b5800",
    "#006c4f",
    "#00658d",
    "#784a9a",
    "#A900A9",
    "#626200",
  ],
  backgroundColor: [
    "#F4DED5",
    "#EEE1CF",
    "#DBE5DE",
    "#DDE3EA",
    "#EADFEA",
    "#EEDEE7",
    "#E6E3D1",
  ],
}

/**
 * Component for choosing colour
 * @param props - Component props
 */
const ColourThemeSelectionControl = (props: any) => {
  const { branding, onPropertyChange } = props

  const [openThemeDialog, setOpenThemeDialog] = useState(false)

  const theme = useTheme()

  const defaultColour = theme.scheme.primary
  const defaultTheme = makeColorThemeFromColor(defaultColour)

  const baseColor = branding["colour"]
  const baseTheme = baseColor ? makeColorThemeFromColor(baseColor) : null

  const defaultMainColor = defaultTheme.mainColor
  const defaultBackgroundColor = defaultTheme.backgroundColor

  /**
   * Update the color on user change
   * @param key - which color field
   * @param value - the color
   */
  const onColorChange = (key: keyof ColorChoices, value: any) => {
    if (key === "mainColor") {
      if (defaultMainColor && value == null) {
        value = defaultMainColor
      }
    }

    if (key === "backgroundColor") {
      if (defaultBackgroundColor && value == null) {
        value = defaultBackgroundColor
      }
    }

    onPropertyChange(key, value)
  }

  /** Open the theme dialog. */
  const chooseTheme = () => {
    setOpenThemeDialog(true)
  }

  /**
   * Apply the theme and remember the settings
   * @param baseColor - Color used to generate the theme
   * @param mainColor - main color
   * @param backgroundColor - background color
   */
  const onApplyTheme = (
    baseColor: string,
    mainColor: string,
    backgroundColor: string
  ) => {
    onPropertyChange("colour", baseColor)
    onColorChange("mainColor", mainColor)
    onColorChange("backgroundColor", backgroundColor)
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="titleMedium">Color</Typography>
        <Button size="large" variant="contained" onClick={chooseTheme}>
          Generate Color Scheme
        </Button>
        <ColorPickerBox
          defaultTheme={defaultTheme}
          defaultColor={defaultMainColor}
          baseTheme={baseTheme}
          branding={branding}
          onColorChange={onColorChange}
          name="mainColor"
          label="Main Color"
        />
        <ColorPickerBox
          defaultTheme={defaultTheme}
          baseTheme={baseTheme}
          defaultColor={defaultBackgroundColor}
          branding={branding}
          onColorChange={onColorChange}
          name="backgroundColor"
          label="Background Color"
        />
      </Stack>
      <ThemeChooserDialog
        open={openThemeDialog}
        defaultColour={defaultColour}
        onClose={() => setOpenThemeDialog(false)}
        onApplyTheme={onApplyTheme}
      />
    </>
  )
}

/**
 * A box with a color and a label with UI to change the color
 * @param root0 - the params
 * @param root0.defaultTheme - theme calulated from default color
 * @param root0.defaultColor - color to revert to
 * @param root0.baseTheme - theme calulated from base color
 * @param root0.branding - branging settings
 * @param root0.name - name of the color field
 * @param root0.label - label of the color
 * @param root0.onColorChange - callback
 */
const ColorPickerBox = ({
  branding,
  defaultTheme,
  baseTheme,
  defaultColor,
  onColorChange,
  name,
  label,
}: {
  defaultTheme: SimpleColorTheme
  defaultColor: string | null
  baseTheme: SimpleColorTheme | null
  branding: any
  name: keyof ColorChoices
  label: string
  onColorChange: (key: keyof ColorChoices, value: any) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // If we have an old base color use that as a starting point
  const color =
    branding[name] ??
    defaultColor ??
    (baseTheme ? baseTheme[name] : defaultTheme[name])

  const isDefault = color === defaultTheme[name]

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      sx={(theme: Theme) => ({
        backgroundColor: theme.scheme.surfaceContainerHigh,
        borderRadius: "24px",
        padding: "4px",
      })}
    >
      <Tooltip arrow title="Select Branding Color">
        <IconButton
          onClick={(event) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
        >
          <Circle
            sx={{
              color: color,
              fontSize: 50,
              borderRadius: "50%",
              backgroundColor: color === "#ffffff" ? "transparent" : "white",
            }}
          />
        </IconButton>
      </Tooltip>
      <Typography fontWeight="bold" variant="body1">
        {label}
        {isDefault && <> [Default]</>}
      </Typography>
      {!isDefault && (
        <Box marginLeft="auto">
          <Tooltip arrow title="Restore Default Color">
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                onColorChange(name, null)
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <TwitterPicker
          color={color}
          triangle="hide"
          colors={colourChoices[name]}
          onChangeComplete={(color) => {
            onColorChange(name, color.hex)
          }}
        />
      </Popover>
    </Box>
  )
}

/**
 * Basic Theme generation dialog
 * @param root0 - the params
 * @param root0.open - true to open the dialog
 * @param root0.defaultColour - starting color for the dialog
 * @param root0.onClose - callback on cancel
 * @param root0.onApplyTheme - callback on apply
 */
const ThemeChooserDialog = ({
  open,
  defaultColour,
  onClose,
  onApplyTheme,
}: {
  open: boolean
  defaultColour: string
  onClose: () => void
  onApplyTheme: (baseColor: string, primary: string, background: string) => void
}) => {
  const [baseColor, setBaseColor] = useState<string>(defaultColour)
  const [mainColor, setMainColor] = useState<null | string>(null)
  const [backgroundColor, setBackgroundColor] = useState<null | string>(null)

  useEffect(() => {
    const newTheme = makeColorThemeFromColor(baseColor)
    setMainColor(newTheme.mainColor)
    setBackgroundColor(newTheme.backgroundColor)
  }, [baseColor])

  /** Callback to apply the theme */
  const onApply = () => {
    if (mainColor != null && backgroundColor != null) {
      onApplyTheme(baseColor, mainColor, backgroundColor)
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Generate Color Scheme</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 1 }} variant="body1">
          Select a Base Color:
        </Typography>
        <TwitterPicker
          color={baseColor}
          triangle="hide"
          onChangeComplete={(color) => {
            console.log("onChangeComplete", color.hex)
            setBaseColor(color.hex)
          }}
          colors={colourChoices["mainColor"]}
        />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Typography sx={{ mb: 1 }} variant="body1">
            Suggested Color Scheme:
          </Typography>
          <ColorDisplayBox color={mainColor} label="Main Color" />
          <ColorDisplayBox color={backgroundColor} label=" Background Color" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onApply}
          disabled={mainColor == null || backgroundColor == null}
        >
          Use These Colors
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * Dispplay a box with a color and a label
 * @param root0 - params
 * @param root0.color - the color
 * @param root0.label - the label
 */
const ColorDisplayBox = ({
  color,
  label,
}: {
  color: string | null
  label: string
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      sx={(theme: Theme) => ({
        backgroundColor: theme.scheme.surfaceContainerHigh,
        borderRadius: "24px",
        padding: "4px",
      })}
    >
      <Circle
        sx={{
          color: color,
          fontSize: 50,
          borderRadius: "50%",
          backgroundColor: color === "#ffffff" ? "transparent" : "white",
        }}
      />
      <Typography fontWeight="bold" variant="body1">
        {label}
      </Typography>
    </Box>
  )
}

export default ColourThemeSelectionControl
