import { Stack, Theme } from "@mui/material"

type StorageUsageBarProps = {
  loadingStorageData: boolean
  percentageOfLimit: number | null
}

/**
 * Bar to indicate storage usage and remaining space
 * @param props - Component props
 */
const StorageUsageBar = (props: StorageUsageBarProps) => {
  const { loadingStorageData, percentageOfLimit } = props

  return (
    <Stack
      direction="row"
      sx={(theme: Theme) => ({
        height: 24,
        animation: loadingStorageData ? "pulse 1.5s infinite" : "none",
        "@keyframes pulse": {
          "0%": {
            opacity: 1,
          },
          "50%": {
            opacity: 0.5,
          },
        },

        backgroundColor: theme.scheme.surfaceContainerHigh,
        borderRadius: 10,
        overflow: "hidden",
      })}
    >
      <Stack
        sx={(theme: Theme) => ({
          height: "100%",
          transition: "width 0.5s ease-in-out",
          width: `${percentageOfLimit ?? 0}%`,
          backgroundColor: theme.palette.primary.main,
        })}
      />
    </Stack>
  )
}

export default StorageUsageBar
