import { GoogleWorkspaceType } from "../features/export/ExportTypes"
import { formatFileDate } from "./transformers"

/**
 * Name an exported file
 * @param exportFormat - the format (html, pptx, epub, ...)
 * @param courseId - course id
 * @param courseTitle - course title
 * @param googleSaveTo - drive/slides/docs
 
 */
export const makeExportFilename = (
  exportFormat: string,
  courseId: string,
  courseTitle: string,
  googleSaveTo: GoogleWorkspaceType
) => {
  const fileTitle = sanitizeAndShorten(courseTitle)
  const dateStr = formatFileDate()
  const baseName = `${fileTitle}-${dateStr}`
  let fileName = `${baseName}.zip`

  if (googleSaveTo === "docs" && exportFormat === "docx") {
    fileName = sanitizeForGoogle(`${courseTitle}.docx`)
  } else if (googleSaveTo === "docs" && exportFormat === "video-script") {
    fileName = sanitizeForGoogle(`${courseTitle} (script).docx`)
  } else if (googleSaveTo === "slides" && exportFormat === "pptx") {
    fileName = sanitizeForGoogle(courseTitle)
  } else if (googleSaveTo === "drive" && exportFormat === "epub") {
    fileName = sanitizeForGoogle(`${courseTitle}.epub`)
  } else if (googleSaveTo === "drive" && exportFormat === "video-script") {
    fileName = sanitizeForGoogle(`${courseTitle} (script).docx`)
  } else if (googleSaveTo === "drive") {
    fileName = sanitizeForGoogle(`${courseTitle}.zip`)
  } else if (exportFormat === "epub") {
    fileName = `${fileTitle}-${courseId}.epub`
  } else if (exportFormat === "video-script") {
    fileName = `${baseName}.docx`
  } else if (exportFormat.startsWith("quiz-csv")) {
    fileName = `${baseName}.csv`
  }

  return fileName
}

/**
 * Name an exported microlearning file
 * @param defaultFileName - base file name
 * @param googleSaveTo - drive/docs
 */
export const makeMicroLearningExportFilename = (
  defaultFileName: string,
  googleSaveTo?: GoogleWorkspaceType
) => {
  // Extract the base name and extension from the original filename
  const extension = defaultFileName.match(/\.[^/.]+$/)?.[0] || ""
  const baseFilename = defaultFileName.replace(/\.[^/.]+$/, "")

  let fileName
  if (googleSaveTo === "docs") {
    fileName = sanitizeForGoogle(baseFilename)
  } else if (googleSaveTo === "drive") {
    fileName = sanitizeForGoogle(`${baseFilename}${extension}`)
  } else {
    fileName = `${sanitizeAndShorten(baseFilename, 200)}${extension}`
  }

  return fileName
}

/**
 * Sanitizes a string to be safe for use as a Google Drive filename.
 * Removes/replaces invalid characters and ensures length compliance.
 *
 * @param {string} input The input string to sanitize
 * @param {number} maxLength Optional maximum length (defaults to 255, Google's limit)
 * @returns {string} The sanitized string safe for Google Drive
 */
export const sanitizeForGoogle = (
  input: string,
  maxLength: number = 255
): string => {
  // Replace invalid Google Drive characters with a hyphen
  // Invalid: \ < > : " | ? * /
  let sanitized = input.replace(/[\\<>:"|?*/]/g, "-")

  // Replace multiple consecutive hyphens with a single hyphen
  sanitized = sanitized.replace(/-+/g, "-")

  // Trim hyphens from start and end
  sanitized = sanitized.replace(/^-+|-+$/g, "")

  // Trim whitespace
  sanitized = sanitized.trim()

  // Return "Untitled" if sanitized result is empty or only whitespace
  if (!sanitized) return "Untitled"

  // Ensure length compliance
  if (sanitized.length > maxLength) {
    sanitized = sanitized.substring(0, maxLength)

    // If we cut in the middle of a hyphen sequence, clean it up
    sanitized = sanitized.replace(/-+$/g, "")
  }

  return sanitized
}

/**
 * Sanitizes and shortens a string to be safe for use as a filename.
 * It removes unsafe characters and limits the length to a maximum.
 *
 * @param {string} input The input string to sanitize and shorten.
 * @param {number} maxLength The maximum length of the output string.
 * @returns {string} The sanitized and shortened string.
 */
export const sanitizeAndShorten = (
  input: string,
  maxLength: number = 30
): string => {
  // replace any characters that are not letters, numbers, hyphens, or underscores
  let sanitized = input.replace(/[^a-zA-Z0-9-_]/g, "_")

  // Replace consecutive underscores with a single underscore
  sanitized = sanitized.replace(/_+/g, "_")

  // Shorten to the maximum length
  if (sanitized.length > maxLength) {
    sanitized = sanitized.substring(0, maxLength)
  }

  return sanitized
}
