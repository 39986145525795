import {
  Stack,
  Avatar,
  Theme,
  IconButton,
  Menu,
  ListItem,
  ListItemIcon,
  MenuItem,
  Divider,
  Typography,
  SvgIconTypeMap,
} from "@mui/material"
import { useAuth } from "../../../contexts/authorization"
import { ReactNode, useState } from "react"
import {
  Grading,
  HelpCenterOutlined,
  Logout,
  PrivacyTip,
  Settings,
} from "@mui/icons-material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import terms from "../../../assets/docs/Terms_and_Conditions_2023.pdf"
import { useFlag } from "../../../utilities/feature-management"

const isProduction = process.env.NODE_ENV === "production"

type AccountButtonProps = {
  navigateTo: (path: string, options?: any) => void
  includeAllOptions: boolean
  size?: number
}

type AccountMenuItemProps = {
  children: ReactNode
  onClick: (e: any) => void
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

/**
 * Render a list item within the account menu
 * @param props - Component props
 */
const AccountMenuItem = (props: AccountMenuItemProps) => {
  const { children, icon, onClick } = props

  const Icon = icon
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <Icon
          sx={(theme: Theme) => ({
            color: theme.scheme.onSurfaceVariant,
          })}
          fontSize="small"
        />
      </ListItemIcon>
      <Typography variant="bodyMedium">{children}</Typography>
    </MenuItem>
  )
}

/**
 * Component for user account button and accompanying menu.
 * @param props - Component props
 */
const AccountButton = (props: AccountButtonProps) => {
  const { navigateTo, size = 36, includeAllOptions = true } = props

  const auth = useAuth() as any

  const { user, signOut } = auth
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const displaySettingsPage = useFlag("rollout-editor-audio-file-upload")

  return (
    <>
      <Stack
        alignItems="center"
        sx={{
          height: "56px",
        }}
        justifyContent="center"
      >
        <IconButton onClick={(event: any) => setAnchorEl(event.currentTarget)}>
          <Avatar
            sx={(theme: Theme) => ({
              width: size,
              height: size,
              fontSize: size * 0.4,
              background: theme.scheme.tertiary,
              textTransform: "uppercase",
            })}
            data-cy="account-button"
          >
            {user?.initials}
          </Avatar>
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={(theme: Theme) => ({
          "& .MuiPaper-root": {
            borderRadius: "4px",
            backgroundColor: theme.scheme.surfaceContainer,
          },
        })}
      >
        <ListItem
          sx={{
            color: "text.secondary",
            cursor: "default",
          }}
        >
          <Typography variant="bodyMedium">{user?.email}</Typography>
        </ListItem>
        <Divider />
        {displaySettingsPage && (
          <AccountMenuItem
            onClick={() => navigateTo("/settings")}
            icon={Settings}
          >
            User Settings
          </AccountMenuItem>
        )}
        {includeAllOptions && (
          <>
            <AccountMenuItem
              icon={HelpCenterOutlined}
              onClick={() => navigateTo("/help", { newTab: true })}
            >
              Help
            </AccountMenuItem>
          </>
        )}
        <AccountMenuItem
          onClick={() => navigateTo(terms, { newTab: true })}
          icon={Grading}
        >
          Terms
        </AccountMenuItem>
        {isProduction && (
          <AccountMenuItem
            onClick={() => {
              navigateTo("https://learnexperts.ai/privacy-policy", {
                newTab: true,
              })
            }}
            icon={PrivacyTip}
          >
            Privacy Policy
          </AccountMenuItem>
        )}
        <AccountMenuItem onClick={signOut} icon={Logout}>
          Sign Out
        </AccountMenuItem>
      </Menu>
    </>
  )
}

export default AccountButton
