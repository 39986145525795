import ReactDOM from "react-dom"
import "./assets/css/style.css"
import App from "./containers/App"
import GapiProvider from "./contexts/GapiProvider"
import "./rum-config"
import * as serviceWorker from "./serviceWorker"

import { Authenticator } from "@aws-amplify/ui-react"
import { AdapterLuxon as DateAdapter } from "@mui/x-date-pickers/AdapterLuxon"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { SnackbarProvider } from "notistack"
import { Provider as StoreProvider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { APIProvider } from "./contexts/api"
import { AuthorizationProvider } from "./contexts/authorization"
import { FeaturesProvider } from "./contexts/features"
import store from "./store"
import { asyncWithFlagProvider } from "./utilities/feature-management/FlagProvider"
import * as Sentry from "@sentry/react"

// Initialize Sentry. Only error monitoring is enabled.
// In the future, we could add Tracing and Session Replay
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
  })
}

// Async function to initialize the app with the flag provider
;(async function () {
  const FlagProvider = await asyncWithFlagProvider()

  ReactDOM.render(
    <FlagProvider>
      <BrowserRouter>
        <StoreProvider store={store}>
          <Authenticator.Provider>
            <AuthorizationProvider>
              <SnackbarProvider
                autoHideDuration={5000}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <FeaturesProvider>
                  <APIProvider>
                    <GapiProvider>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <App />
                      </LocalizationProvider>
                    </GapiProvider>
                  </APIProvider>
                </FeaturesProvider>
              </SnackbarProvider>
            </AuthorizationProvider>
          </Authenticator.Provider>
        </StoreProvider>
      </BrowserRouter>
    </FlagProvider>,
    document.getElementById("root")
  )
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
