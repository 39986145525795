import { useTheme } from "@emotion/react"
import { Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  useMediaQuery,
} from "@mui/material"
import PropTypes from "prop-types"
import { forwardRef } from "react"
import { ShareSettingsProvider } from "../../contexts/sharing"
import PublicAccessControl from "../widgets/PublicAccessControl"
import { dialogConfig } from "../../config/dialogConfig"

/**
 * A modal presenting the share settings for a course.
 *
 * When open, the user can configured the specific share settings for a course.
 * At the moment, a course can be configured to be public accessible by URL.
 * @param props / course, onClose, ShareSettingsProvider, ...otherProps /
 */
const ShareCourseDialog = (props) => {
  const fullScreen = useFullscreenDialog()
  const { course, onClose, ShareSettingsProvider, ...otherProps } = props

  return (
    <Dialog
      {...dialogConfig}
      fullScreen={fullScreen}
      scroll={fullScreen ? "paper" : "body"}
      onClose={onClose}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
      {...otherProps}
    >
      {course && (
        <ShareSettingsProvider course={course}>
          <DialogTitle>Share</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Decide who can view and comment on the course.
            </DialogContentText>
            <PublicAccessControl />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions>
        </ShareSettingsProvider>
      )}
    </Dialog>
  )
}

ShareCourseDialog.propTypes = {
  /**
   * A ID for the course being configured.
   */
  course: PropTypes.string,
  /**
   * When `true`, the dialog will be presented to the user.
   */
  open: PropTypes.bool,
  /**
   * A callback invoked when the user closes the modal.
   */
  onClose: PropTypes.func,
  /**
   * Provides a context for controlling the share settings for course.
   *
   * The default implementation is provided, but may be overridden for testing
   * purposes.
   */
  ShareSettingsProvider: PropTypes.func,
}

ShareCourseDialog.defaultProps = {
  open: false,
  ShareSettingsProvider: ShareSettingsProvider,
}

export default ShareCourseDialog

/**
 * adding fullScreen for Dialog
 */
const useFullscreenDialog = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}
