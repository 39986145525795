import { Box, BoxProps, Button, IconButton, Typography } from "@mui/material"
import { Edit } from "@mui/icons-material"
import React from "react"
import ImageUploadControl from "../uxRefresh/pages/brandingPage/components/ImageUploadControl"
import { useFlag } from "../../utilities/feature-management"

type OldImageUploadBoxProps = {
  title: string
  imageUrl: string | null
  maxWidth?: number
  onChange: (imageUrl: string | null, file: any) => void
} & Omit<BoxProps, "onChange">

type ImageUploadBoxProps = {
  title: string
  imageUrl: string | null
  helpText?: string
  maxWidth?: number
  imageDisplayWidth: number
  onChange: (imageUrl: string | null, file: any) => void
} & Omit<BoxProps, "onChange">

/**
 * Component for uploading image files and previewing them. Wraps either the old or new
 * image upload component based on feature flag.
 *
 * @param props - Component props
 * @param props.title Dialog Title
 * @param props.helpText Helper Text
 * @param props.imageDisplayWidth Actual display size when exported
 * @param props.imageUrl Starting image
 * @param props.maxWidth Width of drop box
 * @param props.onChange Callback when changed
 */
const ImageUploadBox = ({
  title,
  imageUrl,
  maxWidth,
  onChange,
  helpText,
  imageDisplayWidth,
  ...otherProps
}: ImageUploadBoxProps) => {
  const enableNewInsertDialog = useFlag("rollout-new-insert-image-dialog")

  if (enableNewInsertDialog) {
    return (
      <ImageUploadControl
        title={title}
        imageUrl={imageUrl}
        maxWidth={maxWidth}
        onChange={onChange}
        helpText={helpText}
        imageDisplayWidth={imageDisplayWidth}
      />
    )
  }

  return (
    <OldImageUploadBox
      {...otherProps}
      title={title}
      imageUrl={imageUrl}
      maxWidth={maxWidth}
      onChange={onChange}
    />
  )
}

/**
 * Component for uploading image files and previewing them
 *
 * @param props - Component props
 * @param props.title Dialog Title
 * @param props.imageUrl Starting image
 * @param props.maxWidth Width of drop box
 * @param props.onChange Callback when changed

 */
const OldImageUploadBox = ({
  title,
  imageUrl,
  maxWidth,
  onChange,
  ...otherProps
}: OldImageUploadBoxProps) => {
  /**
   * Handle file upload of image. Create a URL to display it to the user and
   * store the file object itself in the branding.changes object in the state.
   *
   * @param event - File input event
   */
  const onAdd = (event: any) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const imageUrl = URL.createObjectURL(file)
      onChange(imageUrl, file)
    }
  }

  return (
    <>
      <Typography variant="body1" fontWeight="bold">
        {title}
      </Typography>
      <Box
        {...otherProps}
        sx={{
          display: "flex",
          /**
           * Set the height based on MUI theme inline
           * @param theme - MUI theme
           */
          height: (theme) => theme.spacing(24),
          /**
           * Set the width based on MUI theme inline. If it's a brand mark box, make it a square
           * @param theme - MUI theme
           */
          maxWidth: (theme) =>
            maxWidth != null ? theme.spacing(maxWidth) : "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "2px dashed",
          borderColor: "neutral.main",
          color: "text.secondary",
          borderRadius: 1,
          position: "relative",
        }}
      >
        {imageUrl ? (
          <>
            <Box display="flex" flexDirection="column">
              <img alt="not found" height="100rem" src={imageUrl} />
              <Button
                onClick={() => {
                  onChange(null, "null")
                }}
              >
                Remove
              </Button>
            </Box>
            <IconButton
              component="label"
              sx={{
                position: "absolute",
                bottom: "-10px",
                right: "-10px",
                color: "lexWhite",
                backgroundColor: "primary.main",
                "&:hover, &.Mui-focusVisible": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              <Edit />
              <input hidden type="file" accept="image/*" onChange={onAdd} />
            </IconButton>
          </>
        ) : (
          <Button component="label">
            <input hidden type="file" accept="image/*" onChange={onAdd} />
            Choose File
          </Button>
        )}
      </Box>
    </>
  )
}

export default ImageUploadBox
