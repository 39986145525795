import React from "react"
import NavBar from "./PageNav"
import PropTypes from "prop-types"
import { Container, Stack, LinearProgress } from "@mui/material"
import Breadcrumbs from "../atoms/Breadcrumbs"
import PageFooter from "./PageFooter"

/**
 * Page layout for main screens, looks after loading, resizing, footers and breadcrumbs
 * @param props See below
 */
const PageLayout = (props) => {
  const currentYear = new Date().getFullYear()
  const {
    fixed,
    maxWidth,
    footer = <span> {`Copyright © LEAi™ ${currentYear}`}</span>,
    loading = false,
    breadcrumbs,
    children,
  } = props

  return (
    <>
      <Stack minHeight="100vh">
        <NavBar fixed={fixed} maxWidth={maxWidth} />
        <Container
          fixed={fixed}
          maxWidth={maxWidth}
          sx={{ display: "flex", flexDirection: "column", flex: "1 0 auto" }}
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ mb: 2 }} />
          {loading && <LinearProgress />}
          {children}
        </Container>
        <PageFooter>{footer}</PageFooter>
      </Stack>
    </>
  )
}

PageLayout.propTypes = {
  /**
   * Whether to use a fixed container.
   */
  fixed: PropTypes.bool,
  /**
   * The maximum width of the container.
   */
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  /**
   * The footer content.
   */
  footer: PropTypes.node,
  /**
   * Whether the page is in a loading state.
   */
  loading: PropTypes.bool,
  /**
   * An optional list of navigation breadcrumbs to display.
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * A label for the breadcrumb location.
       */
      label: PropTypes.string.isRequired,
      /**
       * A URL for the breadcrumb location.
       *
       * Can be excluded to display a non-navigable crumb.
       */
      href: PropTypes.string,
    })
  ),
  /**
   * The main content of the page.
   */
  children: PropTypes.node.isRequired,
}

export default PageLayout
