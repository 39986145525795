import { Box, Button, Switch, Typography } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { useState } from "react"
import PreviewIcon from "@mui/icons-material/Preview"
import { DownloadTranslationSheetButton } from "./DownloadTranslationSheetButton"
import { UploadTranslationSheetButton } from "./UploadTranslationSheetButton"
import { TranslationPreviewModal } from "./TranslationPreviewModal"
import { availableTranslationLocales, localeNames } from "./translationLocales"
import { ExportLocation, GoogleWorkspaceType } from "./ExportTypes"
import GoogleDriveAccessDialog from "../createCourse/GoogleDriveAccessDialog"
import useExportToGoogleDrive from "./hooks/useExportToGoogleDrive"
import { downloadTranslationSheet } from "../../api"
import { useAuth } from "../../contexts/authorization"
import GoogleUploadDialog from "../../components/screens/GoogleUploadDialog"
import { GoogleDriveUploadResult } from "./api/uploadToGoogleDrive"

/**
 * A component for controlling the translation of the course
 * @param props - The props for the component
 * @param props.courseId - The ID of the course
 * @param props.courseTitle - The Title of course
 * @param props.courseHtml - The HTML content of the course
 * @param props.summaryHtml - The HTML content of the course summary
 * @param props.translationLanguageCode - The language code of the translation
 * @param props.handleChangeTranslationLanguageCode - The function to call when the translation language code changes
 */
export function TranslationControls(props: {
  courseId: string
  courseTitle: string
  courseHtml: string
  summaryHtml: string
  translationLanguageCode: string | null
  handleChangeTranslationLanguageCode: (languageCode: string | null) => void
}) {
  const {
    courseId,
    courseTitle,
    courseHtml,
    summaryHtml,
    translationLanguageCode,
    handleChangeTranslationLanguageCode,
  } = props

  const [showGoogleDriveUploadResult, setShowGoogleDriveUploadResult] =
    useState<GoogleDriveUploadResult | null>(null)
  const { user } = useAuth() as any
  const tenantId = user?.id

  const [isDownloadingTranslation, setIsDownloadingTranslationSheet] =
    useState(false)

  /**
   * function for handling translation sheet export from googl drive
   * @param exportLocation - exportLocation param for location
   */
  const handleTranslationSheetExport = async (
    exportLocation: ExportLocation
  ) => {
    setIsDownloadingTranslationSheet(true)
    try {
      while (true) {
        if (translationLanguageCode) {
          const completed = await downloadTranslationSheet(
            tenantId,
            courseId,
            translationLanguageCode,
            courseHtml,
            summaryHtml,
            { exportLocation },
            setShowGoogleDriveUploadResult
          )
          if (completed) {
            break
          }
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      }
    } catch (error) {
      alert("Failed to download translation sheet")
    } finally {
      setIsDownloadingTranslationSheet(false)
    }
  }
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const {
    handleGoogleDriveExport,
    googleDriveAccessPromptDialogOpen,
    handleCloseGoogleDriveAccessPromptDialog,
    handleOkGoogleDriveAccessPromptDialog,
  } = useExportToGoogleDrive(handleTranslationSheetExport)
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="body1" fontWeight="bold">
        Translate Exported Course
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <Switch
          data-cy="translation-switch"
          checked={translationLanguageCode != null}
          onChange={(e) => {
            handleChangeTranslationLanguageCode(e.target.checked ? "fr" : null)
          }}
        />
        <Typography variant="body2" sx={{ ml: 1 }}>
          Our AI will automatically translate this export to another language
          for you.
        </Typography>
      </Box>

      {translationLanguageCode != null && (
        <>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
              labelId="language-select-label"
              value={translationLanguageCode}
              onChange={(e) =>
                handleChangeTranslationLanguageCode(e.target.value || null)
              }
              label="Language"
            >
              {availableTranslationLocales.map((locale) => (
                <MenuItem key={locale} value={locale}>
                  {localeNames[locale]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {translationLanguageCode && (
            <Box display="flex" gap={2}>
              <DownloadTranslationSheetButton
                courseId={courseId}
                language={translationLanguageCode}
                courseHtml={courseHtml}
                summaryHtml={summaryHtml}
                onTranslationExportToGoogle={(saveTo: GoogleWorkspaceType) => {
                  handleGoogleDriveExport(saveTo)
                }}
                isDownloadingTranslation={isDownloadingTranslation}
              />
              <UploadTranslationSheetButton
                courseId={courseId}
                language={translationLanguageCode}
              />
              <Button
                variant="outlined"
                onClick={() => setPreviewModalOpen(true)}
                disabled={!courseHtml}
                startIcon={<PreviewIcon />}
              >
                Preview Translation
              </Button>
            </Box>
          )}
          {previewModalOpen && translationLanguageCode && (
            <TranslationPreviewModal
              onClose={() => setPreviewModalOpen(false)}
              courseId={courseId}
              courseHtml={courseHtml}
              language={translationLanguageCode}
            />
          )}
        </>
      )}
      <GoogleUploadDialog
        courseTitle={courseTitle}
        result={showGoogleDriveUploadResult}
        onClose={() => {
          setShowGoogleDriveUploadResult(null)
        }}
        translation={true}
      />
      <GoogleDriveAccessDialog
        open={googleDriveAccessPromptDialogOpen}
        onClose={handleCloseGoogleDriveAccessPromptDialog}
        onOk={handleOkGoogleDriveAccessPromptDialog}
        upload={true}
      />
    </Box>
  )
}
