import { debounce } from "lodash"
import { useState, useMemo, useCallback, useEffect } from "react"
import { saveTranslationRules, loadTranslationRules } from "../../../api"
import { useAuth } from "../../../contexts/authorization"
import { useStableCallback } from "../../../hooks/useStableCallback"
import { TranslationRuleData } from "../TranslationRuleData"

/**
 * Hook for handling logic surrounding translation rules
 */
const useTranslationRules = () => {
  const { user } = useAuth() as any
  const tenantId = user?.id

  const [translationRules, setTranslationRules] =
    useState<TranslationRuleData | null>(null)
  const [originalRules, setOriginalRules] =
    useState<TranslationRuleData | null>(null)

  const hasChanges = useMemo(
    () => JSON.stringify(translationRules) !== JSON.stringify(originalRules),
    [translationRules, originalRules]
  )

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const handleSave = useStableCallback(async () => {
    if (!hasChanges || !translationRules) return

    setError(null)
    try {
      await saveTranslationRules(tenantId, translationRules)
      setOriginalRules(translationRules)
    } catch (error) {
      console.error("Failed to save translations:", error)
      setError("Failed to save translations. Please check your connection.")
    }
  })

  /**
   * Debounce the save function to prevent excessive calls to the server.
   */
  const debouncedSave = useMemo(
    () => debounce(handleSave, 1000, { leading: false, trailing: true }),
    [handleSave]
  )

  const handleDataChange = useCallback(
    (newData: TranslationRuleData) => {
      setTranslationRules(newData)
      debouncedSave()
    },
    [debouncedSave]
  )

  useEffect(() => {
    /**
     * Fetch the translation rules for the current tenant.
     */
    const fetchRules = async () => {
      try {
        setError(null)
        const rules = await loadTranslationRules(tenantId)
        setTranslationRules(rules)
        setOriginalRules(rules)
      } catch (error) {
        console.error("Failed to fetch translation rules:", error)
        setError("Failed to load translation rules. Please try again later.")
      } finally {
        setIsLoading(false)
      }
    }

    fetchRules()
  }, [tenantId])

  return {
    isLoading,
    hasChanges,
    error,
    setError,
    translationRules,
    handleDataChange,
  }
}

export default useTranslationRules
