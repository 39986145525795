import onCreateAudio from "../features/insertAudio/onCreateAudio"
import { createInsertAudioDialog } from "../features/insertAudio/InsertAudioDialog"
import Froalaeditor from "froala-editor"
import customAudioPlugin from "./customPlugins/customAudioPlugin"

/**
 * Define icons used for audio popup menu
 */
Froalaeditor.DefineIcon("deleteElement", {
  NAME: "remove",
  SVG_KEY: "remove",
})

Froalaeditor.DefineIcon("replaceElement", {
  NAME: "exchange",
  SVG_KEY: "replaceImage",
})

Froalaeditor.DefineIcon("editAudio", {
  NAME: "editAudio",
  PATH: "m14.06 9.02.92.92L5.92 19H5v-.92zM17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29m-3.6 3.19L3 17.25V21h3.75L17.81 9.94z",
})

/**
 * Need to manually provide SVG path to icon for Froala to parse it
 */
Froalaeditor.DefineIcon("showAudioDialog", {
  NAME: "audio",
  PATH: "M3 9v6h4l5 5V4L7 9zm7-.17v6.34L7.83 13H5v-2h2.83zM16.5 12c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02M14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77",
})

/**
 * Add audio popup to list of popup templates
 */
Froalaeditor.POPUP_TEMPLATES["audio.popup"] = "[_BUTTONS_][_CUSTOM_LAYER_]"

/**
 * Define buttons used in popups
 */
Object.assign(Froalaeditor.DEFAULTS, {
  popupButtons: ["editElement", "deleteElement"],
})

/**
 * Load the custom audio plugin into Froala's list of plugins
 */
Froalaeditor.PLUGINS.audio = customAudioPlugin

/**
 * Set up additional custom commands for audio insertion and editing with the froala editor
 * @param displayReactElement - Function to render edit Dialog Box
 */
const registerAudioCommands = (displayReactElement: any) => {
  Froalaeditor.RegisterCommand("deleteElement", {
    title: "Remove",
    undo: false,
    icon: "deleteElement",

    focus: false,
    callback: function () {
      const id = this.audio.getCurrentID()
      const audioElement = this.el.querySelector(`#${id}`)
      audioElement.remove()
      this.popups.hideAll()
      this.undo.saveStep()
    },
  })

  Froalaeditor.RegisterCommand("editElement", {
    title: "Edit",
    undo: false,
    icon: "editAudio",
    focus: false,
    callback: function (cmd, val) {
      const id = this.audio.getCurrentID()

      const audioElement = this.el.querySelector(`#${id}`)

      let mediaID = audioElement.getAttribute("data-media-id")
      let titleElement = audioElement.querySelector(".audio-title")
      let urlElement = audioElement.querySelector(".audio-url")
      let fileName = urlElement.getAttribute("data-filename")

      const title = titleElement.innerText || ""
      const url = urlElement.src

      displayReactElement((onClose: () => void) =>
        createInsertAudioDialog(
          (data) => {
            if (data) {
              audioElement.setAttribute("data-media-id", data.id)
              titleElement.textContent = data.title
              urlElement.src = data.url
              urlElement.setAttribute("data-filename", data.fileName)

              this.popups.hideAll()
            }
            onClose()
          },
          {
            title: title,
            url: url,
            fileName: fileName,
            file: null,
            id: mediaID,
          },
          true
        )
      )
    },
  })

  Froalaeditor.RegisterCommand("showAudioDialog", {
    title: "Insert Audio",
    icon: "showAudioDialog",
    focus: true,
    undo: true,
    callback: function () {
      const selection = this.selection.get() as any
      const anchorElement = selection.anchorNode
      anchorElement.innerHTML = "<br/>"

      displayReactElement((onClose: () => void) =>
        onCreateAudio(anchorElement, onClose, this)
      )
    },
  })
}

export default registerAudioCommands
