type ProviderMap = { [domain: string]: string }

/**
 * A map of user damains to authentication providers.
 */
export const providers: ProviderMap = {
  "example.com": "mock-saml",
  "lexonelogintest.com": "mock-onelogin-saml",
  "bullhorn.com": "bullhorn",
  "prophix.com": "prophix",
  "redhat.com": "redhat",
  "assent.com": "assent",
  "assentcompliance.com": "assent",
}
