import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import { useState } from "react"
import { dialogConfig } from "../../../config/dialogConfig"

type FolderTitleDialogProps = {
  onConfirm: (title: string) => void
  onCancel: () => void
  titleText: string
  contentText: string
  confirmText: string
  initialText: string
}

/**
 * Dialog for creating/renaming new folder
 * @param props - Component props
 */
const FolderTitleDialog = (props: FolderTitleDialogProps) => {
  const {
    onConfirm,
    onCancel,
    titleText,
    contentText,
    confirmText,
    initialText = "",
  } = props

  const [title, setTitle] = useState(initialText)

  return (
    <Dialog
      {...dialogConfig}
      open={true}
      onClose={onCancel}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>{contentText}</DialogContentText>
        <TextField
          data-cy="Microlearning-folder-creation"
          fullWidth
          label="Folder Title"
          value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={title === ""}
          onClick={(e) => {
            e.preventDefault()
            onConfirm(title)
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FolderTitleDialog
